import React from 'react'
import Decimal from "decimal.js"

let styles = {
  base: {
    fontFamily: 'Menlo, monospace',
    fontSize: '11px',
    lineHeight: '14px',
    cursor: 'default'
  },
  propertyNodesContainer: {
    paddingLeft: '12px'
  },
  unselectable: {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    OUserSelect: 'none',
    userSelect: 'none'
  },
  expandControl: {
    color: '#6e6e6e',
    fontSize: '10px',
    marginRight: '3px',
    whiteSpace: 'pre'
  },
  property: {
    paddingTop: '2px'
  }
}

let objectStyles = {
  name: {
    color: 'rgb(136, 19, 145)'
  },
  value: {
    'null': {
      color: 'rgb(128, 128, 128)'
    },
    undefined: {
      color: 'rgb(128, 128, 128)'
    },
    string: {
      color: 'rgb(196, 26, 22)'
    },
    text: {
      color: 'rgb(196, 26, 22)',
      margin: '0px'
    },
    number: {
      color: 'rgb(28, 0, 207)'
    },
    boolean: {
      color: 'rgb(28, 0, 207)'
    }
  }
}
	
class ObjectInspector extends React.Component {

  static defaultProps = {name: void 0, data: undefined, depth: 0, path: 'root'}
  state = {expandedPaths: {}}

  static isExpandable(data) {
    return typeof data === 'object' && data !== null && Object.keys(data).length > 0
  }

  getExpanded(path) {
    let expandedPaths = this.state.expandedPaths
    if (typeof expandedPaths[path] !== 'undefined') {
      return expandedPaths[path]
    }
    return false
  }

  setExpanded (path, expanded) {
    let expandedPaths = this.state.expandedPaths
	  expandedPaths[path] = expanded
	  this.setState({expandedPaths: expandedPaths})
  }

  handleClick() {
    if (ObjectInspector.isExpandable(this.props.data)) {
      if (this.props.depth > 0) {
        this.props.setExpanded(this.props.path, !this.props.getExpanded(this.props.path))
      } else {
        this.setExpanded(this.props.path, !this.getExpanded(this.props.path))
      }
    }
  }

  handleExpandAll() {
    let expandedPaths = {}
    expandedPaths['root'] = true
    this.fillExpandedPaths(this.props.data, expandedPaths, 'root.')
    this.setState({expandedPaths: expandedPaths})
  }

  fillExpandedPaths(data, expandedPaths, path) {
    for (let prop in data) {
      if (data.hasOwnProperty(prop)) {
        if (data[prop] && (typeof data[prop] === 'object' || Array.isArray(data[prop]) )) {
          const subPath = path + prop;
          expandedPaths[subPath] = true;
          this.fillExpandedPaths(data[prop], expandedPaths, subPath + '.')
        }
      }
    }
  }

  handleCollapseAll() {
    this.setState({expandedPaths: {}})
  }

  render() {
    let data = this.props.data
	  let name = this.props.name
    let setExpanded = this.props.depth === 0 ? this.setExpanded.bind(this) : this.props.setExpanded
    let getExpanded = this.props.depth === 0 ? this.getExpanded.bind(this) : this.props.getExpanded
    let expanded = getExpanded(this.props.path)
    let expandGlyph = ObjectInspector.isExpandable(data) ? expanded ? '▼' : '▶' : this.props.depth === 0 ? '' : ' '
    let propertyNodesContainer = undefined
    if (expanded) {
      let propertyNodes = []
      for (let propertyName in data) {
        let propertyValue = data[propertyName]
        if (data.hasOwnProperty(propertyName) && typeof(data[propertyName]) != 'function') {
          propertyNodes.push(<ObjectInspector getExpanded={getExpanded} setExpanded={setExpanded} path={this.props.path + '.' + propertyName} depth={this.props.depth + 1}
                                key={propertyName} name={propertyName} data={propertyValue}/>)
        }
      }
      propertyNodesContainer = <div style={styles.propertyNodesContainer}>{propertyNodes}</div>
    }
    let expanders = []
    if (this.props.depth === 0) {
      expanders.push(<i key="space">{'\u00A0'}</i>)
      expanders.push(<i key="minus" onClick={this.handleCollapseAll.bind(this)} className="minus square link icon">{'\u00A0\u00A0\u00A0'}</i>)
      expanders.push(<i key="plus" onClick={this.handleExpandAll.bind(this)} className="plus square link icon"></i>)
    }
    return (
      <div style={styles.base}>
        <span style={styles.property} onClick={this.handleClick.bind(this)}>
          <span style={Object.assign({}, styles.expandControl, styles.unselectable)}>{expandGlyph}</span>
          <span><span style={objectStyles.name}>{name}</span>: <ObjectDescription object={data}/></span>
        </span>
        {expanders}
        {propertyNodesContainer}
      </div>
    )
  }

}

class ObjectDescription extends React.Component {

  render() {
    let object = this.props.object
    switch (typeof object) {
      case 'number':
        return <span style={objectStyles.value.number}>{(new Decimal(object)).toFixed()}</span>
      case 'string':
        if (object.indexOf('\n') > -1) {
          return <pre style={objectStyles.value.text}>"{object}"</pre>
        } else {
          return <span style={objectStyles.value.string}>"{object}"</span>
        }
      case 'boolean':
        return <span style={objectStyles.value.boolean}>{String(object)}</span>
      case 'undefined':
        return <span style={objectStyles.value.undefined}>undefined</span>
      case 'object':
        if (object === null) {
          return <span style={objectStyles.value['null']}>null</span>
        }
        if (Array.isArray(object)) {
          return <span>Array[{object.length}]</span>
        }
        return <span>Object</span>
      default:
        return <span/>
    }
  }

}

export default ObjectInspector