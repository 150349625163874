import React from "react"

import {MC} from './MC.js'

class Steps extends React.Component {

  handleClick(e, item) {
    if (MC.eventHasKey(e) && !MC.isNull(item['@url'])) {
      return
    }
    let field = this.props.field
    let behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior != 'url') {
      e.preventDefault()
    }
    MC.handleEvent(field, 'click', null, e)
    if (behavior == 'url') {
      if (!MC.isNull(item['@url'])) {
        field.flow.reactFlow().routeTo(e, item['@url'])
        return
      }
    } else {
      MC.putFieldParamValue(field.param, 'value', item['@key'])
      MC.handleEvent(field, 'change')
      field.flow.handleSubmit(field)
    }
  }

  render() {
    let steps = []
    let field = this.props.field
    let defSteps = MC.getFieldParamValue(field.param, 'step')
    let Tag = MC.isNull(MC.getFieldParamValue(field.param, '@behavior')) ? 'div' : 'a'
    if (!MC.isNull(defSteps)) {
      defSteps = MC.asArray(defSteps)
      let ordered = MC.getFieldParamBooleanValue(field.param, '@ordered')
      let activeIndex = MC.getFieldParamValue(field.param, '@activeIndex') || 1
      for (let i=0; i<defSteps.length; i++) {
        let dstep = defSteps[i]
        let css = MC.classes({"active": (i+1) == activeIndex, "disabled": (i+1) > activeIndex || dstep['@enabled'] == false, "completed": ordered && (i+1) < activeIndex}, "step")
        let desc = dstep['@description'] ? <div className="description">{dstep['@description']}</div> : null
        let icon = dstep['@icon'] ? <i className={MC.buildIconClass(dstep['@icon'])}></i> : null
        steps.push(
          <Tag className={css} key={'step' + i} onClick={(e) => this.handleClick(e, dstep)} href={dstep['@url']}>
            {icon}
            <div className="content">
              <div className="title">{dstep['@title']}</div>
              {desc}
            </div>
          </Tag>
        )
      }
      return <div className={MC.classes("mnc", {'ordered': ordered}, MC.getFieldParamValue(field.param, '@cssClass'), "steps")} style={MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))}>{steps}</div>
    } else {
      return null
    } 
  }

}

export default Steps