import React from "react"

import {MC} from "../client/MC.js"
import Dropdown from '../client/semantic-widgets/Dropdown.jsx'
import Checkbox from '../client/semantic-widgets/Checkbox.jsx'

import {WidgetModel} from "./WidgetModel.js"
import {GetText} from "./GetText.js"

class InspectorOption extends React.Component {

  changeValue(value) {
    try {
      this.props.onSetOptionValue(this.props.option.path, value)
      delete this.error
    } catch(e) {
      this.error = e
      this.forceUpdate()
      return
    }
    this.props.onFormWasChanged()
    if (!this.stored) {
      this.stored = true
      this.props.onStoreForm(this.form)
    } else if (typeof this.initValue != "undefined" && this.initValue == value) {
      this.stored = false
      this.props.onCancelChange()
    }
  }

  getType() {
    if (this.props.option.editas && ['textbox', 'textarea', 'combobox' , 'checkbox']) {
      return this.props.option.editas
    } else if ("boolean" == this.props.option.dataType) {
      return "checkbox"
    } else if ("staticValues" == this.props.option.dataType || this.props.option.enum) {
      return "combobox"
    }
    return "textbox"
  }

  getDropdownItems() {
    if (this.props.option.enum) {
      return MC.asArray(this.props.option.enum)
    } else {
      return WidgetModel.getStaticValuesNames()
    }
  }

  handleDropdownOrCheckboxChange = (e, props) => {
    this.form = this.props.onGetFormCopy()
    this.stored = false
    let  value = (props.indeterminate !== undefined && props.checked !== undefined) ? props.checked : props.value[0]
    this.changeValue(value)
  }

  handleTextChange = (event) => {
    this.changeValue(event.target.value)
  }
  
  handleTextFocus = (event) => {
    this.form = this.props.onGetFormCopy()
    this.initValue = event.target.value
    this.stored = false
  }

  render () {
    let type = this.getType()
    let option
    let indeterminate = false
    let value = this.props.onOptionValue(this.props.option.path)
    if (value instanceof Object && value.message == "indeterminate" || type == 'checkbox' && (MC.isNull(value) || (value != true && value != 'true' && value != false && value != 'false'))) {
      value = undefined
      indeterminate = true
    }
    let placeholderI = indeterminate ? GetText.t("indeterminate") : null
    switch (type) {
      case "checkbox":
        value = value == true || value == "true"
        option = <Checkbox checked={value} onChange={this.handleDropdownOrCheckboxChange} indeterminate={indeterminate} className="mnc form fitted checkbox"/>
        break;
      case "combobox":
        value = MC.isNull(value) ? '' : value
        let items = this.getDropdownItems()
        items = items.map(e => { return {value: e, text: e} })
        if (!this.props.option.required) {
          items.unshift({value: '', text: ''})
        }
        if (!items.find(o => o.value === value)) {
          items.unshift({value: value, text: value, invalid: true})
        }
        let placeholderD = indeterminate ? GetText.t("indeterminate") : null
        option = <Dropdown className="mini form fluid selection" style={{fontSize: '.78571429rem'}} onChange={this.handleDropdownOrCheckboxChange} value={value} options={items} closeOnChange={true} placeholder={placeholderD}/>
        break;
      case "textbox":
        let cls = MC.classes("mnc mini input fluid", {"error": !MC.isNull(this.error)})
        let type = ['integer', 'decimal'].indexOf(this.props.option.dataType) > -1 ? 'number' : 'text'
        let step = 'decimal' == this.props.option.dataType ? '0.5' : '1'
        option = <React.Fragment><span className="mnc error">{this.error}</span><div className={cls}><input type={type} step={step} placeholder={placeholderI} value={value} onChange={this.handleTextChange} onFocus={this.handleTextFocus}/></div></React.Fragment>
        break;
      case "textarea":
        let clsT = MC.classes("mnc mini input fluid", {"error": !MC.isNull(this.error)})
        option = <React.Fragment><span className="mnc error">{this.error}</span><div className={clsT}><textarea placeholder={placeholderI} value={value} onChange={this.handleTextChange} onFocus={this.handleTextFocus} rows={1}/></div></React.Fragment>
      break;
    }
    return option
  }
}

export {InspectorOption}