import React from "react"

import {MC} from './MC.js'

class Breadcrumb extends React.Component {

  onclick = (item, e) => {
    e.stopPropagation()
    if (!MC.isNull(item.url)) {
      this.props.data.flow.reactFlow().routeTo(e, item.url)
      return
    }
  }

  render() {
    let params = this.props.data.param
    let propItems = MC.getFieldParamValue(params, 'items')
    let items = []
    if (Array.isArray(propItems) && propItems.length > 0) {
      for (let i=0; i<propItems.length; i++) {
        let propItem = propItems[i]
        let ItemTag = "div"
        let active = i == propItems.length-1
        let cls = MC.classes({'active': active}, 'section')
        let href = null
        if (!MC.isNull(propItem.url)) {
          href = propItem.url
          ItemTag = "a"
        }
        if (i > 0) {
          items.push(<div key={'d-' + i} className="divider"> / </div>)
        }
        items.push(
          <ItemTag className={cls} key={i} href={href} onClick={this.onclick.bind(this, propItem)}>{propItem.title}</ItemTag>) 
      }
    }
    return (<div className={MC.classes('mnc breadcrumb', MC.getFieldParamValue(params, '@cssClass'))} style={MC.styleObjectFromString(MC.getFieldParamValue(params, '@css'))}>{items}</div>)
  }
}

export default Breadcrumb