import React from "react"

import {MC} from './MC.js'
import {Widget} from "./Widget.jsx"
import {Field} from "../modeler/Field.jsx"

class Repeater extends React.Component {

  dragCounter = []

  buildSubFields(subFields, disabled, readOnly, textMode) {
    let resolution = this.props.resolution
    let hrows = null
    if (Array.isArray(subFields)) {
      let rows = MC.splitFieldsIntoRows(subFields, resolution)
      hrows = []
      for (var i = 0; i < rows.length; i++) {
        var hrow = []
        for (var ii = 0; ii < rows[i].length; ii++) {
          var subField = rows[i][ii]
          let offsetDiv
          var grid = MC.getFieldGrid(subField, resolution)
          if (grid.offset > 0) {
            var cls = "mnc " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field"
            offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>
          }
          hrow.push(<Widget key={subField.rbsid} widget={subField} disabled={disabled} readOnly={readOnly} resolution={resolution} offsetDiv={offsetDiv} textMode={textMode}/>)
        }
        hrows.push(<div key={i} className="mnc row">{hrow}</div>)
      }
    }
    return hrows
  }

  dragStartRow = (i, e) => {
    Field.dragStart(this.props.data.fields[0].rows[i], i, e)
  }

  dragOverRow = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  dragEnterRow = (i, e) => {
    if (MC.dragData) {
      e.stopPropagation()
      e.preventDefault()
      this.dragCounter[i] = this.dragCounter[i] ?  this.dragCounter[i] + 1 : 1
      if (this.dragCounter[i] == 1) {
        if (MC.dragData.widgetPath == this.props.data.flow.getFormFieldPath(this.props.data) + "/rows*" && MC.dragData.dragIndex == i) {
          return
        }
        MC.handleEvent(this.props.data.fields[0].rows[i], 'dragenter', null, e, {dragData: MC.dragData})
      }
    }
  }

  dragLeaveRow = (i, e) => {
    if (MC.dragData) {
      e.stopPropagation()
      this.dragCounter[i] = this.dragCounter[i] ? this.dragCounter[i] - 1 : 0
      if (this.dragCounter[i] == 0) {
        if (MC.dragData.widgetPath == this.props.data.flow.getFormFieldPath(this.props.data) + "/rows*" && MC.dragData.dragIndex == i) {
          return
        }
        MC.handleEvent(this.props.data.fields[0].rows[i], 'dragleave', null, e, {dragData: MC.dragData})
      }
    }
  }

  dropRow = (i, e) => {
    if (MC.dragData) {
      if (MC.dragData.widgetPath == this.props.data.flow.getFormFieldPath(this.props.data) + "/rows*" && MC.dragData.dragIndex == i) {
        e.stopPropagation()
        delete MC.dragData
        return
      }
      this.dragCounter = []
      MC.handleEvent(this.props.data.fields[0].rows[i], 'drop', null, e, {dragData: MC.dragData})
      delete MC.dragData
    }
  }

  render() {
    let field = this.props.data
    let count = MC.getRowsCount(Array.isArray(field.fields) && field.fields[0] ? field.fields[0] : field)
    let widgets = []
    if (MC.showAtLeastOneIteration(field) && count == 0) {
      count = 1
    }
    let inline = MC.getFieldParamBooleanValue(field.param, '@inline')
    let inlineAuto = MC.getFieldParamBooleanValue(field.param, '@inlineAuto')
    const cls = MC.getFieldParamValue(field.param, '@cssClass')
    let css = MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))
    let draggable = MC.getFieldParamBooleanValue(field.param, '@draggable') && !MC.isModelerActive(field)
    let droppable = MC.getFieldParamBooleanValue(field.param, '@droppable') && !MC.isModelerActive(field)
    for (let i = 0; i < count; i++) {
      let disabled = this.props.disabled
      let readOnly = this.props.readOnly
      let textMode = this.props.textMode
      let clsRow
      let cssRow = {}
      let subfields = Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields ? field.fields[0].fields : []
      let draggableRow = draggable
      let droppableRow = droppable
      if (field.fields[0].rows) {
        let row = field.fields[0].rows[i].param
        if (false === MC.getFieldParamBooleanValue(row, '@visible')) {
          continue
        }
        if (false === MC.getFieldParamBooleanValue(row, '@enabled') || false === MC.getFieldParamBooleanValue(row, '@permitted')) {
          disabled = true
        }
        if (true === MC.getFieldParamBooleanValue(row, '@readonly')) {
          readOnly = true
        }
        if (MC.getFieldParamBooleanValue(row, '@textmode') !== null) {
          textMode = MC.getFieldParamBooleanValue(row, '@textmode')
        }
        if (MC.getFieldParamBooleanValue(row, '@draggable') !== null) {
          draggableRow = MC.getFieldParamBooleanValue(row, '@draggable')
        }
        if (MC.getFieldParamBooleanValue(row, '@droppable') !== null) {
          droppableRow = MC.getFieldParamBooleanValue(row, '@droppable')
        }
        subfields = field.fields[0].rows[i].fields
        clsRow = MC.getFieldParamValue(row, '@cssClass')
        cssRow = MC.styleObjectFromString(MC.getFieldParamValue(row, '@css'))
      }
      let resolution = this.props.resolution
      let wideClass = "twelve"
      if (inline) {
        let grid = MC.getFieldGrid(field, resolution)
        wideClass = MC.getFieldWideClassFromInt(grid.columns)
      } else if (inlineAuto) {
        wideClass = "auto-inline"
      }
      let className = MC.classes("mnc", wideClass, "wide column", cls, clsRow, {'curgrab': draggableRow})
      let dragEnterRow = null
      let dragStartRow = null
      let dragOverRow = null
      let dragLeaveRow = null
      let dropRow = null
      if (draggableRow) {
        dragStartRow = this.dragStartRow.bind(this, i)
      }
      if (droppableRow) {
        dragEnterRow = this.dragEnterRow.bind(this, i)
        dragOverRow = this.dragOverRow
        dragLeaveRow = this.dragLeaveRow.bind(this, i)
        dropRow = this.dropRow.bind(this, i)
      }
      widgets.push(<div key={i} className={className} style={{...css, ...cssRow}} draggable={draggableRow} onDragStart={dragStartRow} onDragEnter={dragEnterRow} onDragLeave={dragLeaveRow} onDragOver={dragOverRow}  onDrop={dropRow}><div className="mnc twelve column grid">{this.buildSubFields(subfields, disabled, readOnly, textMode)}</div></div>)
    }
    return (
    <div className="mnc twelve column grid" data-widget-i-name={field.id}>
      {widgets}
    </div>)
  }

}

export {Repeater}