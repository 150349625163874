let millisecondsInDay = 24 * 60 * 60 * 1000
let daysPerMonthLowerBound = [
  28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31, 31,
  28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31, 31,
  28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31, 31,
  28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31, 31]
let daysPerMonthUpperBound = [
  31, 31, 30, 31, 30, 31, 31, 29, 31, 30, 31, 30,
  31, 31, 30, 31, 30, 31, 31, 28, 31, 30, 31, 30,
  31, 31, 30, 31, 30, 31, 31, 28, 31, 30, 31, 30,
  31, 31, 30, 31, 30, 31, 31, 28, 31, 30, 31, 30]
let daysPerMonthLowerBoundNegative = [
  28, 31, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
  28, 31, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
  28, 31, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
  28, 31, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
let daysPerMonthUpperBoundNegative = [
  31, 31, 30, 31, 30, 31, 29, 31, 31, 30, 31, 30,
  31, 31, 30, 31, 30, 31, 28, 31, 31, 30, 31, 30,
  31, 31, 30, 31, 30, 31, 28, 31, 31, 30, 31, 30,
  31, 31, 30, 31, 30, 31, 28, 31, 31, 30, 31, 30]

/**
 * Object for parsing and computing with durations
 */
let Duration = function() {
  this.negative = false;
  this.years = 0;
  this.months = 0;
  this.days = 0;
  this.hours = 0;
  this.minutes = 0;
  this.seconds = 0;
  this.milliseconds = 0;
  this.isValid = true;

  this.parseIsoString = function(string) {
    if (typeof(string) != 'string' || string == '') {
      this.isValid = false;
      return;
    }
    string = string.trim();
    if (!string.match(/^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([D]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/)) {
      this.isValid = false;
    }
    if (string.startsWith('-')) {
      this.negative = true;
      string = string.substring(1);
    }
    string = string.substring(1);
    var date = string;
    var time = false;
    if (string.indexOf('T') > -1) {
      date = string.split('T')[0];
      time = string.split('T')[1];
    }
    if (date) {
      if (date.indexOf('Y') > -1) {
        this.years = Number(date.split('Y')[0]);
        date = date.split('Y')[1];
      }
      if (date.indexOf('M') > -1) {
        this.months = Number(date.split('M')[0]);
        date = date.split('M')[1];
      }
      if (date.indexOf('D') > -1) {
        this.days = Number(date.split('D')[0]);
      }
    }
    if (time) {
      if (time.indexOf('H') > -1) {
        this.hours = Number(time.split('H')[0]);
        time = time.split('H')[1];
      }
      if (time.indexOf('M') > -1) {
        this.minutes = Number(time.split('M')[0]);
        time = time.split('M')[1];
      }
      if (time.indexOf('S') > -1) {
        var seconds = time.split('S')[0];
        if (seconds.indexOf('.') > -1) {
          this.seconds = Number(seconds.split('.')[0]);
          var micros = seconds.split('.')[1];
          if (micros.length > 3) {
            micros  = micros.substring(0, 3);
          }
          this.milliseconds = Number(micros);
        } else {
          this.seconds = Number(seconds);
        }
      }
    }
    this.normalize();
  };

  this.from = function(years, months, days, hours, minutes, seconds, milliseconds) {
    this.years = years;
    this.months = months;
    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
    if (milliseconds < 1000) {
      this.milliseconds = milliseconds;
    } else {
      this.milliseconds = milliseconds % 1000;
      this.seconds = this.seconds + Math.floor(milliseconds/1000);
    }
    this.normalize();
  };

  this.normalize = function() {
    if (this.isAllZero()) {
      this.negative = false;
    } else if (this.negative && !this.isAllZeroOrPositive() || !this.negative && this.isAllZeroOrNegative()) {
      this.negative = !this.negative;
      this.years = -this.years;
      this.months = -this.months;
      this.days = -this.days;
      this.hours = -this.hours;
      this.minutes = -this.minutes;
      this.seconds = -this.seconds;
      this.milliseconds = -this.milliseconds;
    }
  };

  this.isZeroLength = function() {
    return this.isAllZero();
  };

  this.isAllZero = function() {
    return this.years == 0 && this.months == 0 && this.days == 0 && this.hours == 0 && this.minutes == 0 && this.seconds == 0 && this.milliseconds == 0;
  };

  this.isAllZeroOrPositive = function() {
    return this.years >= 0 && this.months >= 0 && this.days >= 0 && this.hours >= 0 && this.minutes >= 0 && this.seconds >= 0 && this.milliseconds >= 0;
  };

  this.isAllZeroOrNegative = function() {
    return this.years <= 0 && this.months <= 0 && this.days <= 0 && this.hours <= 0 && this.minutes <= 0 && this.seconds <= 0 && this.milliseconds <= 0;
  };

  this.getNegative = function() {
    return this.negative;
  };

  this.getYears = function() {
    return this.negative ? -this.years : this.years;
  };

  this.getMonths = function() {
    return this.negative ? -this.months : this.months;
  };

  this.getDays = function() {
    return this.negative ? -this.days : this.days;
  };

  this.getHours = function() {
    return this.negative ? -this.hours : this.hours;
  };

  this.getMinutes = function() {
    return this.negative ? -this.minutes : this.minutes;
  };

  this.getSeconds = function() {
    return this.negative ? -this.seconds : this.seconds;
  };

  this.getMilliseconds = function() {
    return this.negative ? -this.milliseconds : this.milliseconds;
  };

  this.negate = function() {
    if (!this.isZeroLength()) {
      this.negative = !this.negative;
    }
  };

  this.add = function(other) {
    var thisSign = this.getNegative() ? -1 : 1;
    var otherSign = other.getNegative() ? -1 : 1;
    this.years = thisSign * this.years + otherSign*other.getYears();
    this.months = thisSign * this.months + otherSign*other.getMonths();
    this.days = thisSign * this.days + otherSign*other.getDays();
    this.hours = thisSign * this.hours + otherSign*other.getHours();
    this.minutes = thisSign * this.minutes + otherSign*other.getMinutes();
    this.seconds = thisSign * this.seconds + otherSign*other.getSeconds();
    this.milliseconds = thisSign * this.milliseconds + otherSign*other.getMilliseconds();
  };

  this.subtract = function(other) {
    other.negate();
    this.add(other);
  };

  this.toIsoString = function() {
    var result = '';
    if (this.negative) {
      result += "-";
    }
    result += "P";
    if (this.years != 0) {
      result += this.years + "Y";
    }
    if (this.months != 0) {
      result += this.months + "M";
    }
    if (this.days != 0) {
      result += this.days + "D";
    }
    if (this.hours == 0 && this.minutes == 0 && this.seconds == 0 && this.milliseconds == 0) {
      if (this.years == 0 && this.months == 0 && this.days == 0) {
        result += "T0S";
      }
    } else {
      result += "T";
      if (this.hours != 0) {
        result += this.hours + "H";
      }
      if (this.minutes != 0) {
        result += this.minutes + "M";
      }
      if (this.seconds != 0 || this.milliseconds != 0) {
        if (this.seconds != 0) {
          result += this.seconds;
        } else {
          result += "0";
        }
        if (this.milliseconds != 0) {
          result += "." + Math.abs(this.milliseconds);
        }
        result += "S";
      }

    }
    return result;
  };

  this.isValidDuration = function() {
    return this.isValid;
  }

  this.getTotalMonths = function() {
    return (this.negative ? -1 : 1) * (this.years * 12 + this.months)
  }

  this.getTotalMilliseconds = function() {
    return (this.negative ? -1 : 1) * ((((this.days * 24 + this.hours) * 60 + this.minutes) * 60 + this.seconds) * 1000 + this.milliseconds)
  }

  this.getTotalDays = function(months, lowerBound) {
    let negative
    let table
    if (this.months == 0) {
      return 0
    } else if (this.months > 0) {
      table = lowerBound ? daysPerMonthLowerBound : daysPerMonthUpperBound
      negative = false
    } else {
      months = Math.abs(months)
      table = lowerBound ? daysPerMonthLowerBoundNegative : daysPerMonthUpperBoundNegative
      negative = true
    }
    let days = 0
    let i = 0
    while (i < months) {
      days += table[i % 48]
      i++
    }
    return negative ? -days : days
  }

  this.compareTo = function(duration) {
    let thisTotalMonths = this.getTotalMonths()
    let otherTotalMonths = duration.getTotalMonths()
    let thisTotalMilliseconds = this.getTotalMilliseconds()
    let otherTotalMilliseconds = duration.getTotalMilliseconds()
    if (thisTotalMonths == otherTotalMonths) {
      return this.compareNumberTo(thisTotalMilliseconds, otherTotalMilliseconds)
    }
    if (thisTotalMilliseconds = otherTotalMilliseconds) {
      return this.compareNumberTo(thisTotalMonths, otherTotalMonths)
    }
    let thisLower = (this.getTotalDays(thisTotalMonths, true) * millisecondsInDay) + thisTotalMilliseconds
    let thisUpper = (this.getTotalDays(thisTotalMonths, false) * millisecondsInDay) + thisTotalMilliseconds
    let otherLower = (this.getTotalDays(otherTotalMonths, true) * millisecondsInDay) + otherTotalMilliseconds
    let otherUpper = (this.getTotalDays(otherTotalMonths, false) * millisecondsInDay) + otherTotalMilliseconds
    if (this.compareNumberTo(thisUpper, otherLower) < 0) {
      return -1
    } else if (this.compareNumberTo(thisLower, otherUpper) > 0) {
      return 1
    } else {
      return -2
    }
  }

  this.compareNumberTo = function(xs, xy) {
    return xs != xy ? ((xs > xy) ? 1 : -1) : 0
  }

}

export {Duration}