import Decimal from "decimal.js"
import {Duration} from "./Duration.js"
import {Expression} from "./Expression.js"

import {MC} from './MC.js'

const Value = {

  v: function(value, basicType = 'string', empty = false) {
    if (empty) {
      return {type: basicType, empty: true, value: ''}
    }
    if (MC.isNull(value)) {
      return {type: basicType, null: true, value: null}
    }
    if (basicType === 'collection') {
      return {type: basicType, value: MC.asArray(value)} 
    }
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value = value[0]
      }
    }
    if (MC.isPlainObject(value)) {
      if ('anyType' === basicType || 'dataNode' === basicType) {
        return Value.fromJson(value)
      } else {
        return {type: basicType, value: Value.castToScalar(Value.v(value, 'anyType'), basicType).value}
      }
    }
    if ('dataNode' === basicType) {
      MC.error('Can not cast "' + value + '" to data node value!')
      return {type: basicType, null: true, value: null}
    }
    if ('boolean' === basicType) {
      if (typeof value === 'string') {
        value = value.trim().toLowerCase()
        if (value === 'true') {
          return {type: basicType, value: 'true'}
        } else if (value === 'false') {
          return {type: basicType, value: 'false'}
        } else {
          MC.error('Value "' + value + '" is not castable to boolean!')
          return {type: basicType, null: true, value: null}
        }
      } else {
        if (value === true) {
          return {type: basicType, value: 'true'}
        } else if (value === false) {
          return {type: basicType, value: 'false'}
        } else {
          MC.error('Value "' + value + '" is not castable to boolean!')
          return {type: basicType, null: true,  value: null}
        }
      }
    } else if (['integer', 'int', 'long', 'short', 'byte', 'decimal', 'double', 'float'].indexOf(basicType) > -1) {
      if (typeof value === 'string') {
        value = value.trim()
        if (value.startsWith('+')) {
          value = value.substring(1)
        }
      }
      if (['integer', 'long', 'int', 'short', 'byte'].indexOf(basicType) > -1) {
        let number = new Decimal(value)
        if (number.isInt()) {
          return {type: basicType, value: number.toFixed(0)}
        } else {
          MC.error('Value "' + value + '" is not castable to integer!')
          return {type: basicType, null: true, value: null}
        }
      } else if (['float', 'double', 'decimal'].indexOf(basicType) > -1) {
        return {type: basicType, value: (new Decimal(value)).toFixed()}
      }
    } else if ('duration' === basicType) {
      var dur = new Duration()
      dur.parseIsoString(value)
      if (!dur.isValidDuration()) {
        MC.error('Value "' + value + '" is not castable to duration!')
      }
      return {type: basicType, value: dur.toIsoString()}
    } else if (['date', 'time', 'dateTime'].indexOf(basicType) >= 0) {
      if (typeof value === 'string') {
        value = value.trim()
      }
      let lux = MC.dateTimeStringToLuxon(value)
      if (!lux.v.isValid) {
        MC.error('Value "' + value + '" is not castable to ' + basicType + '!')
      }
      return {type: basicType, value: MC.luxonToDateTimeString(lux, basicType)}
    } else if ('hexBinary' == basicType) {
      value = ('' + value).trim()
      if (value.match(/^[0-9a-fA-F]+$/)) {
        return {type: basicType, value: value.toUpperCase()}
      } else if (value.match(/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/)) {
        let expression = new Expression()
        expression.init(null, {}, null)
        value = expression.operatorDecodeBase64([Value.v(value, 'string')])
        value = expression.operatorEncodeHex([value])
        return {type: basicType, value: value.value}
      } else {
        return {type: basicType, value: '' + value}
      }
    } else if ('base64Binary' == basicType) {
      if (value.match(/^[0-9a-fA-F]+$/)) { //is hex
        value = ('' + value).trim().toUpperCase()
        let expression = new Expression()
        expression.init(null, {}, null)
        value = expression.operatorDecodeHex([Value.v(value, 'string')])
        value = expression.operatorEncodeBase64([value])
        return {type: basicType, value: value.value}
      } else {
        return {type: basicType, value: ('' + value).trim()}
      }
    } else {
      return {type: basicType, value: '' + value}
    }
  },
  dataNode: function(object) {
    if (MC.isPlainObject(object)) {
      return {type: 'anyType', value: object}
    } else {
      return Value.v(null)
    }
  },
  error: function(mess, path) {
    let val = {type: 'error', mess: mess}
    if (path) {
      val.path = path
    }
    return val
  },
  errorPathSet: function(value, path) {
    if (path && !value.path) {
      value.path = path
    }
  },
  getErrorMessage: function(value) {
    return (value.path ? (' Target path "' + value.path + '": ') : '') + value.mess
  },
  isError: function(value) {
    return value.type == 'error' || value.isErrorInside
  },
  errorInside: function(value, mess) {
    if (!Value.isError(value)) {
      value.mess = mess
      value.isErrorInside = 'error'
    }
  },
  fromJson: function(value) {
    let res = {}
    if (Array.isArray(value)) {
      res.type = 'collection'
      res.value = []
      for (let it of value) {
        res.value.push(Value.fromJson(it))
      }
    } else if (MC.isPlainObject(value)) {
      res.type = 'anyType'
      res.value = {}
      for (let key in value) {
        res.value[key] = Value.fromJson(value[key])
      }
    } else {
      if (typeof value == "number") {
        res.type = 'decimal'
        res.value = Value.v(value, 'decimal').value
      } else if (typeof value == "boolean") {
        res.type = 'boolean'
        res.value = Value.v(value, 'boolean').value
      } else if (MC.isNull(value)) {
        res.type = 'string'
        res.value = null
        res.null = true
      } else {  
        res.type = 'string'
        res.value = value
      }
    }
    return res
  },
  toJson: function(value, forceNumebrs = false) {
    let res = null
    if (!value) {
      return res
    }
    if (value.null) {
      return null
    } else if (value.empty) {
      return ""
    } else if (value.type == 'collection') {
      res = []
      for (let it of value.value) {
        res.push(Value.toJson(it, forceNumebrs))
      }
    } else if (value.type == 'anyType' && MC.isPlainObject(value.value)) {
      res = {}
      for (let key in value.value) {
        res[key] = Value.toJson(value.value[key], forceNumebrs)
      }
    } else {
      if (value.type == 'boolean') {
        res = (value.value === "true")
      } else if (Value.isNumber(value) && forceNumebrs) {
        res = parseFloat(value.value)
      } else {
        res = value.value
      }
    }
    return res
  },
  isNumber: function(value) {
    return ['integer', 'int', 'long', 'short', 'byte', 'decimal', 'double', 'float'].indexOf(value.type) > -1
  },
  isCollection: function(value) {
    return value.type == 'collection'
  },
  isCollectionNotEmpty: function(value) {
    return value.type == 'collection' && Array.isArray(value.value) && value.value.length > 0
  },
  isDataNode: function(value) {
    return value.type == 'anyType' || value.type == 'dataNode'
  },
  hasProperty: function(value, prop) {
    return (value.type == 'anyType' || value.type == 'dataNode') && !MC.isNull(value.value[prop])
  },
  getProperty: function(value, prop) {
    if (Value.hasProperty(value, prop)) {
      return value.value[prop]
    } else {
      return Value.v(null)
    }
  },
  collectionValue: function(value, i) {
    if (Value.isCollectionNotEmpty(value)) {
      return value.value
    } else {
      return []
    }
  },
  collectionItem: function(value, i) {
    if (Value.isCollectionNotEmpty(value) && value.value[i]) {
      return value.value[i]
    } else {
      return Value.v(null)
    }
  },
  collectionSize: function(value, i) {
    if (Value.isCollectionNotEmpty(value)) {
      return value.value.length
    } else {
      return 0
    }
  },
  isEmpty: function(value) {
    return value.empty === true
  },
  isEmptyString: function(value) {
    return value.type === 'string' && (value.empty || value.value === '')
  },
  isNull: function(value) {
    if (value.null === true) {
      return true
    }
    if (Value.isCollection(value)) {
      if (Value.collectionSize(value) == 0) {
        return true
      }
      for (let item of Value.collectionValue(value)) {
        if (item && !Value.isNull(item)) {
          return false
        }
      }
      return true
    }
    if (Value.isDataNode(value)) {
      for (let k in value.value) {
        if (k && !Value.isNull(value.value[k])) {
          return false
        }
      }
      return true
    }
    return false
  },
  isPureNull: function(value) {
    return value.null === true
  },
  isNullOrEmpty: function(value) {
    return Value.isEmpty(value) || Value.isNull(value)
  },
  castToScalar: function(value, type) {
    if (Value.isError(value)) {
      return value
    }
    if (Value.isCollection(value)) {
      if (value.value.length > 0) {
        value = value.value[0]
      } else {
        value = Value.v(null, type || 'string')
      }
    } else if (Value.isDataNode(value)) {
      for (let key in value.value) {
        value = value.value[key]
        break
      }
    }
    if (Value.isCollection(value) || Value.isDataNode(value)) {
      return Value.castToScalar(value, type)
    } else {
      if (value.type == 'string' && value.value === '') {
        return Value.v('', 'string', true)
      }
      return Value.v(value.value, type || value.type, Value.isEmpty(value))
    }
  },
  castToCollection: function(value) {
    if (!Value.isCollection(value)) {
      value = {type: 'collection',  value: Value.isNull(value) ? [] : [value], null: Value.isNull(value)}
    }
    return value
  },
  castToDataNode: function(value) {
    if (Value.isCollection(value)) {
      value = Value.castToDataNode(Value.collectionItem(value, 0))
    }
    if (Value.isNullOrEmpty(value) || Value.isDataNode(value)) {
      return value
    }
    MC.error('Cannot cast "' + value.type + '" (' + JSON.stringify(Value.toJson(value)) + ') to data node value!')
  },
  isTrue: function(value) {
    if (Value.isNullOrEmpty(value)) {
      return false
    } else {
      return value.value == 'true'
    }
  },
  isFalse: function(value) {
    if (Value.isNullOrEmpty(value)) {
      return false
    } else {
      return value.value == 'false'
    }
  },
  getFirstNotNull: function(value) {
    if (Value.isNull(value)) {
      return Value.v(null)
    } 
    if (Value.isCollection(value)) {
      for (let v of value.value) {
        if (!Value.isNull(v)) {
          return v
        }
      }
      return {type: value.type, null: true, value: null}
    } else {
      return value
    }
  },
  collectionDepth: function(coll) {
    if (!Value.isCollection(coll)) {
      return 0
    }
    let depth = 1
    for (let item of this.collectionValue(coll)) {
      let itemDepth = Value.collectionDepth(item) + 1
      if (itemDepth > depth) {
        depth = itemDepth
      }
    }
    return depth
  },
  extend: function(target, source) {
    let name, tgt, src, copyIsArray, clone
    for (name in source.value) {
      tgt = target.value[name]
      src = source.value[name]
      if (target === src) { // Prevent never-ending loop
        continue
      }
      if (src && (Value.isDataNode(src) || (copyIsArray = Value.isCollection(src)))) {
        if (copyIsArray) {
          copyIsArray = false
          clone = tgt && Value.isCollection(tgt) ? tgt : {type: 'collection', value: []}
        } else {
          clone = tgt && Value.isDataNode(tgt) ? tgt : Value.dataNode({})
        }
        let res = Value.extend(clone, src)
        if (!Value.isNull(res)) {
          target.value[name] = res
        }  
      } else {
        if (!Value.isNull(src)) {
          if (Value.isEmpty(src) && tgt && Value.isCollection(tgt)) {
            delete target.value[name]
          } else {
            target.value[name] = src
          }
        }
      }
    }
    return target
  }

}

export {Value}