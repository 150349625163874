import React from "react"

import {MC} from "../client/MC.js"
import tt from "../client/tooltip/tooltip.js"

import {InspectorOption} from "./InspectorOption.jsx"
import {GetText} from "./GetText.js"

class Inspector extends React.Component {

  state = {search: ""}
  searchRef = React.createRef()
  rootRef = React.createRef()

  componentDidMount() {
    if (this.props.loaded && this.props.inspectedFields.length > 0) {
      this.searchRef.current.focus()
    }
    this.updateTooltips()
  }

  componentDidUpdate () {
    this.updateTooltips()
  }

  updateTooltips() {
    if (this.rootRef.current) {
      tt(this.rootRef.current.querySelectorAll('[data-tt-content]'), {allowHTML: true, zIndex: 200000})
    }
  }

  handleSearchChange = (event) => {
    this.setState({search: event.target.value})
  }

  editInRepository = () => {
    let mconf = this.props.mconf
    this.props.inspectedFields.forEach(function(field) {
      if (!field.isNotStored) {
        let modeler = field.getModelerReact()
        window.open(mconf.baseUrl + 'miniapp/formedit/' + modeler.state.form.model + '?id=' + modeler.state.form.formId + '&p=svc:FormField;' + field.rbsid, '_blank')
      }
    })
  }

  inspectedFieldsOptions = () => {
    var inspectedFields = this.props.inspectedFields;
    var fieldsOptions = inspectedFields.map(function(field) {
      return field.getOptions()
    });
    var moreThenOne = inspectedFields.length > 1;
    return fieldsOptions[0].filter(function(option) {
      var isInEvery = fieldsOptions.every(function(options) {
        return options.find(function(innerOption){
          return innerOption.name == option.name;
        });
      });
      return isInEvery && (!moreThenOne || option.path[0] != "id");
    });
  }

  inspectedFieldsOptionValue = (path) => {
    var inspectedFields = this.props.inspectedFields;
    var values = inspectedFields.map(function(field) {
      return field.getOption(path);
    });
    return reduceValues(values)
  }

  inspectedFieldsSetOptionValue = (path, value) => {
    var inspectedFields = this.props.inspectedFields;
    console.log(path, value)
    inspectedFields.forEach(function(field) {
      field.setOption(path, value);
    })
  }

  groupOptions = () => {
    let self = this
    let options = this.inspectedFieldsOptions()
    let inspectorOptions = options.filter(function (option) {
      return option.name.toUpperCase().includes(self.state.search.toUpperCase())
    })
    return ["basic", "grid", "column", "others"].map(function(group) {
      let groupOptions = inspectorOptions.filter(function(option) {
        return option.group == group
      })
      return [group, groupOptions]
    })
  }

  makeInspectorOption = (option, i) => {
    let help = option.help ? <i className="help icon" data-tt-content={option.help}/> : null
    return <div key={option.path.join('_')} style={{display: 'flex', paddingTop: '2px', marginRight: '5px', flexShrink: 0, backgroundColor: i%2 == 0 ? 'rgba(0,0,50,.05)' : null}}>
             <div style={{width: '200px'}}>{option.name}</div>
             <div style={{width: '200px'}}>
              <InspectorOption option={option} field={this.props.inspectedField} onOptionValue={this.inspectedFieldsOptionValue} onSetOptionValue={this.inspectedFieldsSetOptionValue}
                onSetSelectedField={this.props.onSetSelectedField} onStoreForm={this.props.onStoreForm} onGetFormCopy={this.props.onGetFormCopy} onCancelChange={this.props.onCancelChange}
                onFormWasChanged={this.props.onFormWasChanged}/></div>
            <div style={{fontSize: '10px'}}>{help}</div> 
           </div>
  }

  makeInspectorItems = () => {
    let self = this
    let groupOptions = this.groupOptions().filter(function(group) {
      return group[1].length != 0
    })
    let groupNames = {basic: GetText.t("Basic"), grid: "Grid", column: GetText.t("Column"), others: GetText.t("Others")}
    let modelerReact = this.props.inspectedFields[0].getModelerReact()
    groupNames["grid"] += " (" + MC.getAvailableResolution(modelerReact.state.resolution, modelerReact.state.form) + ")"
    let inspectorOptions = groupOptions.map(function (groupAndOptions, i) {
      let inspectorOptions = groupAndOptions[1].map(function(option, i) {
        return self.makeInspectorOption(option, i)
      })
      let groupHeader = <div key={'group-' + groupNames[groupAndOptions[0]]} style={{fontWeight: 'bold', paddingTop: i > 0 ? '10px' : 0}}>{groupNames[groupAndOptions[0]]}</div>
      return [groupHeader, inspectorOptions]
    })
    return inspectorOptions.reduce((a, b) => {return a.concat(b)}, [])
  }
    
  render () {
    let style = {top: this.props.position.y, left: this.props.position.x,  width: 850, position: 'absolute', zIndex: 100000, background: '#FFF', border: '1px solid rgba(34,36,38,.15)'}
    if (this.props.loaded) {
      let editInRepository
      for (let field of this.props.inspectedFields) {
        if (!field.isNotStored) {
          editInRepository = <div style={{padding: '5px'}}><button className="mnc icon button" onClick={this.editInRepository} data-tt-content={GetText.t("Edit in repository")}><i className={"edit icon"}/></button></div>
          break
        }
      }
      return (
        <div id="inspector" ref={this.rootRef} style={style}>
          <div style={{borderBottom: '1px solid rgb(230, 230, 230)', display: 'flex', alignItems: 'center'}}>
            <div className="mnc icon input" style={{padding: '5px'}}>
              <input ref={this.searchRef} type="text" placeholder={GetText.t("Search...")} onChange={this.handleSearchChange} value={this.state.search}/>
              <i className="search icon"/>
            </div>
            <div style={{padding: '5px'}}>
              <button className="mnc button" onClick={this.props.onDeleteSelectedFields} data-tt-content={GetText.t("Delete field")}>{GetText.t("Delete")}</button>
            </div>
            {editInRepository}
          </div>
          <div className="inner" style={{padding: '5px', display: 'flex', flexDirection: 'column', overflow: 'auto', height: '700px', flexWrap: 'wrap'}}>
            {this.makeInspectorItems()}
          </div>
        </div>)
    } else {
      return <div style={style}><div className="mnc loader"></div></div>
    }
  }
}

function reduceValues (values) {
  return values.reduce(function(v1, v2) {
    if (isIndeterminate(v1)) {
      return v1;
    }
    if (v1 == v2) {
      return v1;
    } else {
      return {message: "indeterminate"};
    }
  });
}

function isIndeterminate (value) {
  return value instanceof Object && value.message == "indeterminate";
}

function isValid(option, value) {
  if (option.required && MC.isNull(value)) {
    return false
  }
  if (!MC.isNull(value) && Array.isArray(option.enum) && option.enum.indexOf(value) == -1) {
    return false
  }
  return true
}

export {Inspector}