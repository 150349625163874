import React from "react"

import Dropdown from './semantic-widgets/Dropdown.jsx'
import {MC} from './MC.js'
import {ReactFlow} from './ReactFlow.jsx'

class FlowInput extends React.Component {

  mounted = false
  state = {flowName: this.props.flowName, inputData: this.props.inputData, operationList: []}

  componentDidMount() {
    const self = this
    MC.onRegisterReactRomponent(() => self.forceUpdate())
    self.loadOperations()
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSubmitWithInput = (e) => {
    e.preventDefault()
    this.run(true)
  }

  handleSubmitNoInput = (e) => {
    e.preventDefault()
    this.run(false)
  }

  run(withInput) {
    this.props.onRun(this.state.flowName, (withInput ? this.state.inputData : null))
  }

  changeOperation = (e, combo) => {
    this.loadInput(combo.value[0]);
    this.setState({flowName: combo.value[0]})
  }

  changeInput = (e) => {
    this.setState({inputData: e.target.value})
  }

  loadOperations = () => {
    let configuration = this.props.configuration
    if (!configuration.startsWith('miniclient;')) {
      return
    }
    let self = this
    let url = self.props.mconf.baseUrl + ReactFlow.flowServerUrl + 'miniclientoplist/' + configuration;
    MC.callServer('GET', url, 'application/json').then(function (res) {
      let output = {}
      if (res.content) {
        try {
          output = JSON.parse(res.content)
        } catch (e) {
          if (res.status == 404) {
            output.errorMessage = 'Configuration path "' + configuration + '" not found!'
          } else {
            output.errorMessage = 'Error parsing operation list for configuration "' + configuration + '"!'
          }
        }
      }
      if (res.status == 200 || res.status == 204) {
        if (output.props['fl:component']) {
          let operationList = []
          for (let component of MC.asArray(output.props['fl:component'])) {
            if (!component['fl:operation']) {
              continue
            }              
            for (let operation of MC.asArray(component['fl:operation'])) {
              operationList.push({title: operation.name + ' (' + operation.kind + ')', name: operation.name, component: component.name})
            }
          }
          if (!self.mounted) { return; }
          self.setState({operationList: operationList})
        }
      } else {
        let message = ''
        if (output.errorName) {
          message += output.errorName + ': '
        }
        message += 'Loading list operations failed! Status:' + res.status
        if (output.errorMessage) {
          message += ' ' + output.errorMessage
        }
        MC.error(message)
      }
    })

  }

  getModelNsMap(items) {
    var result = {};
    for (var i=0; i<items.length; i++) {
      result[items[i]['prefix']] = items[i]['uri'];
    }
    return result;
  }

  getNsDefinition(nsMap) {
    var result = '';
    for (var pref in nsMap) {
      result += ' xmlns:' + pref + '="' + nsMap[pref] + '"';
    }
    return result;
  }

  loadInput(flowName) {
    var self = this;
    if (!flowName) {
      return;
    }
    this.setState({inputLoading: true});
    MC.getFlowDefinition(flowName, self.props.configuration, self.props.mconf.lang, self.props.mconf.baseUrl, null).then((flowData) => {
      if (flowData.input) {
        var nsMap = {};
        self.getInputNsMap(flowData, self.getModelNsMap(flowData.ns), nsMap);
        if (!self.mounted) { return; }
        self.setState({inputData: '<data' + self.getNsDefinition(nsMap) + '>' + self.convertInputToXml(flowData, '    ') + '\n</data>', inputLoading: false})
      } else {
        if (!self.mounted) { return; }
        self.setState({inputData: '<data>\n</data>', inputLoading: false})
      }
    })
  }

  getInputNsMap(inputTree, nsMap, result) {
    for (var i=0; i<inputTree.input.length; i++) {
      var param = inputTree.input[i];
      var key = param.name;
      if (key && key.indexOf(':') > 0) {
        var pref = key.substring(0, key.indexOf(':'));
        if (!nsMap[pref]) {
          MC.error('Namespace with prefix ' + pref + ' is not defined!');
        }
        if (!result[pref]) {
          result[pref] = nsMap[pref];
        }
      }
      if (param.input) {
        this.getInputNsMap(param, nsMap, result);
      }
    }
  }

  convertInputToXml(inputTree, gaps) {
    var result = '';
    for (var i=0; i<inputTree.input.length; i++) {
      var param = inputTree.input[i];
      var key = param.name;
      if (!key) {
        continue
      }
      if (key.endsWith('*')) {
        key = key.substring(0, key.length-1);
      }
      if (param.sample) {
        result += '\n' + gaps + '<'+ key + '>';
        result += param.sample;
        result += '</'+ key + '>';
      } else {
        for (var p = 0; p < 1; p++) {
          result += '\n' + gaps + '<'+ key + '>';
          if (param.input) {
            result += this.convertInputToXml(param, gaps + '  ');
            result += '\n' + gaps + '</'+ key + '>';
          } else {
            result += param.basictype;
            result += '</'+ key + '>';
          }
        }
      }
    }
    return result;
  }

  render() {
    let options = []
    options.push({value: '', text: ''}) 
    if (this.state.operationList.length > 0) {
      for (let op of this.state.operationList) {
        options.push({ value: op.name, text: op.title, content: (<React.Fragment>{op.title}</React.Fragment>), group: op.component})
      }
    }
    var runable = (this.state.flowName ? true : false)
    let componentsHtml = null
    let extComponents = MC.getReactRomponents()
    if (!MC.isEmptyObject(extComponents)) {
      componentsHtml = (
        <div className="fields">
          <div className="mnc sixteen wide field">Registered external widgets: {Object.keys(extComponents).join(', ')}</div>
        </div>)
    }

    return (
      <div className="mnc icon message">
        <i className="setting icon"></i>
        <div className="content">
          <div className="mnc form" id="inputForm" onSubmit={this.handleSubmitNoInput} role="form">
            {componentsHtml}
            <div className="mnc grid">
              <div className="mnc row">
                <div className="mnc eleven wide column field">
                  <label htmlFor="flowName">Flow in '{this.props.configuration}'</label>
                  <Dropdown className="selection" search value={this.state.flowName} options={options} onChange={this.changeOperation} disabled={this.state.operationList.length > 0 ? false : true}/>
                  <label htmlFor="help"></label>  
                </div>
                <div className="mnc one wide column field">
                  <label>&nbsp;</label>
                  <button className="mnc icon button" onClick={this.loadOperations}>
                    <i className="refresh icon" title="Reload operation list"></i>
                  </button>
                </div>
              </div>
              <div className="mnc row">
                <div className="mnc twelve wide column field">
                  <label htmlFor="inputData">Input data
                    <i className={"refresh icon mnc clickable" + (runable ? "" : " disabled") + (this.state.inputLoading ? " loading" : "")} title="Refresh default input data" onClick={() => this.loadInput(this.state.flowName)}></i>
                  </label>
                  <textarea name="inputData" value={this.state.inputData ? this.state.inputData : ''} disabled={!runable} onChange={this.changeInput}></textarea>
                  <label htmlFor="help"></label>
                </div>
              </div>
              <div className="mnc row">
                <div className="mnc twelve wide field column">
                  <button className="mnc right labeled icon button positive" type="submit" disabled={!runable} onClick={this.handleSubmitNoInput}>
                    <i className="right play icon"></i>
                    Run
                  </button>
                  <button className="mnc right labeled icon button positive basic" type="submit" disabled={!runable} onClick={this.handleSubmitWithInput}>
                    <i className="right play icon"></i>
                    Run with input
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export {FlowInput}