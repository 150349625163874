import React from "react"

import {MC} from './MC.js'

class WhisperBox extends React.Component {

  state = {matchedData: [], showDropdown: false, data: [], activeIndex: null, param: {}}
  rootRef = React.createRef()

  componentDidMount() {
    this.updateData(this.props)
    document.addEventListener('MNC.CLICK', this.handleOutsideClick)
    document.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('MNC.CLICK', this.handleOutsideClick)
    document.removeEventListener('click', this.handleOutsideClick)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!MC.objectEqual(prevState.param, this.props.data.param, true)) {
      this.updateData(this.props)
    }
  }

  updateData(props) {
    var field = props.data;
    var value = MC.getFieldParamValue(props.data.param, 'value')
    var titleValue = MC.getFieldParamValue(props.data.param, 'text')
    let newState = {...this.state, param: MC.extend({}, props.data.param)}
    var data = []
    if (field.param['items']) {
      for (let item of field.param['items']) {
        if (!MC.isNull(item['@key'])) {
          data.push({key: item['@key'].toString(), title: MC.isNull(item['@title']) ? item['@key'].toString() : item['@title'].toString(), titleHtml: item['@titleHtml']})
          if (!MC.isNull(value) && MC.isNull(titleValue)) {
            if (value == item['@key'].toString()) {
              this.handleSetValue(value, item['@title'].toString(), false)
            }
          } else if (!MC.isNull(titleValue) && MC.isNull(value)) {
            if (titleValue == item['@title'].toString()) {
              this.handleSetValue(item['@key'].toString(), titleValue, false)
            }
          }
        }
      }
    }
    if (MC.getFieldParamBooleanValue(props.data.param, '@forceSearch')) {
      MC.putFieldParamValue(props.data.param, '@forceSearch', false)
      newState.matchedData = data
      newState.showDropDown = true
    }
    newState = {...newState, data: data, activeIndex: null}
    if (!MC.objectEqual(this.state, newState, true)) {
      this.setState(newState)
    }
  }

  handleChange = (e) => {
    let value = e.target.value
    this.props.widget.handleTextChange(value, value)
    this.triggerChange(value)
  }

  triggerChange(value) {
    if (value.trim()) {
      let matchedData = this.searchQuery(value.trim(), this.state.data)
      this.setState({matchedData: matchedData, showDropdown: true, activeIndex: null})
    } else {
      this.setState({showDropdown: false})
    }
  }  

  searchQuery(value, array) {
    var whisperType =  MC.getFieldParamValue(this.props.data.param, '@whisperType')
    var searchTitle = true;
    if (whisperType == 'value') {
      searchTitle = false;
    }
    var searchKey = false;
    if (whisperType == 'value' || whisperType == 'both') {
      searchKey = true;
    }
    var result = [];
    value = value.toLowerCase()
    for (var i=0; i<array.length; i++) {
      if (searchKey && array[i]['key'].toLowerCase().indexOf(value) !== -1 || searchTitle && array[i]['title'].toLowerCase().indexOf(value) !== -1) {
        result.push(array[i]);
      }
    }
    return result;
  }

  handleSetValue(value, titleValue, triggerBlur) {
    this.setState({showDropdown: false}, function() {
      if (this.props.textMode) {
        return
      }
      this.props.widget.handleTextChange(value, titleValue, {notypingbreak: true})
      if (triggerBlur) {
        if (MC.getFieldParamBooleanValue(this.props.data.param, '@preventBlurOnSelect')) {
          this.props.widgetRef.current.focus()
        } else {
          this.props.onBlur()
        }
      }
    })
  }

  handleNoValue() {
    this.setState({showDropdown: false}, function() {
      this.props.widget.handleTextChange(null, null, {notypingbreak: true})
    })
  }

  activateItem(i) {
    this.setState({activeIndex: i});
  }

  handleClickItem = (item) => {
    if (this.props.readOnly) {
      return
    }
    this.handleSetValue(item['key'], item['title'], true)
  }  

  handleKeyDown = (e) => {
    if (!this.state.matchedData || !Array.isArray(this.state.matchedData) || this.props.readOnly) {
      return;
    }
    if (e.key == 'Enter') {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      if (Number.isInteger(this.state.activeIndex) && (this.state.matchedData && Array.isArray(this.state.matchedData))) {
        this.handleSetValue(this.state.matchedData[this.state.activeIndex]['key'], this.state.matchedData[this.state.activeIndex]['title'], true)
      }
    } else if (e.key == 'Escape') {
      if (this.state.showDropdown) {
        e.stopPropagation()
        e.preventDefault()
      }
      this.setState({activeIndex: null, showDropdown: false});
    } else if (e.key == 'ArrowUp') {
      if (Number.isInteger(this.state.activeIndex) && this.state.activeIndex > 0) {
        this.setState({activeIndex: this.state.activeIndex - 1});
      } else {
        this.setState({activeIndex: this.state.matchedData.length -1});
      }
    } else if (e.key == 'ArrowDown') {
      if (Number.isInteger(this.state.activeIndex) && this.state.activeIndex + 1 < this.state.matchedData.length) {
        this.setState({activeIndex: this.state.activeIndex + 1});
      } else {
        this.setState({activeIndex: 0});
      }
    } else if (e.key == 'Tab') {
      if (this.state.showDropdown) {
        if (MC.getFieldParamBooleanValue(this.props.data.param, '@forceValue')) {
          this.handleNoValue()
        } else {
          this.setState({activeIndex: null, showDropdown: false})
        }
      }
    }
  }

  handleOutsideClick = (e) => {
    if (this.props.textMode || this.rootRef.current.contains(e.detail.target) || this.props.readOnly) {
      return
    }
    if (this.state.showDropdown) {
      if (MC.getFieldParamBooleanValue(this.props.data.param, '@forceValue')) {
        this.handleNoValue()
      } else {
        this.setState({activeIndex: null, showDropdown: false})
      }
      this.props.onBlur()
    }
  }

  blur = (e) => {
    if (this.state.showDropdown) {
      e.preventDefault()
      e.stopPropagation() 
    } else {
      this.props.onBlur()
    }
  }  

  render() {
    var inputCls = "input " + (this.state.showDropdown ? "input-show-dropdown" : "");
    var dropDown = null;
    if (this.state.showDropdown) {
      if (this.state.matchedData && Array.isArray(this.state.matchedData) && this.state.matchedData.length > 0) {
        var mdata = this.state.matchedData;
        var items = [];
        for (var i=0; i<mdata.length; i++) {
          var cls = 'dropdown-ul-li' + (this.state.activeIndex === i ? ' active' : '');
          if (mdata[i]['titleHtml']) {
            items.push(<li key={i} className={cls} dangerouslySetInnerHTML={{__html: mdata[i]['titleHtml']}} onClick={this.handleClickItem.bind(this, mdata[i])} onMouseEnter={this.activateItem.bind(this, i, true)}></li>)
          } else {
            items.push(<li key={i} className={cls} onClick={this.handleClickItem.bind(this, mdata[i])} onMouseEnter={this.activateItem.bind(this, i, true)}>{mdata[i]['title']}</li>)
          }
        }
        dropDown = <div className="dropdown"><ul className="dropdown-ul">{items}</ul></div>
      }
    }
    let titleValue = MC.getFieldParamValue(this.props.data.param, 'text') || ""
    if (this.props.textMode) {
      return titleValue
    }
    let input =  <input type="text" className={inputCls} placeholder={this.props.placeholder} onChange={this.handleChange} value={titleValue} onFocus={this.props.onFocus} onBlur={this.blur}
                   readOnly={this.props.readOnly} disabled={this.props.disabled} data-widget-i-name={this.props.data.id} ref={this.props.widgetRef}/>
    let icon = MC.getFieldParamValue(this.props.data.param, '@icon')
    if (icon) {
      let iconPlacement = MC.getFieldParamValue(this.props.data.param, '@iconPlacement')
      input = <div className={MC.classes("mnc", {"left": iconPlacement !== 'right'}, "icon input")} key="inwrap">{input}<i key="icon" className={MC.buildIconClass(icon)}></i></div>
    }
    return (
      <div className="whisperbox" onKeyDown={this.handleKeyDown} ref={this.rootRef}>
        <div className="search-input">{input}</div>
        <div className="search-dropdown">{dropDown}</div>
      </div>
    );
  }

}

export {WhisperBox}
