import React from "react"

class EditableLabel extends React.Component {

  state = {isActive: false}
  inputRef = React.createRef()

  render() {
    if (this.state.isActive) {
      return <div className="mnc input editable-label" style={{textAlign: "left"}}>
               <input type="text" value={this.state.value} onKeyUp={this.handleKeyUp} onChange={this.handleChange} onBlur={this.handleBlur} ref={this.inputRef}/>
             </div>
    } else {
      if (this.props.nospan) {
        return React.cloneElement(this.props.widget, {onDoubleClick: this.handleDoubleClick})
      } else {
        return <section onDoubleClick={this.handleDoubleClick}>{this.props.widget}</section>
      }
    }
  }

  componentDidUpdate() {
    if (this.state.isActive) {
      this.inputRef.current.focus()
      this.props.field.getModelerReact().isDragPossible = false
    }
  }

  deactivate() {
    let field = this.props.field
    let value = this.state.value
    let path = this.props.path
    let modelerReact = field.getModelerReact()
    let lastId = field.id
    try {
      if (typeof value === "undefined") {
        value = ""
      }
      field.setOption(path, value)
      if (path.length === 1 && path[0] === "id") {
        if (lastId === modelerReact.state.selectedField) {
          modelerReact.setState({selectedField: field.id})
        }
      }
    } catch (e) {
    }

    this.setState({isActive: false})
    modelerReact.isDragPossible = true
    modelerReact.formWasChanged()
    modelerReact.store(modelerReact.state.form.getCopy())
  }

  handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      this.deactivate()
    }
    event.stopPropagation()
  }

  handleChange = (event) => {
    this.setState({value: event.target.value})
  }

  handleDoubleClick = () => {
    this.setState({isActive: true, value: this.props.field.getOption(this.props.path)})
  }

  handleBlur = () => {
    this.deactivate()
  }

}

export {EditableLabel}