import React from "react"

import {MC} from './MC.js'
import {MCHistory} from "./MCHistory.js"
import {Modal} from "./Modal.jsx"
import {ReactFlow} from "./ReactFlow.jsx"
import {Value} from "./Value.js"

class Dialog extends React.Component {

  state = {flowName: null, start: false}
  flowRef = React.createRef()

  componentDidMount() {
    this.checkStart()
  }

  componentDidUpdate() {
    this.checkStart()
  }

  checkStart() {
    const props = this.props
    if (props.dialog && MC.isPlainObject(props.dialog) && props.dialog.start) {
      let dialog = props.dialog
      dialog.start = false
      if (!MC.isNull(props.dialog.flowName)) {
        let flowInstanceId = MC.generateId()
        this.setState({flowName: props.dialog.flowName, start: true, flowInstanceId: flowInstanceId})
        MCHistory.history(this.props.dialog.parentFlow, dialog.action, 'DIALOG CALL', {'Input': dialog.input, 'Trace': dialog.trace, target: {...dialog.action.interface, ...{id: dialog.flowName}}, executionId: flowInstanceId}, {start: dialog.lStartDate, end: Date.now(), duration: performance.now() - dialog.lStart})  
      } else {
        this.setState({flowName: null, start: false})
      }
    } else if (this.state.start) {
      this.setState({start: false})
    }
  }

  close = () => {
    if (this.state.flowName) {
      if (this.props.dialog.action.dialogCloseAction) {
        let child = this.flowRef.current
        child.flow.handleSubmit(child.state.formData, this.props.dialog.action.dialogCloseAction)
      } else {
        this.setState({flowName: null})
        this.props.dialog.parentFlow.closeDialog()
      }
    }
  }

  onEnd = (output, message, options) => {
    if (this.state.flowName) {
      this.setState({flowName: null})
      document.querySelector('body').classList.remove('showing-modal')
      this.props.dialog.parentFlow.endDialog(output, message, false, options.endAction)
    }
  }

  render() {
    if (this.state.flowName && this.props.dialog) {
      let dialog = this.props.dialog
      let closeIcon = !MC.isNull(dialog.closeIcon) ? dialog.closeIcon : dialog.asContext ? false : true
      let flow = dialog.parentFlow
      let flowHtml = <ReactFlow configuration={flow.confPath} flowName={this.state.flowName} env={flow.context.data.env} input={dialog.input} start={this.state.start} onEndFunction={this.onEnd} embedded={true} 
        options={flow.reactFlow().props.options} parent={flow.reactFlow()} debug={flow.wantedLogLevel} console={false} autoScrollUp={false}
        mconf={flow.reactFlow().props.mconf} instanceId={this.state.flowInstanceId} ref={this.flowRef} baseUrl={flow.baseUrl}/>
      return <Modal show={true} onClose={this.close} cssClass={MC.classes(dialog.size, dialog.cssClass)} closeIcon={closeIcon} asContext={dialog.asContext} target={dialog.target} position={dialog.position}>{flowHtml}</Modal>
    } else {
      return null
    }
  }

}

export {Dialog}