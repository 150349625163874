import React from "react"

import {MC} from './MC.js'

class Download extends React.Component {

  componentDidMount() {
    this.runDownload()
  }

  componentDidUpdate() {
    this.runDownload()
  }

  runDownload() {
    let data =  MC.getFieldParamValue(this.props.data.param, 'value/@data')
    if (!MC.isNull(data)) {
      MC.putFieldParamValue(this.props.data.param, 'value/@data', null)
      let uri = MC.base64toBlob(data, "octet/stream")
      let fileName =  MC.getFieldParamValue(this.props.data.param, 'value/@fileName')
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(uri, fileName)
      } else {
        uri = window.URL.createObjectURL(uri)
        let link = document.createElement('a')
        if (typeof link.download === 'string') {
          document.body.appendChild(link) // Firefox requires the link to be in the body
          link.download = fileName
          link.href = uri
          link.click()
          document.body.removeChild(link) // remove the link when done
        } else {
          location.replace(uri)
        }
      }
    }
  }

  render() {
    return <span data-widget-i-name={this.props.data.id}/>
  }

}

export {Download}