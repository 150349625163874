import ReactDOM from "react-dom/client"

import MiniclientConsole from "./MiniclientConsole.jsx"
import FormModeler from "./FormModeler.jsx"

function Miniclient() {

  this.config = (cobj) => {
    this.element = cobj.element || document.getElementById('content')
    this.conf =  cobj
    if (!cobj.baseUrl) {
      let base = document.querySelector('base').href.split('?')[0]
      cobj.baseUrl = base.substring(0, base.lastIndexOf("/") + 1)
    }
    if (cobj.registerServiceWorker) {
      if ('serviceWorker' in navigator) { // only when browser supports SWs
        navigator.serviceWorker.register('sw.js').then(function() {
          console.log("Service worker sw.js registered")
        }).catch(error => {
          console.error("Error during service worker registration:", error)
        })
      }
    }
    if (cobj.serverFunction) {
      window.mncServerFunction = cobj.serverFunction
    }
    return this
  }
  
  this.run = () => {
    let conf = this.conf
    if (conf.isFormModeler) {
      ReactDOM.createRoot(document.getElementById('content-modeler')).render(<FormModeler mconf={conf}/>)
    } else {
      const root = ReactDOM.createRoot(this.element)
      root.render(null)
      root.render(<MiniclientConsole mconf={conf}/>)
    }
    return this
  } 

}

window.Miniclient = Miniclient  