import React from "react"

import {MC} from './MC.js'

class Link extends React.Component {

  handleClick = (e) => {
    const field = this.props.data
    if (MC.isModelerActive(field)) {
      return
    }
    const url = MC.getFieldParamValue(field.param, '@url')
    if (MC.eventHasKey(e) && !MC.isNull(url)) {
      return
    }
    const behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior != 'url') {
      e.preventDefault()
    }
    const escapeHtml = MC.getFieldParamBooleanValue(field.param, '@escapeHtml')
    if (!escapeHtml && e.target.nodeName !== 'A') {
      return
    }
    if (MC.isFunction(this.props.onClick) && !MC.isModelerActive(this.props.data)) {
      this.props.onClick()
    }
    MC.handleEvent(field, 'click', null, e)
    if (behavior == 'url') {
      if (!MC.isNull(url)) {
        let target = MC.getFieldParamValue(field.param, '@target')
        if (['blank', 'parent', 'top'].indexOf(target) > -1) {
          return
        }
        field.flow.reactFlow().routeTo(e, url)
        return
      }
    } else {
      this.props.data.flow.handleSubmit(this.props.data)
    } 
  }

  handleDblClick = (e) => {
    MC.handleEvent(this.props.data, 'doubleclick', null, e)
  } 

  render() {
    const field = this.props.data
    let title = this.props.value
    let escapeHtml = MC.getFieldParamBooleanValue(field.param, '@escapeHtml')
    let cls
    if (this.props.cls) {
      cls = this.props.cls
    } else {
      cls = MC.getFieldParamValue(field.param, '@cssClass')
      if (escapeHtml) {
        cls = MC.classes('mnc link curpointer', cls)
      }
    }
    let css = MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))
    let href = MC.getFieldParamValue(field.param, '@url')
    let imageUrl = MC.getFieldParamValue(field.param, '@imageUrl')
    if (!MC.isNull(imageUrl)) {
      imageUrl = MC.rebaseUrl(MC.findRoot(field).componentName, imageUrl, field.flow.baseUrl)
      if (MC.getFieldParamValue(field.param, '@iconPlacement') === 'right') {
        title = <React.Fragment>{title}<img src={imageUrl}/></React.Fragment>
      } else {
        title = <React.Fragment><img src={imageUrl}/>{title}</React.Fragment>
      }
    } else if (escapeHtml) {
      title = MC.iconize(field, <span>{title}</span>)
    }
    if (this.props.disabled) {
      return <span id={field.rbsid} className={cls} style={css} ref={this.props.widgetRef}>{title}</span>
    } else {
      let target = MC.getFieldParamValue(field.param, '@target')
      if (['blank', 'parent', 'top'].indexOf(target) > -1) {
        target = '_' + target
      } else {
        target = null
      }
      if (escapeHtml) {
        return <a className={cls} style={css} id={field.rbsid} onClick={this.handleClick} onDoubleClick={this.handleDblClick} href={href} target={target} data-widget-i-name={field.id} ref={this.props.widgetRef}>{title}</a>
      } else {
        return <div className={cls} style={css} id={field.rbsid} onClick={this.handleClick} onDoubleClick={this.handleDblClick} dangerouslySetInnerHTML={{__html: MC.customHtml(title)}} data-widget-i-name={field.id} ref={this.props.widgetRef}></div>
      }
    }
  }

}

export {Link}