import React from "react"

import {MC} from "../client/MC.js"
import tt from "../client/tooltip/tooltip.js"

import {GetText} from "./GetText.js"

class Button extends React.Component {

  rooRef = React.createRef()

  componentDidMount() {
    tt(this.rooRef.current, {allowHTML: true})
  }

  render() {
    let cls = MC.classes("mnc", this.props.className, {"icon": this.props.icon}, "button", {"disabled": this.props.disabled, "active": this.props.active})
    let icon = this.props.icon ? <i className={this.props.icon + " icon"}/> : null
    return <button className={cls} onClick={this.props.onClick} data-tt-content={this.props.help} ref={this.rooRef}>{icon}{this.props.title}</button>
  }

}

class SaveButton extends React.Component {

  handleSave = () => {
    this.props.onSave()
  }

  render() {
    let iconCls = MC.classes({"spinner loading": this.props.inProcess, "save": !this.props.inProcess}, "icon")
    let cls = MC.classes("mnc button primary", {"disabled": !this.props.isSavePossible || this.props.inProcess})
    return <button className={cls} onClick={this.handleSave}><i className={iconCls}></i>{GetText.t("Save")}</button>
  }

}

class ResolutionButtons extends React.Component {

  refRoot = React.createRef()
  state = {menuActive: false}

  handleClick = (event) => {
    let resolution = event.currentTarget.getAttribute("data")
    this.props.onChangeResolution(resolution)
  }

  componentDidMount() {
    tt(this.refRoot.current.querySelectorAll("button"), {allowHTML: true})
    document.addEventListener('click', this.handleClickOutside, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false)
  }

  handleClickOutside = (e) => {
    const node = this.refRoot.current
    if (node && node.contains(e.target)) {
      return
    }
    if (this.state.menuActive) {
      this.setState({menuActive: false})
    }
  }

  toggleMenu = () => {
    this.setState({menuActive: !this.state.menuActive})
  }  

  render() {
    var self = this
    var shortcuts = {"x-small": "XS", small: "S", medium: "M", large: "L"};
    var resolutions = ["x-small", "small", "medium", "large"]
    var items = resolutions.map(function(e) {
      var text = shortcuts[e]
      var help = e + " layout"
      var cls = "mnc button"
      if (self.props.resolution == e) {
        cls += " active"
      }
      if (self.props.definedResolutions.indexOf(e) != -1) {
        text= <u>{text}</u>
        help += " (" + GetText.t("with grid") + ")"
      }
      return <button className={cls} key={e} data={e} onClick={self.handleClick} data-tt-content={help}>{text}</button>
    })
    let deleteGridClass = MC.classes("item", {"disabled": !this.props.isDeleteResolutionEnabled})
    let createGridClass = MC.classes("item", {"disabled": self.props.definedResolutions.indexOf(self.props.resolution) != -1})
    let menuCls = MC.classes("menu", this.state.menuActive && "visible transition")
    return <div className="mnc icon buttons" ref={this.refRoot}>
              {items}
              <div className="mnc floating dropdown icon button" onClick={this.toggleMenu}>
                <i className="dropdown icon" />
                <div className={menuCls}>
                  <div className={createGridClass} data-value="create" onClick={this.props.onCreateGrid}>
                    <i className="file icon"/>
                    {GetText.t("Create grid")}
                  </div>
                  <div className={deleteGridClass} data-value="delete" onClick={this.props.onDeleteGrid}>
                    <i className="delete icon red"/>
                    {GetText.t("Delete grid")}
                  </div>
                </div>
              </div>
            </div>
  }
}

class SearchResultItem extends React.Component {

  state = {hover: false}

  handleClick = (e) => {
    e.stopPropagation()
    this.props.handleSearchSelect(this.props.id)
  }

  render() {
    let rStyle = {cursor: 'pointer', display: 'block', overflow: 'hidden', fontSize: '1em', padding: '0.85714286em 1.14285714em', color: 'rgba(0,0,0,.87)', lineHeight: 1.33, borderBottom: '1px solid rgba(34,36,38,.1)', background: this.state.hover ? '#f9fafb' : null}
    return <div style={rStyle} onClick={this.handleClick} onMouseEnter={() => {this.setState({hover: true})}} onMouseLeave={() => {this.setState({hover: false})}}>{this.props.id}</div>
  }

}

class Menu extends React.Component {

  state = {search: "", searchIds: [], searchHoverIndex: null}
  refRoot = React.createRef()
  searchMenuRef = React.createRef()

  handleSearchChange = (event) => {
    this.setSearchValue(event.target.value)
  }

  setSearchValue = (value, clearResults) => {
    if (value == "") {
      this.props.setSelectedFields([])
      this.setState({search: value, searchIds: []})
      document.removeEventListener('click', this.handleClickOutside, false)
    } else {
      let form = this.props.form
      let fields = form.findFieldsHelp(value, form.fields, true)
      let fieldsRbsId = fields.map(function(field) {return field.rbsid})
      let fieldsId = fields.map(function(field) {return field.id})
      this.props.setSelectedFields(fieldsRbsId)
      this.setState({search: value, searchIds: clearResults ? [] : fieldsId})
      if (!clearResults) {
        document.addEventListener('click', this.handleClickOutside, false)
      } else {
        document.removeEventListener('click', this.handleClickOutside, false)
      }
    }
  }

  componentDidMount() {
    let helpPopup =  `<div style="min-width: 450px; width: 450px; padding: 10px;">
      <div class="mnc header">${GetText.t('Controls')}</div>
      <table class="mnc very basic table">
        <tbody>
          <tr>
            <td>
              <div class="mnc label">Shift+S</div>
            </td>
            <td>${GetText.t('Save')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Ctrl+X</div>
            </td>
            <td>${GetText.t('Cut')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Ctrl+C</div>
            </td>
            <td>${GetText.t('Copy')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Ctrl+V</div>
            </td>
            <td>${GetText.t('Paste')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Ctrl+Z</div>
            </td>
            <td>${GetText.t('Undo')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Ctrl+Y</div>
            </td>
            <td>${GetText.t('Redo')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Ctrl+L</div>
            </td>
            <td>${GetText.t('Toggle end of line')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Ctrl+A</div>
            </td>
            <td>${GetText.t('Select All')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Backspace</div>
              <div class="mnc label">Delete</div>
            </td>
            <td>${GetText.t('Delete')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Ctrl+Click</div>
            </td>
            <td>${GetText.t('Select field')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">${GetText.t('Context menu')}</div>
            </td>
            <td>${GetText.t('Show palette')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">Shift+Drag</div>
            </td>
            <td>${GetText.t('Change offset')}</td>
          </tr>
          <tr>
            <td>
              <div class="mnc label">${GetText.t('Double click')}</div>
            </td>
            <td>${GetText.t('Change the name or title')}</td>
          </tr>
        </tbody>
      </table>
    </div>`
    tt(this.refRoot.current.querySelector(".help.icon"), {content: helpPopup, interactive: true, maxWidth: 470, allowHTML: true, placement: 'bottom'})
  }

  handleSearchClick = () => {
    this.setSearchValue(this.state.search)
  }

  handleClickOutside = (e) => {
    const node = this.searchMenuRef.current
    if (node && node.contains(e.target)) {
      return
    }
    if (this.state.searchIds.length > 0) {
      e.stopPropagation()
      this.setSearchValue(this.state.search, true)
    }
  }

  handleSearchSelect = (value) => {
    this.setSearchValue(value, true)
  }

  render() {
    let isDeleteResolutionEnabled = (this.props.definedResolutions.indexOf(this.props.resolution) != -1) && (this.props.definedResolutions.length > 1)
    let undoRedoButtons = <div className="mnc icon buttons">
                            <Button icon="undo" onClick={this.props.onUndo} disabled={!this.props.isUndoStack} help={GetText.t('Undo')}/>
                            <Button icon="horizontally flipped redo" onClick={this.props.onRedo} disabled={!this.props.isRedoStack} help={GetText.t('Redo')}/>
                          </div>
    let resolutionButtons = <ResolutionButtons definedResolutions={this.props.definedResolutions} resolution={this.props.resolution} isDeleteResolutionEnabled={isDeleteResolutionEnabled} onChangeResolution={this.props.onChangeResolution}
                              onDeleteGrid={this.props.onDeleteGrid} onCreateGrid={this.props.onCreateGrid}/>
    let saveButton = <SaveButton isSavePossible={this.props.isSavePossible} onSave={this.props.onSave} inProcess={this.props.saveInProcess}/>
    let searchResults = null
    if (this.state.searchIds.length > 0) {
      let items = []
      for (let id of this.state.searchIds) {
        items.push(<SearchResultItem key={MC.generateId()} id={id} handleSearchSelect={this.handleSearchSelect}/>)
      }
      let stl = {position: 'absolute', top: '100%', left: 0, background: '#fff', width: '18em', borderRadius: '0.28571429rem', boxShadow: '0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%)', border: '1px solid #d4d4d5', zIndex: 998}
      searchResults = <div style={stl}>{items}</div>
    }
    let itemStyle = {paddingLeft: '5px', paddingRight: '5px', paddingTop: '2px', paddingTottom: '2px'}
    return  <div ref={this.refRoot} style={{borderBottom: '1px solid rgb(230, 230, 230)', display: 'flex', alignItems: 'center'}}>
              <div style={itemStyle}>
                {undoRedoButtons}
              </div>
              <div style={itemStyle}>
                {resolutionButtons}
              </div>
              <div style={itemStyle}>
                <div className="mnc icon buttons">
                  <Button icon="eye" active={this.props.ghostMode} onClick={this.props.onTongueGhostMode} help={GetText.t('Toggle display of invisible fields')}/>
                  <Button icon="lab" active={this.props.modelerState.structuralMode} onClick={this.props.tongueStructuralMode} help={GetText.t('Toggle structural view')}/>
                </div>
            </div>
            <div style={itemStyle}>
              <Button icon="browser"  onClick={this.props.showPalette} help={GetText.t('Show palette')}/>
            </div>
            <div style={itemStyle}>
              <div ref={this.searchMenuRef} style={{position: 'relative'}}>
                <div className="mnc icon input">
                    <input type="text" placeholder={GetText.t("Search...")} onChange={this.handleSearchChange} onClick={this.handleSearchClick} value={this.state.search} autoComplete="off"/>
                    <i className="search icon"/>
                </div>
                {searchResults}
              </div>
            </div>
            <div style={itemStyle}>
              <i className="help icon"></i>
           </div>
              <div style={{marginLeft: 'auto'}}>
                <div style={itemStyle}>
                  {saveButton}
                </div>
              </div>
            </div>
    }

}

export {Menu}