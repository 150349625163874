import React from "react"

import {MC} from './MC.js'
import tt from "./tooltip/tooltip.js"

class Menu extends React.Component {

  constructor(props) {
    super(props)
    this.onclick = this.onclick.bind(this)
    this.handleRootMouseEnter = this.handleRootMouseEnter.bind(this)
    this.handleRootMouseLeave = this.handleRootMouseLeave.bind(this)
    this.menuRef = React.createRef()  
  }

  componentDidMount() {
    if (!MC.isModelerActive(this.props.data)) {
      if (this.menuRef.current) {
        let escape = MC.getFieldParamBooleanValue(this.props.data.param, '@escapeTooltipHtml')
        tt(this.menuRef.current.querySelectorAll('[data-tt-content]'), {allowHTML: !escape})
      }
      document.addEventListener('MNC.CLICK', this.handleOutsideClick)
      document.addEventListener('click', this.handleOutsideClick)
    }
  }

  componentWillUnmount() {
    if (!MC.isModelerActive(this.props.data)) {
      document.removeEventListener('MNC.CLICK', this.handleOutsideClick)
      document.removeEventListener('click', this.handleOutsideClick)
    }
  }

  recalculateActiveKey(activeKey) {
    if (!MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu') && MC.getFieldParamBooleanValue(this.props.data.param, '@vertical')) {
      let items = MC.getFieldParamValue(this.props.data.param, 'items')
      if (Array.isArray(items) && activeKey) {
        for (let item of items) {
          let activeItem = false
          if (Array.isArray(item.items)) {
            activeItem = item.items.find((subitem) => {
              let activeSubItem = false
              if (Array.isArray(subitem.items)) {
                activeSubItem = subitem.items.find((ssubitem) => {
                  return ssubitem.key && ssubitem.key == activeKey
                })
              }
              if (subitem.key && subitem.key == activeKey || activeSubItem) {
                subitem.active = true
                subitem.opened = true
                return true
              }
              return false
            })
          }
          if (item.key && item.key == activeKey || activeItem) {
            item.active = true
            item.opened = true
          }
        }
      }
    }
    this.props.data.activeKeyWasRecalculated = true
    this.activeKey = activeKey
  }

  handleOutsideClick = (e) => {
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu') || !MC.getFieldParamBooleanValue(this.props.data.param, '@vertical')) {
      if (!this.menuRef.current || this.menuRef.current.contains(e.detail.target)) {
        return
      }
      this.closeDropDown()
    }
  }

  getItemByIndex(index) {
    index = index.split("-")
    let item = MC.getFieldParamValue(this.props.data.param, 'items')[index.shift()]
    while (index.length > 0) {
      item = item.items[index.shift()]
    }
    return item
  }

  closeDropDown() {
    let items = MC.getFieldParamValue(this.props.data.param, 'items')
      if (Array.isArray(items) && items.length > 0) {
        for (let item of items) {
          if (item.opened) {
            item.opened = false
            if (Array.isArray(item.items) && item.items.length > 0) {
              for (let sitem of item.items) {
                if (sitem.opened) {
                  sitem.opened = false
                  break
                }
              }  
            } 
            break
          }  
        }  
      }
      this.forceUpdate()
  }

  onclick(e) {
    let item = this.getItemByIndex(e.currentTarget.dataset.i)
    if (MC.eventHasKey(e) && !MC.isNull(item.url)) {
      return
    }
    let field = this.props.data
    let behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior != 'url' || item.enabled == false) {
      e.preventDefault()
    }
    e.stopPropagation()
    if (item.enabled == false) {
      return
    }
    if (Array.isArray(item['items']) && item['items'].length > 0 && item['items'][0] && item['items'][0].title) {
      if (!MC.getFieldParamBooleanValue(field.param, '@dropdownSubMenu')) {
        if (behavior == 'url' && !MC.isNull(item.url)) {
          return
        }
        item.opened = !item.opened
        this.forceUpdate()
        MC.handleEvent(field, 'change')
      }
    } else {
      if (MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu') || !MC.getFieldParamBooleanValue(this.props.data.param, '@vertical')) {
        this.closeDropDown()
      }
      if (behavior == 'url') {
        if (!MC.isNull(item.url)) {
          this.props.data.flow.reactFlow().routeTo(e, item.url)
          return
        }
      } else {
        MC.putFieldParamValue(this.props.data.param, '@activeKey', item.key)
        MC.handleEvent(field, 'change')
        this.forceUpdate()
        field.flow.handleSubmit(field)
      }
    }
    MC.handleEvent(field, 'click', null, e)
  }

  handleRootMouseEnter(e) {
    const asDropdown = MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu')
    if (asDropdown) {
      let item = this.getItemByIndex(e.currentTarget.dataset.i)
      if (Array.isArray(item.items) && item.items.length > 0) {
        item.opened = true
        this.forceUpdate()
      }
    }
  }

  handleRootMouseLeave(e) {
    const asDropdown = MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu')
    if (asDropdown) {
      let item = this.getItemByIndex(e.currentTarget.dataset.i)
      if (Array.isArray(item.items) && item.items.length > 0) {
        item.opened = false
        this.forceUpdate()
      }
    }
  }

  buildSubmenu(item, rooti) {
    let submenu = ""
    let params = this.props.data.param
    let activeKey = MC.getFieldParamValue(params, '@activeKey')
    let behavior = MC.getFieldParamValue(params, '@behavior')
    if (MC.isNull(rooti) && !MC.isNull(activeKey)) {
      if (this.activeKey !== activeKey || !this.props.data.activeKeyWasRecalculated) {
        this.recalculateActiveKey(activeKey)
      }
    }
    if (Array.isArray(item['items']) && item['items'].length > 0) {
      if (item.opened || MC.isNull(rooti)) {
        let asDropdown = MC.getFieldParamBooleanValue(params, '@dropdownSubMenu')
        let vertical = MC.getFieldParamBooleanValue(params, '@vertical')
        let iconPlacement = MC.getFieldParamValue(params, '@iconPlacement') || 'left'
        let items = []
        for (let i=0; i<item['items'].length; i++) {
          let strI = !MC.isNull(rooti) ? rooti + '-' + i : i
          let propItem = item['items'][i]
          let siconLeft = null
          let siconRight = null
          if (typeof propItem.icon == 'string') {
            if (iconPlacement == 'right') {
              siconRight = <span className="icon-span-right"><i className={MC.buildIconClass(propItem.icon)}/></span>
            } else {
              siconLeft = <span className="icon-span-left"><i className={MC.buildIconClass(propItem.icon)}/></span>
            }
          }
          let submenu = this.buildSubmenu(propItem, strI)
          let active = propItem.key && activeKey && propItem.key == activeKey
          let cls = MC.classes('mnc', {'active': active, 'visible': submenu && (asDropdown || !vertical), 'opened': submenu && !asDropdown, 'dropdown': submenu && (asDropdown || !vertical), 'disabled': propItem.enabled == false}, propItem.cssclass, 'item curpointer')
          let tooltip = propItem.tooltip ? propItem.tooltip : null
          let href = null
          if (!MC.isNull(propItem.url) && propItem.url !== '' || propItem.url === '' && behavior == 'url') {
            href = propItem.url
          }
          let ItemTag = submenu ? "div" : "a"
          let title = <React.Fragment>{siconLeft}{propItem.title || '\u00A0'}{siconRight}</React.Fragment>
          if (submenu && href) {
            title = <a href={href} className={MC.classes({'active': active})}>{title}</a>
            href = null
          }
          items.push(
            <ItemTag className={cls} style={MC.styleObjectFromString(propItem.css)} href={href} onClick={this.onclick} data-i={strI} key={'item' + strI} data-tt-content={tooltip} onMouseEnter={this.handleRootMouseEnter} onMouseLeave={this.handleRootMouseLeave}>
              {title}
              {submenu}
            </ItemTag>)
        }
        let mcls = 'menu transition visible'
        let ref = null
        if (MC.isNull(rooti)) {
          ref = this.menuRef
          mcls = MC.classes('mnc menu', MC.getFieldParamBooleanValue(params, '@fitWidth') ? 'fluid' : 'compact', {'vertical': MC.getFieldParamBooleanValue(params, '@vertical')}, MC.getFieldParamValue(params, '@cssClass'))
        }
        submenu = <div className={mcls} style={MC.styleObjectFromString(MC.getFieldParamValue(params, '@css'))} ref={ref}>{items}</div>
      }
    }
    return submenu
  }

  render() {
    return this.buildSubmenu(this.props.data.param, null)
  }
}

export default Menu
