import {FieldDef} from "./FieldDef.js";
import {WidgetModel} from "./WidgetModel.js";

const Clipboard = {
    pasteFieldsFromClipboard: function(modeler) {
      let storageValue = localStorage.getItem('formModelerClipboard')
      if (storageValue) {
        let clipboard = JSON.parse(storageValue)
        clipboard.forEach(function(field) {
          FieldDef.setProto(field)
          field.setFlow(modeler.state.form.flow)
          field.setParents()
          field.generateRbsId()
        })
        if (clipboard.length != 0) {
          modeler.store(modeler.state.form)
        }
        let target = modeler.state.selectedFieldIds.length == 1 ? modeler.selectedFields()[0] : modeler.state.form
        let maxIndexes = {}
        target.fields.forEach(field => {
          field.grid.forEach(grid => {
            let index = parseInt(grid.index)
            if (maxIndexes[grid.resolution] === undefined || index > maxIndexes[grid.resolution]) {
              maxIndexes[grid.resolution] = index
            }
          })
        })
        clipboard.forEach(field => {
          if (WidgetModel.isSupportedChild(field.widget, target.widget)) {
            Clipboard.checkFieldIdUniquness(modeler.state.form, field)
            field.grid.forEach(grid => {
              maxIndexes[grid.resolution] = maxIndexes[grid.resolution] === undefined ? 0 : maxIndexes[grid.resolution] + 1
              grid.index = maxIndexes[grid.resolution].toString() || "0"
            })
            target.setSubfields(target.getSubfields().concat([field]))
            if (target.isTable()) {
              let fieldColumn = field.param && field.param.columns || WidgetModel.getTableColumnDefaults(field.getOption(["param", "@title"]))
              for (let prop in fieldColumn) {
                field.setOption(["param", "columns", prop], fieldColumn[prop])
              }
            } else if (!field.isTable()) {
              field.removeInTableParams()
            }
          }
        })
        modeler.formWasChanged()
      }
    },
    checkFieldIdUniquness: function(form, field) {
      let fields = form.findFields(field.id)
      if (fields.length > 0) {
        let count = 1
        while (form.findField(field.id + count)) {
          count++
        }
        field.setOption(["id"], field.id + count)
      }
      field.getSubfields().forEach(function(subfield) {
        Clipboard.checkFieldIdUniquness(form, subfield)
      })
    },
    copySelectedFields: function(modeler) {
      var clipboard = modeler.selectedFields().map(function(field) {return field.getCopy()})
      clipboard.forEach(function(field) {
        field.unsetParents();
        field.unsetFlow();
        field.deleteAllRbsIds();
      });
      localStorage.setItem('formModelerClipboard', JSON.stringify(clipboard));
    },
    deleteSelectedFields: function(modeler) {
      var stored = false;
      modeler.state.selectedFieldIds.forEach(function(fieldId) {
        var field = modeler.state.form.findFieldByRbsId(fieldId);
        if (field) {
          if (!stored) {
            modeler.store(modeler.state.form);
            stored = true;
          }
          field.removeField();
        }});
      modeler.setState({selectedFieldIds: []});
    }
};

export {Clipboard}