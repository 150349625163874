import React from "react"

import {MC} from './MC.js'

class Upload extends React.Component {

  state = {drag: false, progress: null}
  dropRef = React.createRef()
  inputRef = React.createRef()
  chunkCounter = 0
  totalSize = 0
  progressSize = 0

  componentDidUpdate() {
    let field = this.props.field
    if (MC.getFieldParamBooleanValue(field.param, '@nextPart')) {
      MC.putFieldParamValue(field.param, '@nextPart', false)
      if (!MC.isNull(this.nextStart) && !MC.isNull(this.fileIndex)) {
        this.createChunk(this.nextStart, this.fileIndex, true)
      } else {
        this.setState({progress: 100})
        this.handleBeahaviour()
      }
    }
  }  

  handleFileChange = (e) => {
    let self = this
    const props = this.props
    const field = props.field
    const widget = props.widget
    if (e.target.files && e.target.files.length > 0) {
      field.param.value = []
      this.partSize = MC.getFieldParamValue(field.param, '@partSize')
      if (MC.isNumeric(this.partSize) && this.partSize > 0) {
        this.files = e.target.files
        for (let file of this.files) {
          field.param.value.push({'@fileName': file.name, '@contentType': file.type, '@size': file.size, '@relativePath': file.webkitRelativePath ? file.webkitRelativePath : file.name})
          this.totalSize += file.size
        }
        this.createChunk(0, 0, false)
      } else {
        const files = e.target.files
        this.fileCounter = files.length
        for (let file of files) {
          let newRecord = {'@fileName': file.name, '@contentType': file.type, '@size': file.size, '@relativePath': file.webkitRelativePath ? file.webkitRelativePath : file.name}
          newRecord['@fileName'] = file.name
          newRecord['@contentType'] = file.type
          newRecord['@size'] = file.size
          newRecord['@relativePath'] = file.webkitRelativePath ? file.webkitRelativePath : file.name
          let reader = new FileReader()
          reader.onload = function(e) {
            newRecord['@data'] = e.target.result.substring(e.target.result.indexOf(';base64,')+8)
            field.param.value.push(newRecord)
            self.fileCounter--
            if (self.fileCounter == 0) {
              MC.handleEvent(field, 'change')
              self.handleBeahaviour()
              if (field.flow && field.flow.getCfgParameter('fl:validationStyle') == 'blur') {
                widget.revalidate(true)
              } else {
                widget.revalidate()
              }
            }
          }
          reader.readAsDataURL(file)
        }
      }
    }
  }

  handleUploadClick = () => {
    this.inputRef.current.value = null
    this.inputRef.current.click()
  }

  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  checkList(e) {
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      const props = this.props
      const field = props.field
      let partSize = MC.getFieldParamValue(field.param, '@partSize')
      const multiple = MC.getFieldParamBooleanValue(field.param, '@multiple')
      const directory = MC.getFieldParamBooleanValue(field.param, '@directory')
      if (!multiple && e.dataTransfer.items.length > 1) {
        return false
      }
      if (MC.isNumeric(partSize) && partSize > 0 && Array.isArray(field.param.value) && field.param.value.length > 0) {
        return false
      }
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const entry = e.dataTransfer.items[i].webkitGetAsEntry()
        if (entry) {
          if (entry.isDirectory && !directory) {
            return false
          }
        }
      }
      return true
    }
    return false
  }

  countItem(item, path) {
    self = this
    return new Promise((resolve) => {
      if (item.isFile) {
        self.fitems.push({item: item, path: path})
        resolve()
      } else if (item.isDirectory) {
        let promises = []
        let dirReader = item.createReader()
        let fnReadEntries = (() => {
          return (entries) => {
            for (let entry of entries) {
              promises.push(self.countItem(entry, path + item.name + "/"))
            }
            if (entries.length > 0) {
              dirReader.readEntries(fnReadEntries)
            } else {
              if (promises.length > 0) {
                Promise.all(promises).then(function () {
                  resolve()
                })
              } else {
                resolve()
              }
            }
          }
        })()
        dirReader.readEntries(fnReadEntries)
      }
    })
  }  

  countList(items) {
    return new Promise((resolve) => {
      let promises = []
      if (items && items.length > 0) {
        for (let item of items) {
          const entry = item.webkitGetAsEntry()
          if (entry) {
            promises.push(this.countItem(entry, ""))
          }
        }
      }
      if (promises.length > 0) {
        Promise.all(promises).then(function () {
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (this.checkList(e)) {
      this.setState({drag: true})
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({drag: false})
    }
  }

  handleDrop = (e) => {
    self = this
    e.preventDefault()
    e.stopPropagation()
    e.persist()
    this.setState({drag: false})
    let field = this.props.field
    let items = e.dataTransfer.items
    field.param.value = []
    if (this.checkList(e)) {
      this.fitems = []
      this.countList(items).then(() => {
        this.fileCounter = this.fitems.length
        for (let item of this.fitems) {
          item.item.file((file) => {
            this.partSize = MC.getFieldParamValue(field.param, '@partSize')
            if (MC.isNumeric(this.partSize) && this.partSize > 0) {
              self.fileCounter--
              if (!this.files) {
                this.files = []
              }
              this.files.push(file)
              field.param.value.push({'@fileName': file.name, '@contentType': file.type, '@size': file.size, '@relativePath': item.path ? item.path + file.name : file.name})
              this.totalSize += file.size
              if (self.fileCounter == 0) {
                this.createChunk(0, 0, false)
              }
            } else {
              let newRecord = {'@fileName': file.name, '@contentType': file.type, '@size': file.size, '@relativePath': item.path ? item.path + file.name : file.name}
              const reader = new FileReader()
              reader.onload = function(e) {
                newRecord['@data'] = e.target.result.substring(e.target.result.indexOf(';base64,')+8)
                field.param.value.push(newRecord)
                self.fileCounter-- 
                if (self.fileCounter == 0) {
                  MC.handleEvent(field, 'change')
                  self.handleBeahaviour()
                  if (field.flow && field.flow.getCfgParameter('fl:validationStyle') == 'blur') {
                    self.props.widget.revalidate(true)
                  } else {
                    self.props.widget.revalidate()
                  }
                  self.fitems = null
                }
              }
              reader.readAsDataURL(file)
            }
          })
        }
        self.dragCounter = 0
        e.dataTransfer.clearData()
      })
    }
  }

  createChunk = (start, fileIndex, progress) => {
    let file = this.files[fileIndex]
    if (start == 0) {
      this.numberOfChunks = Math.ceil(file.size/Number(this.partSize))
    }
    this.chunkCounter++
    let chunkEnd = Math.min(start + Number(this.partSize), file.size)
    let chunk = file.slice(start, chunkEnd)
    let self = this
    let reader = new FileReader()
    reader.readAsDataURL(chunk)
    reader.onloadend = function() {
      self.props.field.param.part = {'@data': reader.result, '@size': chunkEnd-start, '@number': self.chunkCounter, '@totalParts': self.numberOfChunks, '@fileIndex': fileIndex + 1}
      self.progressSize += (chunkEnd-start)
      if (chunkEnd < file.size) {
        self.fileIndex = fileIndex
        self.nextStart = chunkEnd
        if (self.chunkCounter > 1) {
          let totalComplete =  Math.round( (self.progressSize/self.totalSize) * 100 )
          if (totalComplete <= 100) {
            self.setState({progress: totalComplete})
          }
        } else {
          self.forceUpdate()
        }
      } else {
        if (self.files.length > fileIndex+1) {
          self.fileIndex = fileIndex + 1
          self.nextStart = 0
        } else {
          delete self.fileIndex
          delete self.nextStart
          delete self.files
          self.progressSize = 0
          self.totalSize = 0
          if (progress) {
            self.setState({progress: 100})
          }
        }
        self.chunkCounter = 0
        self.numberOfChunks = 0
        if (self.props.field.flow && self.props.field.flow.getCfgParameter('fl:validationStyle') == 'blur') {
          self.props.widget.revalidate(true)
        } else {
          self.props.widget.revalidate()
        }
      }
      MC.handleEvent(self.props.field, 'change')
    }
  }

  handleBeahaviour = () => {
    this.props.field.flow.handleSubmit(this.props.field)
  }

  render() {
    const props = this.props
    const field = props.field
    let innerDisabled = props.disabled
    if (MC.isModelerActive(field)) {
      innerDisabled = true
    }
    const buttonTitle = MC.getFieldParamValue(field.param, '@buttonTitle')
    let placeholder = props.placeholder
    let isSelectedFile = false
    let values = MC.getFieldParamValue(field.param, 'value')
    if (this.state.progress) {
      placeholder = <span className="progress">{this.state.progress}%</span>
    } else if (Array.isArray(values) && values.length > 0) {
      isSelectedFile = true
      if (values.length > 8) {
        placeholder = values.slice(0, 8).map((val) =>  val['@fileName']).join(', ') + ' ... (' +  values.length + ')'
      } else {
        placeholder = values.map((val) =>  val['@fileName']).join(', ')
      }
    }
    if (this.props.textMode) {
      return <React.Fragment>{placeholder}</React.Fragment>
    }
    let partSize = MC.getFieldParamValue(field.param, '@partSize')
    let largeFileUpload = MC.isNumeric(partSize) && partSize > 0
    let multiple = MC.getFieldParamBooleanValue(field.param, '@multiple')
    const accept = MC.getFieldParamValue(field.param, '@accept')
    const directory = MC.getFieldParamBooleanValue(field.param, '@directory') ? 'true' : null
    const showAsDropZone = MC.getFieldParamBooleanValue(field.param, '@showAsDropZone')
    if (largeFileUpload && this.chunkCounter && this.chunkCounter> 0) {
      innerDisabled = true
    }
    let iterationId = MC.asArray(MC.getFieldParamValue(field.param, '@iteration')).join('-')
    if (showAsDropZone) {
      const dropZoneHeight = MC.getFieldParamValue(field.param, '@dropZoneHeight') || '200px'
      let style = {height: dropZoneHeight}
      let dragClass = this.state.drag ? ' drag' : ''
      if (isSelectedFile) {
        dragClass += ' selected'
      }
      return (
        <div ref={this.dropRef} style={style} className={'mnc-file-drop-zone' + dragClass} data-widget-i-name={field.id} onDragEnter={this.handleDragIn} onDragLeave={this.handleDragOut} 
          onDragOver={this.handleDrag} onDrop={this.handleDrop} onClick={this.handleUploadClick}>
          <div className={'mnc-file-drop-zone-placeholder' + dragClass}>{MC.iconize(field, placeholder)}</div>
          <input key="input" ref={this.inputRef} type="file" onChange={this.handleFileChange} style={{display: 'none'}} multiple={multiple} accept={accept} webkitdirectory={directory} disabled={innerDisabled}/>
        </div>)
    } else {
      return (
        <div>        
          <label htmlFor={field.id+iterationId} key="button" className={MC.classes("upload-button mnc button", {"disabled": innerDisabled || this.props.readOnly})}>{MC.iconize(field, buttonTitle)}</label>
          <input key="input" ref={this.inputRef} data-widget-i-name={field.id} id={field.id+iterationId} type="file" onChange={this.handleFileChange} style={{display: 'none'}} multiple={multiple} accept={accept} webkitdirectory={directory} disabled={innerDisabled}/>
          <span key="span" className="upload-file-name">{placeholder}</span>
        </div>
      )
    }
  }

}

export {Upload}