import React from "react"
import {DateTime} from "luxon"

import {Duration} from "./Duration.js"
import {MCHistory} from "./MCHistory.js"
import {JdateFormat} from "./JdateFormat.js"
import {NumberFormat} from "./NumberFormat.js"
import {MCCache} from "./MCCache.js"
import {ReactFlow} from './ReactFlow.jsx'
import {Expression} from "./Expression.js"
import {Value} from "./Value.js"

if (typeof String.prototype.startsWith != 'function') {
  String.prototype.startsWith = function (str) {
    return this.indexOf(str) == 0;
  };
}

if (typeof String.prototype.endsWith != 'function') {
  String.prototype.endsWith = function(suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
  };
}

export const MC = {
  
  localMessages: {
    en: {
      required: "This field is required.",
      date: "Please enter a valid date.",
      time: "Please enter a valid time.",
      datetime: "Please enter a valid date and time.",
      number: "Please enter a valid number.",
      digits: "Please enter only digits.",
      maxlength: "Please enter no more than {0} characters.",
      minlength: "Please enter at least {0} characters.",
      max: "Please enter a value less than or equal to {0}.",
      min: "Please enter a value greater than or equal to {0}.",
      pattern: "Invalid format.",
      whisper: "Please select valid value.",
      confirm: "Are you sure?",
      cancel: "Cancel",
      beforeleave: "Are you sure? Your changes will be lost!",
      all: "All",
      selectAllRows: "Select all rows",
      selectRow: "Select this row"
    },
    cs: {
      required: "Tento údaj je povinný.",
      date: "Prosím, zadejte platné datum.",
      time: "Prosím, zadejte čas ve správném formátu.",
      datetime: "Prosím, zadejte datum a čas ve správném formátu.",
      number: "Prosím, zadejte číslo.",
      digits: "Prosím, zadávejte pouze číslice.",
      maxlength: "Prosím, zadejte nejvíce {0} znaků.",
      minlength: "Prosím, zadejte nejméně {0} znaků.",
      max: "Prosím, zadejte hodnotu menší nebo rovnu {0}.",
      min: "Prosím, zadejte hodnotu větší nebo rovnu {0}.",
      pattern: "Nesprávný formát.",
      whisper: "Vyberte správnou hodnotu.",
      confirm: "Jste si jisti?",
      cancel: "Storno",
      beforeleave: "Jste si jisti? Vaše změny budou ztraceny!",
      all: "Vše",
      selectAllRows: "Vybrat všechny řádky",
      selectRow: "Vybrat tento řádek"
    },
    sk: {
      required: "Tento údaj je povinný.",
      date: "Prosím, zadajte platný dátum.",
      time: "Prosím, zadajte čas v správnom formáte.",
      datetime: "Prosím, zadajte dátum a čas v správnom formáte.",
      number: "Prosím, zadajte číslo.",
      digits: "Prosím, zadávajte iba číslice.",
      maxlength: "Prosím, zadajte najviac {0} znakov.",
      minlength: "Prosím, zadajte aspoň {0} znakov.",
      max: "Prosím, zadajte hodnotu menšiu alebo rovnú {0}.",
      min: "Prosím, zadajte hodnotu väčšiu alebo rovnú {0}.",
      pattern: "Nesprávny formát.",
      whisper: "Vyberte správnu hodnotu.",
      confirm: "Ste si istí?",
      cancel: "Storno",
      beforeleave: "Ste si istí? Vaše zmeny budú stratené!",
      all: "Všetko",
      selectAllRows: "Vybrať všetky riadky",
      selectRow: "Vybrať tento riadok"
    }
  },
  reactComponents: {},

  registerReactRomponent: function(name, component) {
    this.reactComponents[name] = component;
    if (MC.isFunction(this.onRegisterReactRomponent)) {
      this.onRegisterReactRomponent(name);
    }
  },

  getReactRomponent: function(name) {
    return this.reactComponents[name];
  },

  getReactRomponents: function() {
    return this.reactComponents;
  },

  onRegisterReactRomponent: function(val) {
    if (MC.isFunction(val)) {
      this.onRegisterReactRomponent = val;
    }
  },

  formatMessage: function(source, lang, ...params) {
    let messages = MC.localMessages[lang] ? MC.localMessages[lang] : MC.localMessages.en
    if (!messages[source]) {
      return source
    } else {
      let mess = messages[source]
      if (MC.isNull(params)) {
        return mess
      }
      for (var i=0; i<params.length; i++) {
        mess = mess.replace( new RegExp( "\\{" + i + "\\}", "g" ), params[i])
      }
      return mess
    }
  },

  asArray: function(variable) {
    return [].concat(variable);
  },

  getFirstNotNull: function(value) {
    if (Array.isArray(value)) {
      for (var i=0; i<value.length; i++) {
        if (!MC.isNull(value[i])) {
          return value[i]
        }
      }
      return null
    } else {
      return value
    }
  },

  getJsonType: function() {
    return 'application/json';
  },

  isFunction: function(obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
  },

  error: function(msg) {
    MCHistory.log(MCHistory.T_ERROR, msg, true);
    throw new Error(msg);
  },

  sortFieldsByIndex: function(fields, resolution) {
    var self = this;
    var swapped;
    do {
      swapped = false;
      for (var i=0; i < fields.length-1; i++) {
        if (parseInt(self.getFieldGrid(fields[i], resolution).index) > parseInt(self.getFieldGrid(fields[i+1], resolution).index)) {
          var temp = fields[i];
          fields[i] = fields[i+1];
          fields[i+1] = temp;
          swapped = true;
        }
      }
    } while (swapped);
  },

  getFieldGrid: function(field, resolution) {
    if (typeof field.grid != 'undefined') {
      for (var i = 0; i < field.grid.length; i++) {
        if (field.grid[i].resolution == resolution) {
          return field.grid[i];
        }
      }
    }
    return {
      columns: "6",
      index: "0",
      newLineAfter: "never",
      newLineBefore: "never",
      offset: "0",
      index: "0",
      resolution: resolution,
      visible: "true"
    };
  },

  splitFieldsIntoRows: function(fields, resolution) {
    var rows = [];
    this.sortFieldsByIndex(fields, resolution);
    var row = [];
    for (var i=0; i<fields.length; i++) {
      var field = fields[i];
      var grid = this.getFieldGrid(field, resolution);
      if (grid.newLineBefore && grid.newLineBefore === 'yes') {
        if (row.length > 0) {
          rows.push(row);
        }
        row = [];
      }
      row.push(field);
      if (grid.newLineAfter && grid.newLineAfter === 'yes') {
        rows.push(row);
        row = [];
      }
    }
    if (row.length > 0) {
      rows.push(row);
    }
    return rows;
  },

  getFieldWideClassFromInt: function(size) {
    if (!size || size == 0) {
      return 'twelve';
    }
    size = parseInt(size);
    switch (size) {
      case 1: return 'one'; break;
      case 2: return 'two'; break;
      case 3: return 'three'; break;
      case 4: return 'four'; break;
      case 5: return 'five'; break;
      case 6: return 'six'; break;
      case 7: return 'seven'; break;
      case 8: return 'eight'; break;
      case 9: return 'nine'; break;
      case 10: return 'ten'; break;
      case 11: return 'eleven'; break;
      case 12: return 'twelve'; break;
    }
    return 'twelve';
  },

  xmlStringToObject: function(xml, nsmap, strict, hijackUndeclaredPrefixes = false, ignoreUndefinedNamespaces = false) {
    if (xml.startsWith("<?xml ")) {
      let i = xml.indexOf("?>", 6)
      if (i != -1) {
        xml = xml.substring(i + 2)
      }
    }
    xml = MC.prepareXMLForParsing("<data" + (nsmap ? nsmap.reduce((prev, ns) => prev + ` xmlns:${ns.prefix}="${ns.uri}"`, "") : "") + ">" + xml + "</data>")
    let dom = null
    try {
      dom = (new DOMParser()).parseFromString(xml, "text/xml")
    } catch (e) { 
      dom = null
    }
    if (dom) {
      let obj = MC.xmlToJson(dom, nsmap, strict, hijackUndeclaredPrefixes, ignoreUndefinedNamespaces)
      if (obj.data) {
        return obj.data
      }
    }
    return null
  },
  xmlToJson: function(xml, nsmap, strict, hijackUndeclaredPrefixes, ignoreUndefinedNamespaces) {
    var obj = {};
    if (xml.attributes && xml.attributes.length > 0) {
      for (let attr of xml.attributes) {
        if (attr.name.startsWith('xmlns:')) {
          continue
        }
        let atrName = attr.name
        if (attr.namespaceURI && nsmap) {
          let ns = nsmap.find(i => i.uri == attr.namespaceURI)
          if (ns) {
            atrName = ns.prefix + ':' + attr.localName
          } else if (strict && ignoreUndefinedNamespaces) {
            continue  
          } else if (hijackUndeclaredPrefixes) {
            let i = 1
            nsmap.map(it => i = it.prefix.startsWith('adhocns') ? i + 1 : i)
            nsmap.push({prefix: 'adhocns' + i, uri: attr.namespaceURI})
            atrName = `adhocns${i}:` + attr.localName
          }
        }
        obj['@' + atrName] = attr.value
      }
    }
    if (xml.nodeType == 3) { // text
      obj = xml.nodeValue;
    } else if (xml.hasChildNodes()) {
      for(var i = 0; i < xml.childNodes.length; i++) {
        var item = xml.childNodes.item(i);
        var nodeName = item.nodeName;
        if ('parsererror' == nodeName) {
          let errNS = (new DOMParser()).parseFromString('INVALID', "text/xml").getElementsByTagName("parsererror")[0].namespaceURI
          if (errNS == item.namespaceURI) {
            if (strict) {
              this.error("Error parsing XML string: "  + (item.innerText || item.textContent))
            } else {
              continue
            }
          }
        }
        if (item.namespaceURI && nsmap) {
          let ns = nsmap.find(i => i.uri == item.namespaceURI)
          if (ns) {
            nodeName = ns.prefix + ':' + item.localName
          } else if (strict && ignoreUndefinedNamespaces) {
            continue
          } else if (hijackUndeclaredPrefixes) {
            let i = 1
            nsmap.map(it => i = it.prefix.startsWith('adhocns') ? i + 1 : i)
            nsmap.push({prefix: 'adhocns' + i, uri: item.namespaceURI})
            nodeName = `adhocns${i}:` + item.localName
          }
        }
        if (typeof(obj[nodeName]) == "undefined") {
          var child = this.xmlToJson(item, nsmap, strict, hijackUndeclaredPrefixes, ignoreUndefinedNamespaces)
          if (Object.keys(child).length == 1 && child['#text']) {
            child = child['#text'];
          } else if (child['#text'] && !Array.isArray(child['#text']) && child['#text'].trim() !== '') {
            child['@'] = child['#text']
          }
          if (child['#text']) {
            delete child['#text'];
          }
          obj[nodeName] = child;
        } else {
          if (typeof(obj[nodeName].push) == "undefined") {
            var old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          var child = this.xmlToJson(item, nsmap, strict, hijackUndeclaredPrefixes, ignoreUndefinedNamespaces)
          if (Object.keys(child).length == 1 && child['#text']) {
            child = child['#text'];
          } else if (child['#text'] && !Array.isArray(child['#text']) && child['#text'].trim() !== '') {
            child = {'@': child['#text'], ...child} // move @ to first position for possible casting to scalar
          }
          if (child['#text']) {
            delete child['#text'];
          }
          obj[nodeName].push(child);
        }
      }
    } else if (xml.nodeType == 1) { // empty
      if (MC.isEmptyObject(obj)) { // not has attributes
        obj = ''
      }
    }
    return obj;
  },

  formatValue: function(value, formatter, basictype, pattern, opts) {
    if (formatter === 'message') {
      let msg = pattern
      if (!msg || !opts.param || !MC.isPlainObject(opts.param)) {
        return ''
      }
      let matches = msg.match(/{[^}]*}/g)
      if (Array.isArray(matches) && matches.length > 0) {
        for (let i=0; i<matches.length; i++) {
          let token = matches[i].substring(1, matches[i].length-1)
          let componentsAll = token.split(',')
          let components = componentsAll
          if (componentsAll.length > 3) {
            components = componentsAll.splice(0,2)
            components.push(componentsAll.join(','))
          }
          components = components.map(c => c.trim())
          let sfield = components[0]
          if (token.indexOf(',') > 0) {
            sfield = token.substring(0, token.indexOf(','))
          }
          let mandat = true
          if (sfield.endsWith('?')) {
            mandat = false
            sfield = sfield.substring(0, sfield.length-1)
          }
          let value = MC.getFieldParamValue(opts.param, sfield)
          if (MC.isNull(value)) {
            value = MC.getFieldParamValue(opts.param, '@' + sfield)
          }
          if (!MC.isNull(value) && value !== '') {
            if (components.length > 1) {
              let type = null
              if (opts.msgparam && opts.msgparam[sfield]) {
                type = opts.msgparam[sfield]['basictype']
              }
              value = MC.formatValue(value, components[1], type, components[2], opts)
            }
            msg = msg.replace(matches[i], value)
          } else {
            if (mandat) {
              return ''
            } else {
              msg = msg.replace(matches[i], '')
            }
          }
        }
        return msg
      } else {
        return ''
      }
    } if (formatter === 'date') {
      if (MC.isNull(value) || value === '') {
        return ''
      }
      let lux = MC.dateTimeStringToLuxon(value)
      if (!lux.v.isValid) {
        MC.error('Invalid value "' + value + '" was passed into formatter with formatType date!')
      }
      lux.v.setLocale(opts.lang)
      if (pattern == 'short') {
        return lux.v.toLocaleString(DateTime.DATE_SHORT)
      } else if (pattern == 'medium') {
        return lux.v.toLocaleString(DateTime.DATE_MED)
      } else if (pattern == 'long') {
        return lux.v.toLocaleString(DateTime.DATE_FULL)
      } else if (pattern != null && pattern !== '') {
        return MC.formatDate(value, pattern)
      } else {
        return lux.v.toLocaleString(DateTime.DATE_SHORT)
      }
    } if (formatter === 'time') {
      if (MC.isNull(value) || value === '') {
        return ''
      }
      let lux = MC.dateTimeStringToLuxon(value)
      if (!lux.v.isValid) {
        MC.error('Invalid value "' + value + '" was passed into formatter with formatType time!')
      }
      lux.v.setLocale(opts.lang)
      if (pattern == 'short') {
        return lux.v.toLocaleString(DateTime.TIME_SIMPLE)
      } else if (pattern == 'medium') {
        return lux.v.toLocaleString(DateTime.TIME_WITH_SECONDS)
      } else if (pattern == 'long') {
        return lux.v.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
      } else if (pattern != null && pattern !== '') {
        return MC.formatDate(value, pattern)
      } else {
        return lux.v.toLocaleString(DateTime.TIME_WITH_SECONDS)
      }
    } if (formatter === 'datetime') {
      if (MC.isNull(value) || value === '') {
        return ''
      }
      let lux = MC.dateTimeStringToLuxon(value)
      if (!lux.v.isValid) {
        MC.error('Invalid value "' + value + '" was passed into formatter with formatType datetime!')
      }
      lux.v.setLocale(opts.lang)
      if (pattern == 'short') {
        return lux.v.toLocaleString(DateTime.DATETIME_SHORT)
      } else if (pattern == 'medium') {
        return lux.v.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
      } else if (pattern == 'long') {
        return lux.v.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
      } else if (pattern != null && pattern !== '') {
        return MC.formatDate(value, pattern)
      } else {
        return lux.v.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
      }
    } else if (formatter === 'number') {
      if (MC.isNull(value) || value === '') {
        return ''
      }
      let formatter = new NumberFormat()
      if (pattern != null && pattern !== '') {
        return formatter.formatNumber(value, {format: pattern, locale: opts.lang})
      } else {
        if (basictype == 'integer') {
          return formatter.formatNumber(value, {format: '#,##0', locale: opts.lang})
        } else {
          return formatter.formatNumber(value, {format: '#,##0.##', locale: opts.lang})
        }
      }
    } else if (formatter === 'currency') {
      if (MC.isNull(value) || value === '') {
        return ''
      }
      let formatter = new NumberFormat()
      return formatter.formatNumber(value, {format: '#,##0.00', locale: opts.lang})
    } else if (formatter === 'percent') {
      if (MC.isNull(value) || value === '') {
        return ''
      }
      return value + "%"
    } else {
      MC.error(`Unknown formatter "${formatter}", formatted value "${value}"!`)
    }
  },

  formatDate: function(value, formatString) {
    let lux = MC.dateTimeStringToLuxon(value)
    if (MC.hasTimezone(value)) {
      if (lux.v.offset === 0 && formatString.indexOf('XXX') > -1) {
        formatString = formatString.replace("XXX", "'Z'")
      }
    } else {
      formatString = formatString.replace('XXX', '')
    }
    if (formatString === '') {
      return '';
    }
    return MC.dateTimeStringToLuxon(value).v.toFormat(JdateFormat.toLuxonFormatString(formatString))
  },

  fixSparseColls: function(object) {
    if (!object) {
      return Value.v(null)
    }
    if (Value.isDataNode(object)) {
      for (let key in object.value) {
        if (!Value.isNull(object.value[key]) && !Value.isEmpty(object.value[key])) {
          if (Value.isDataNode(object.value[key]) || Value.isCollection(object.value[key])) {
            object.value[key] = MC.fixSparseColls(object.value[key])
          } 
        }
      }
    } else if (Value.isCollection(object)) {
      let newColl = []
      for (let v of object.value) {
        let val = MC.fixSparseColls(v)
        if (!Value.isNull(val)) {
          newColl.push(val)
        }
      }
      object.value = newColl
    } 
    return object
  },

  nullsToEmpty: function(object) {
    if (MC.isPlainObject(object)) {
      var newObject = {};
      for (var key in object) {
        if (hasOwnProperty.call(object, key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            newObject[key] = MC.nullsToEmpty(object[key]);
          } else {
            if (!MC.isNull(object[key])) {
              newObject[key] = object[key];
            } else {
              newObject[key] = '';
            }
          }
        }
      }
      return newObject;
    } else if (Array.isArray(object)) {
      var newObject = [];
      for (var i=0; i<object.length; i++) {
        var val = MC.nullsToEmpty(object[i]);
        if (!MC.isNull(val)) {
          newObject.push(val);
        } else {
          newObject.push('');
        }
      }
      return newObject;
    } else {
      return object;
    }
  },

  emptysToNull: function(object) {
    if (MC.isPlainObject(object)) {
      var newObject = {};
      for (var key in object) {
        if (hasOwnProperty.call(object, key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            newObject[key] = MC.emptysToNull(object[key]);
          } else {
            if (object[key] === '') {
              newObject[key] = null;
            } else {
              newObject[key] = object[key];
            }
          }
        }
      }
      return newObject;
    } else if (Array.isArray(object)) {
      var newObject = [];
      for (var i=0; i<object.length; i++) {
        var val = MC.emptysToNull(object[i]);
        if (val === '') {
          newObject.push(null);
        } else {
          newObject.push(val);
        }
      }
      return newObject;
    } else {
      return object;
    }
  },

  replaceInKeys: function(object, what, to) {
    if (MC.isPlainObject(object)) {
      for (var key in object) {
        if (hasOwnProperty.call(object, key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            MC.replaceInKeys(object[key], what, to);
          }
          if (key.indexOf(what) > -1) {
            object[key.replace(what, to)] = object[key];
            delete object[key];
          }
        }
      }
    } else if (Array.isArray(object)) {
      for (var i=0; i<object.length; i++) {
        MC.replaceInKeys(object[i], what, to);
      }
    }
  },

  replaceValues: function(object, what, to) {
    if (MC.isPlainObject(object)) {
      for (let key in object) {
        if (object.hasOwnProperty(key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            MC.replaceValues(object[key], what, to)
          } else if (object[key] === what) {
            object[key] = to
          }
        }
      }
    } else if (Array.isArray(object)) {
      for (let i=0; i<object.length; i++) {
        if (MC.isPlainObject(object[i]) || Array.isArray(object[i])) {
          MC.replaceValues(object[i], what, to)
        } else if (object[i] === what) {
          object[i] = to
        }
      }  
    }
  },

  getRowsCount: function(field) {
    if (field.rows && Array.isArray(field.rows)) {
      return field.rows.length
    } else {
      return 0
    }
  },

  customHtml: function(html) {
    if (html && typeof html == 'string') {
      html = html.replaceAll('{', '&#123;')
      html = html.replaceAll('}', '&#125;')
      html = html.replace(/\\n/g, '<br />')
    }
    return html
  },

  getFieldParamValue: function(field, name) {
    if (MC.isNull(field)) {
      return null
    }
    if (name.indexOf('/') > -1) {
      const subname = name.substring(name.indexOf('/') + 1)
      name = name.substring(0, name.indexOf('/'))
      return MC.getFieldParamValue(field[name], subname)
    } else {
      let val = field[name]
      if (!MC.isNull(val)) {
        return val
      }
    }
    return null
  },

  getFieldParamBooleanValue: function(field, name) {
    let val = MC.getFieldParamValue(field, name)
    if (val != null) {
      if (val === true) {
        return true
      }
      if (val === false) {
        return false
      }
    }
    return null
  },

  putFieldParamValue: function(field, name, val) {
    if (name.indexOf('/') > -1) {
      let subname = name.substring(name.indexOf('/') + 1)
      name = name.substring(0, name.indexOf('/'))
      if (!MC.isPlainObject(field[name])) {
        field[name] = {}
      }
      return MC.putFieldParamValue(field[name], subname, val)
    } else {
      field[name] = val
    }
  },

  isNull: function(value) {
    if (value == undefined || value == null) {
      return true;
    } else if (Array.isArray(value)) {
      if (value.length == 0) {
        return true;
      } else {
        for (var i=0; i<value.length; i++) {
          if (!MC.isNull(value[i])) {
            return false;
          }
        }
        return true;
      }
    } else if (MC.isPlainObject(value)) {
      return MC.isEmptyObject(value);
    } else {
      return false;
    }
  },

  isPureNull:  function(value) {
    return (value == undefined || value == null)
  },

  isNullOrEmpty: function(value) {
    return MC.isNull(value) || value === '';
  },

  isEmptyObject: function(object) {
    for(var property in object) {
      if(object.hasOwnProperty(property)) {
        return false;
      }
    }
    return true;
  },

  isPlainObject: function(obj) {
    return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
   /* var proto, Ctor;
		// Detect obvious negatives
		// Use toString instead of jQuery.type to catch host objects
		if (!obj || ({}).toString.call(obj) !== "[object Object]") {
			return false;
		}
		proto = Object.getPrototypeOf(obj);
		// Objects with no prototype (e.g., `Object.create( null )`) are plain
		if (!proto) {
			return true;
		}
		// Objects with prototype are plain iff they were constructed by a global Object function
		Ctor = Object.hasOwnProperty.call(proto, "constructor") && proto.constructor;
		return typeof Ctor === "function" && Object.hasOwnProperty.toString.call(Ctor) === Object.hasOwnProperty.toString.call(Object);*/
  },

  isNumeric: function( obj ) {
    // From jQuery
    // parseFloat NaNs numeric-cast false positives (null|true|false|"")
    // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
    // subtraction forces infinities to NaN
    // adding 1 corrects loss of precision from parseFloat (#15100)
    var realStringObj = obj && obj.toString();
    return !Array.isArray( obj ) && ( realStringObj - parseFloat( realStringObj ) + 1 ) >= 0;
  },

  objectToXML: function(obj, depth) {
    if (!MC.isPlainObject(obj)) {
      return ""+obj
    }
    let xml = []
    for (let key in obj) {
      if (!obj.hasOwnProperty(key) || key.startsWith('@') && key !== '@') {
        continue
      }
      let value = obj[key]
      let attrs = ''
      if (typeof (value) == 'object') {
        for (let atrKey in value) {
          if (atrKey.startsWith('@') && atrKey !== '@') {
            let atrValue = value[atrKey]
            atrKey = atrKey.substring(1)
            attrs += ` ${atrKey}="${MC.escapeXML(atrValue)}"`
          }
        }
      }
      if (key === '@') {
        xml.push(MC.escapeXML(value))
      } else if (Array.isArray(value)) {
        for (let ia=0; ia<value.length; ia++) {
          var tmp = {}
          tmp[key] = value[ia]
          xml.push(MC.objectToXML(tmp, depth))
        }
      } else if (value === null || value === undefined || value === '' || (MC.isPlainObject(value) && MC.isEmptyObject(value))) {
        for (let x = 0; x < depth; x++) {
          xml.push('  ')
        }
        xml.push('<' + key + attrs + '/>\n')
      } else {
        for (let x = 0; x < depth; x++) {
          xml.push('  ')
        }
        xml.push('<' + key + attrs + '>')
        if (typeof (value) == 'object') {
          if (value['@']) {
            xml.push(MC.objectToXML(value, depth + 1))
          } else {
            xml.push('\n')
            xml.push(MC.objectToXML(value, depth + 1))
            for (let x = 0; x < depth; x++) {
              xml.push('  ')
            }
          }
        } else {
          xml.push(MC.escapeXML(value))
        }
        xml.push('</' + key + '>\n')
      }
    }
    return xml.join('')
  },

  stripWhiteSpaceInXML: function(str) {
    str = str.replace(/>\s*/g, '>');
    str = str.replace(/\s*</g, '<');
    return str;
  },

  escapeXML: function(string) {
    return (string+'').replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&apos;");
  },

  getConfiguration: function(ri, debug, self, baseUrl, lang) {
    return new Promise(function (resolve, reject) {
      let url = baseUrl + ReactFlow.flowServerUrl + 'miniclientinit/' + ri
      if (debug) {
        if (debug == 'AUTO' || debug == true || debug == 'true') {
          debug = ''
        }
        url += '?includelog=true&loggingthreshold=' + debug
      }
      MC.callServer('GET', url).then(function (res) {
        var output = {};
        var content = res.content;
        let message;
        if (content) {
          try {
            output = JSON.parse(content);
          } catch (e) {
            message = content
          }
        }
        if (res.status == 200 || res.status == 204) {
          var conf = {};
          if (output.configData && Array.isArray(output.configData)) {
            for (var i=0; i < output.configData.length; i++) {
              if (output.configData[i].key && output.configData[i].value) {
                conf[output.configData[i].key] = output.configData[i].value;
                if (Array.isArray(conf[output.configData[i].key]) && conf[output.configData[i].key].length == 1) {
                  conf[output.configData[i].key] = conf[output.configData[i].key][0]
                }
              }
            }
          }
          if (output.environmentOperation) {
            conf['fl:environmentOperation'] = output.environmentOperation;
          }
          if (output.operationDefinition) {
            const flowTemplate = baseUrl + ReactFlow.flowTemplate
            for (let def of output.operationDefinition) {
              MCCache.put(flowTemplate.replace('{configuration}', ri).replace('{flowName}', def.id).replace('{lang}', lang), def)
            }
          }
          resolve(conf)
        } else {
          if (!message) {
            message = ''
            if (output.errorName) {
              message += output.errorName + ': ';
            }
            message += 'Loading application configuration failed! Status:' + res.status;
            if (output.errorMessage) {
              message += ' ' + output.errorMessage;
            }
          }
          if (self) {
              self.endOperationException('SYS_IntegrationExc', 'Reading configuration failed for url ' + url + ': ' + message);
          } else {
            MC.error('Reading configuration failed for url ' + url + ': ' + message)
          }
          reject(null);
        }
      }).catch(function (err) {
        if (self) {
          if (navigator.onLine) {
            self.endOperationException('SYS_IntegrationExc', 'Reading configuration failed for url ' + url + ': ' + err.message);
            return;
          } else {
            self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available for url ' + url + ': ' + err.message);
            return;
          }
        } else {
          MC.error('Reading configuration failed for url ' + url + ': ' + err.message + ' - ' + res.content)
        }
      });
    });
  },

  getEnvironmentContext: function(configuration, envOperName, baseUrl, lang) {
    return new Promise(function (resolve, reject) {
      if (!MC.isNull(envOperName) && envOperName !== '') {
        if (envOperName.indexOf('/') > -1) {
          envOperName = envOperName.substring(envOperName.lastIndexOf('/') + 1);
        }
        let inputRequest = {};
        inputRequest.language = lang
        var url = baseUrl + ReactFlow.flowServerUrl + configuration + '/' + envOperName + (MC.isNull(inputRequest) ? '' : '?inputdata=' + encodeURIComponent(JSON.stringify(inputRequest)))
        MC.callServer('GET', url, MC.getJsonType()).then(function (res) {
          if (res.status == 200 || res.status == 204) {
            let output = ""
            if (res.content) {
              output = JSON.parse(res.content)
            }
            resolve(output)
          } else {
            reject('Calling server flow failed for url ' + envOperName  + '! Status:' + res.status + ', Output: ' + res.content)
          }
        }).catch(function (err) {
          reject('Calling server flow failed for url ' + envOperName  + '! Error: ' + err.message)
        });
      } else {
        resolve(null)
      }
    })
  },

  toUTF8Array: function(str) {
    var utf8 = [];
    for (var i=0; i < str.length; i++) {
      var charcode = str.charCodeAt(i);
      if (charcode < 0x80) utf8.push(charcode);
      else if (charcode < 0x800) {
        utf8.push(0xc0 | (charcode >> 6),
          0x80 | (charcode & 0x3f));
      }
      else if (charcode < 0xd800 || charcode >= 0xe000) {
        utf8.push(0xe0 | (charcode >> 12),
          0x80 | ((charcode>>6) & 0x3f),
          0x80 | (charcode & 0x3f));
      }
      else {
        // let's keep things simple and only handle chars up to U+FFFF...
        utf8.push(0xef, 0xbf, 0xbd); // U+FFFE "replacement character"
      }
    }
    return utf8;
  },

  fromUTF8Array: function(arr) {
    var str = '';
    for (var i = 0; i < arr.length; i++) {
      str += '%' + ('0' + arr[i].toString(16)).slice(-2);
    }
    return decodeURIComponent(str);
  },

  luxonToDateTimeString: function(l, type, putZone) {
    if (MC.isNull(type)) {
      type = MC.getDateTimeType(l)
    }
    const hasTimezone = MC.objectHasTimezone(l) || putZone
    let milliseconds = l.v.millisecond != 0 ? l.v.millisecond.toString() : ''
    if (milliseconds && milliseconds.length < 3) {
      milliseconds = '0' + milliseconds
    }
    if (milliseconds && milliseconds.length < 3) {
      milliseconds = '0' + milliseconds
    }
    if (milliseconds.endsWith('0')) {
      milliseconds = milliseconds.substring(0, milliseconds.length-1)
    }
    if (milliseconds.endsWith('0')) {
      milliseconds = milliseconds.substring(0, milliseconds.length-1)
    }
    if (milliseconds) {
      milliseconds = '.' + milliseconds
    }
    const tmz = l.v.offset === 0 ? "'Z'" : 'ZZ'
    const year = Intl.NumberFormat('en', {useGrouping: false, minimumIntegerDigits: 4}).format(l.v.year) // if year is negative
    switch (type) {
      case 'date': return hasTimezone ? year + l.v.toFormat("-MM-dd" + tmz) : year + l.v.toFormat("-MM-dd")
      case 'time': return hasTimezone ? l.v.toFormat("HH:mm:ss" + milliseconds + tmz) : l.v.toFormat("HH:mm:ss" + milliseconds)
      default: return hasTimezone ? year + l.v.toFormat("-MM-dd'T'HH:mm:ss" + milliseconds + tmz) : year + l.v.toFormat("-MM-dd'T'HH:mm:ss" + milliseconds)
    }
  },

  dateTimeStringToLuxon: function(s, onlyTimeWithZones) {
    if (MC.isNull(s)) {
      return {v: DateTime.local(), _i: DateTime.local().toISO({includeOffset: false})}
    }
    let isBc = false
    if (s.startsWith('-')) {
      isBc = true
      s = s.substring(1)
    }
    let options = {}
    if (s.match(/^\d{4}-\d\d-\d\d([+-]\d\d:\d\d)$/i)) {
      options.setZone = true
      if (isBc) {
        return {v: DateTime.fromFormat('BC ' + s, 'G yyyy-MM-ddZZ', options), _i: s}
      } else {
        return {v: DateTime.fromFormat(s, 'yyyy-MM-ddZZ', options), _i: s}
      }      
    } else if (s.match(/^\d{4}-\d\d-\d\dZ$/i)) {
      options.zone = 'utc'
      if (isBc) {
        return {v: DateTime.fromFormat('BC ' + s.substring(0, s.length-1), 'G yyyy-MM-dd', options), _i: s}
      } else {
        return {v: DateTime.fromFormat(s.substring(0, s.length-1), 'yyyy-MM-dd', options), _i: s}
      }
    } else {
      options.setZone = true
      if (MC.getDateTimeType({_i: s}) == 'time') {
        return {v: DateTime.fromISO((onlyTimeWithZones ? '2' : '0') + '000-01-01T' + s, options), _i: s}
      } else {
        s = isBc ? '-00' + s : s
        return {v: DateTime.fromISO(s, options), _i: s}
      }
    }
  },

  getDateTimeType: function(l) {
    if (!l._i) {
      return 'dateTime'
    }
    if (l._i.match(/^\d{4}-\d\d-\d\d((([+-]\d\d:\d\d)|Z)?)?$/i)) {
      return 'date'
    } else if (l._i.match(/^\d\d:\d\d(:\d\d?(\.\d+(([+-]\d\d:\d\d)|Z)?)?)?/i)) {
      return 'time'
    } else {
      return 'dateTime'
    }
  },

  isValidDateStringByType: function(value, type) {
    if (MC.isNull(value) || value === '') {
      return true
    }
    if (typeof value !== 'string') {
      return false
    }
    if (type === 'date') {
      return value.match(/^\d{4}-\d\d-\d\d((([+-]\d\d:\d\d)|Z)?)?$/i)
    } else if (type === 'time') {
      return value.match(/^\d\d:\d\d(:\d\d?(\.\d+(([+-]\d\d:\d\d)|Z)?)?)?/i)
    } else {
      return value.match(/^\d{4}-\d\d-\d\d((T|\$\$\$)?\d\d:\d\d(:\d\d)?(\.\d+)?)?(([+-]\d\d:\d\d)|Z)?$/i)
    }
  },

  hasTimezone: function(s) {
    if (MC.isNull(s)) {
      return false;
    }
    if (s.match(/^(\d{4}(-\d\d(-\d\d)?)?)?((T|\$\$\$)?\d\d:\d\d(:\d\d)?(\.\d+)?)?(([+-]\d\d:\d\d)|Z)$/i)) {
      return true;
    } else {
      return false;
    }
  },

  objectHasTimezone: function(m) {
    if (m._i && typeof(m._i) === 'string') {
      return MC.hasTimezone(m._i);
    }
    return false;
  },

  luxonAdd: function (lux, duration) {
    if (!lux.v.isValid || !duration.isValidDuration()) {
      return
    }
    if (MC.isDurationObject(duration)) {
      lux.v = lux.v.plus({years: duration.getYears()})
      lux.v = lux.v.plus({months: duration.getMonths()})
      lux.v = lux.v.plus({days: duration.getDays()})
      lux.v = lux.v.plus({hours: duration.getHours()})
      lux.v = lux.v.plus({minutes: duration.getMinutes()})
      lux.v = lux.v.plus({seconds: duration.getSeconds()})
      lux.v = lux.v.plus({milliseconds: duration.getMilliseconds()})
    }
  },

  isDurationObject: function(dur) {
    if (typeof(dur) == 'object' && MC.isFunction(dur.isValidDuration)) {
      return true;
    }
    return false;
  },

  durationBetween: function(date1, date2) {
    let diff = date2.v.diff(date1.v, ['years','months', 'days','hours','minutes','seconds','milliseconds']).toObject()
    let duration = new Duration()
    duration.from(diff.years, diff.months, diff.days, diff.hours, diff.minutes, diff.seconds, diff.milliseconds)
    return duration
  },

  isRowVisible: function(fields, resolution) {
    if (MC.isNull(fields) || fields.length == 0) {
      return false
    }
    if(fields[0].flow.modelerReact && fields[0].flow.modelerReact.state.ghostMode) {
      return true
    }
    for (let i = 0; i < fields.length; i++) {
      let grid = MC.getFieldGrid(fields[i], resolution)
      let visibleInParam = MC.getFieldParamBooleanValue(fields[i].param, '@visible')
      if (grid.visible !== 'false' && grid.visible !== false && visibleInParam !== false) {
        return true
      }
    }
    return false
  },

  rebaseUrl: function(comName, url, baseUrl) {
    if (MC.isNull(url) || typeof(url) != 'string' || url.startsWith('http')) {
      return url
    } else if (url.startsWith('/staticapp/')) {  
      return baseUrl + 'miniapp' + url
    } else {  
      return baseUrl + 'miniapp/static/' + encodeURIComponent(comName) + '/' + url
    }
  },

  getResolutionFromWidth: function(width, mconf) {
    let breakpoints = [576, 768, 992]
    if (mconf.responsiveBreakpoints && Array.isArray(mconf.responsiveBreakpoints) && mconf.responsiveBreakpoints.length > 2 && !mconf.responsiveBreakpoints.some(isNaN)) {
      breakpoints = mconf.responsiveBreakpoints
    }
    if (width < breakpoints[0]) {
      return 'x-small'
    } else if (width < breakpoints[1]) {
      return 'small'
    } else if (width < breakpoints[2]) {
      return 'medium'
    } else {
      return 'large'
    }
  },

  hasLayout: function(form, resolution) {
    return form.fields.some(function(field) {
      if (field.grid && Array.isArray(field.grid)) {
        for (var i = 0; i < field.grid.length; i++) {
          if (field.grid[i].resolution === resolution) {
            return true;
          }
        }
      }
    });
  },

  getAvailableResolution: function(resolution, form) {
    if (MC.hasLayout(form, resolution)) {
      return resolution;
    }
    if (resolution === 'large') {
      if (MC.hasLayout(form, 'medium')) {
        return 'medium';
      } else if (MC.hasLayout(form, 'small')) {
        return 'small';
      } else if (MC.hasLayout(form, 'x-small')) {
        return 'x-small';
      }
    } else if (resolution === 'medium') {
      if (MC.hasLayout(form, 'large')) {
        return 'large';
      } else if (MC.hasLayout(form, 'small')) {
        return 'small';
      } else if (MC.hasLayout(form, 'x-small')) {
        return 'x-small';
      }
    } else if (resolution === 'small') {
      if (MC.hasLayout(form, 'x-small')) {
        return 'x-small';
      } else if (MC.hasLayout(form, 'medium')) {
        return 'medium';
      } else if (MC.hasLayout(form, 'large')) {
        return 'large';
      }
    } else if (resolution === 'x-small') {
      if (MC.hasLayout(form, 'small')) {
        return 'small';
      } else if (MC.hasLayout(form, 'medium')) {
        return 'medium';
      } else if (MC.hasLayout(form, 'large')) {
        return  'large';
      }
    }
    return null;
  },

  commonAncestor: function(thisPath, relativePath) {
    if ('.' == relativePath) {
      return thisPath;
    }
    var steps = relativePath.split('/');
    var thisSteps = thisPath.split('/');
    var contextItemStep = thisSteps.shift();
    while (true) {
      var step = steps.shift();
      if (step == '..') {
        if (thisSteps.length > 0) {
          thisSteps.pop();
        }
        continue;
      }
      break;
    }
    thisSteps.unshift(contextItemStep);
    return thisSteps.join('/');
  },

  collectionDepth: function(path) {
    var depth = 0;
    var steps = path.split('/');
    for (var i = 0; i < steps.length; i++) {
      if (steps[i].endsWith('*')) {
        depth++;
      }
    }
    return depth;
  },
  isModelerActive: function(field) {
    return field.flow.isModelerActive || typeof field.flow.modelerReact != "undefined";
  },
  isModelerReactAvailable: function(field) {
    return typeof field.flow.modelerReact != "undefined";
  },
  isModelerInEyeMode: function(field) {
    return MC.isModelerReactAvailable(field) &&  field.flow.modelerReact.state.ghostMode;
  },
  isModelerInStructuralMode: function(field) {
    return MC.isModelerReactAvailable(field) &&  field.flow.modelerReact.state.structuralMode;
  },
  showAtLeastOneIteration: function(field) {
    return MC.isModelerReactAvailable(field)
  },
  getModelerReact: function(field) {
    return field.flow.modelerReact;
  },
  handleEvent: function(field, event, target, jsEvent, options) {
    if (MC.isModelerActive(field)) {
      return
    }
    if (jsEvent) {
      target = {node: jsEvent.target, field: field}
      if (event != 'keydown' || jsEvent.key != 'Escape') {
        jsEvent.stopPropagation()
      }
      if (event == 'click') {
        MC.saveLastMouseClickPosition(jsEvent)
      }
      if (event == 'keydown') {
        field.flow.reactFlow().handleActivity()
      }
      if (!options) {
        options = {}
      }
      options.e = jsEvent
    }
    target = target || field.reactWidget && {node: field.reactWidget.widgetRef.current || field.reactWidget.widgetRootRef.current, field: field} || null
    field.flow.eventForm(field, event, target, null, options)
    if (field.parent) {
      if (field.id == 'rows*' && field.parent.id == 'rows*') {
        MC.handleEvent(field.parent.parent.parent, event, target, null, options)
      } else {
        MC.handleEvent(field.parent, event, target, null, options)
      }
      if (field.parent.formId && event == 'click') {
        let evt = new CustomEvent('MNC.CLICK', {detail: {target: target.node}})
        document.dispatchEvent(evt)
      }
    } else if (event == 'keydown' && field.formId && options.e.key == 'Enter' && options.e.target.tagName == 'INPUT' && options.e.target.type != 'checkbox' && options.e.target.type != 'radio') {
      let defaultActionField = MC.findDefaultAction(field.fields)
      options.e.preventDefault()
      if (defaultActionField) { 
        field.flow.handleSubmit(defaultActionField)
      }
    }
  },
  findDefaultAction(fields) {
    if (!fields) {
      fields = []
    }
    for (var i=0; i<fields.length; i++) {
      if (MC.getFieldParamBooleanValue(fields[i].param, '@defaultAction')) {
        return fields[i]
      } else {
        var res = MC.findDefaultAction(fields[i].fields)
        if (res) {
          return res
        }
      }
    }
    return false
  },
  updateInvalidSummary(field, update) {
    let formData = MC.findRoot(field)
    if (MC.isModelerActive(field) || !MC.getFieldParamBooleanValue(formData.param, '@showInvalidSummary')) {
      return
    }
    let fpath = field.flow.getFormFieldPath(field)
    if (field.param['@invalid']) {
      if (!formData.invalidSummary) {
        formData.invalidSummary = {}
      }
      formData.invalidSummary[fpath] = (field.param['@title'] ? field.param['@title'] + ': ' : "") + (MC.getFieldParamValue(field.param, 'validation/@title') || MC.getFieldParamValue(field.param, '@invalidmessage'))
      if (update) {
        field.flow.reactFlow().forceUpdate()
      }
    } else if (formData.invalidSummary && formData.invalidSummary[fpath]) {
      delete formData.invalidSummary[fpath]
      if (update) {
        field.flow.reactFlow().forceUpdate()
      } 
    }
  },
  validateFieldTree: function(field, triggeredByField, repeatLevel) {
    if (Array.isArray(field.fields) && field.fields.length > 0 && field.widget !== 'radiogroup') {
      let valid = true
      if (!MC.isNull(field.formId) || MC.getFieldParamBooleanValue(field.param, "@enabled") != false && MC.getFieldParamBooleanValue(field.param, "@permitted") != false) {
        if (field.id === 'rows*' && field.rbsid) {
          if (Array.isArray(field.rows) && field.rows.length > 0) {
            let repeaterRows = triggeredByField ? MC.getFieldParamValue(triggeredByField.param, '@iteration') : null
            if (Array.isArray(repeaterRows) && repeaterRows.length > 0 && MC.isCorrespondingRepeater(field, triggeredByField)) {
              if (!MC.validateFieldTree(field.rows[repeaterRows[repeatLevel]], triggeredByField, repeatLevel + 1)) {
                valid = false
              }
            } else {
              for (let row of field.rows) {
                if (!MC.validateFieldTree(row, triggeredByField, repeatLevel)) {
                  valid = false
                }
              }
            }
          }
        } else {
          var tabActiveIndex = 0;
          if (field.widget === "tabpanel") {
            tabActiveIndex = MC.getFieldParamValue(field.param, "@activeIndex")
          }
          for (var i = 0; i < field.fields.length; i++) {
            if (field.widget === "tabpanel" && tabActiveIndex != i) {
              continue
            }
            if (!MC.validateFieldTree(field.fields[i], triggeredByField, repeatLevel)) {
              valid = false
            }
          }
        }
      }
      return valid
    } else {
      let result = MC.validateField(field)
      MC.updateInvalidSummary(field, false)
      return result
    }
  },
  validateField: function(field) {
    let lang = field.flow.reactFlow().props.mconf.lang
    if (['label', 'chart'].indexOf(field.widget) > -1 || MC.getFieldParamBooleanValue(field.param, "@enabled") == false || MC.getFieldParamBooleanValue(field.param, "@permitted") == false
        || field.scriptedWidget && field.scriptedWidget.script || MC.getFieldParamBooleanValue(field.param, "@textmode")) {
      return true
    }
    if (MC.getFieldParamBooleanValue(field.param, "validation/@disableValidation") == true) {
      if (MC.getFieldParamBooleanValue(field.param, "@invalid") == true) {
        return false
      } else {
        return true
      }
    }
    var valid = true;
    var valMsg = null;
    var value = MC.getFieldParamValue(field.param, "value")
    if (field.widget != "combobox" && !MC.getFieldParamBooleanValue(field.param, '@multiple')) {
      value = Value.castToScalar(Value.fromJson(value), 'string').value
    }
    // required
    var required = MC.getFieldParamBooleanValue(field.param, "validation/@required")
    if (required && (MC.isNull(value) || value === "")) {
      valid = false;
      valMsg = MC.formatMessage("required", lang);
    }
    if (required && field.widget == "checkbox" && value !== "true") {
      valid = false;
      valMsg = MC.formatMessage("required", lang);
    }
    if (required && field.widget == "combobox" && MC.getFieldParamBooleanValue(field.param, '@multiple') && Array.isArray(value) && value.length > 0) {
      const hasEmpty = value.some((el) => {
        return MC.isNull(el) || el === ''
      })
      if (hasEmpty) {
        valid = false
        valMsg = MC.formatMessage("required", lang)
      }
    }
    // minlength
    if (valid && !MC.isNull(value) && value !== "") {
      var minlength = MC.getFieldParamValue(field.param, "validation/@minLength");
      if (MC.isNumeric(minlength) && value.length < Number(minlength)) {
        valid = false;
        valMsg = MC.formatMessage("minlength", lang, minlength);
      }
    }
    // maxlength
    if (valid && !MC.isNull(value) && value !== "") {
      var maxlength = MC.getFieldParamValue(field.param, "validation/@maxLength");
      if (MC.isNumeric(maxlength) && value.length > Number(maxlength)) {
        valid = false;
        valMsg = MC.formatMessage("maxlength", lang, maxlength);
      }
    }
    //numbers
    var checkMaxMin = false;
    if ('integer' == field.basictype) {
      checkMaxMin = true;
      if (!MC.isNull(value) && value !== "" && !/^-?\d+$/.test(value)) {
        valid = false;
        valMsg = MC.formatMessage("digits", lang);
      }
    } else if ('decimal' == field.basictype) {
      checkMaxMin = true;
      if (!MC.isNull(value) && value !== "" && !/^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value)) {
        valid = false;
        valMsg = MC.formatMessage("number", lang);
      }
    }
    if (checkMaxMin) {
      var min = MC.getFieldParamValue(field.param, "validation/@minValue");
      if (MC.isNumeric(min) && valid && !MC.isNull(value) && value !== "") {
        if (Number(value) < Number(min)) {
          valid = false;
          valMsg = MC.formatMessage("min", lang, min);
        }
      }
      var max = MC.getFieldParamValue(field.param, 'validation/@maxValue');
      if (MC.isNumeric(max) && valid && !MC.isNull(value) && value !== "") {
        if (Number(value) > Number(max)) {
          valid = false;
          valMsg = MC.formatMessage("max", lang, max);
        }
      }
    }
    // pattern
    var pattern = MC.getFieldParamValue(field.param, "validation/@pattern");
    if (!MC.isNull(pattern) && valid && !MC.isNull(value) && value !== "") {
      pattern  = new RegExp( "^(?:" + pattern  + ")$" );
      if (!pattern.test(value)) {
        valid = false;
        valMsg = MC.formatMessage("pattern", lang);
      }
    }
    if ((field.widget == "datebox" || ['date', 'time', 'dateTime'].indexOf(field.basictype) > -1) && valid && !MC.isNull(value) && value !== "") {
      let minVal = MC.getFieldParamValue(field.param, "validation/@minValue")
      let maxVal = MC.getFieldParamValue(field.param, 'validation/@maxValue')
      if (field.basictype == 'time') {
        if (!/^\d\d:\d\d(:\d\d?(\.\d+(([+-]\d\d:\d\d)|Z)?)?)?/i.test(value)) {
          valid = false
          valMsg = MC.formatMessage("time", lang)
        }
        let val = MC.dateTimeStringToLuxon(value).v
        if (valid && minVal) {
          let minValLux = MC.dateTimeStringToLuxon(minVal).v
          if (minValLux.isValid) {
            if (val < minValLux) {
              valid = false;
              valMsg = MC.formatMessage("time", lang)
            }
          } else {
            MC.error(`Unsupported time minimum format "${minVal}".`)
          }
        }
        if (valid && maxVal) {
          let maxValLux = MC.dateTimeStringToLuxon(maxVal).v
          if (maxValLux.isValid) {
            if (val > maxValLux) {
              valid = false;
              valMsg = MC.formatMessage("time", lang)
            }
          } else {
            MC.error(`Unsupported time maximum format "${maxVal}".`)
          }
        }
      } else if (field.basictype == 'dateTime') {
        let val = MC.dateTimeStringToLuxon(value).v
        if (!val.isValid) {
          valid = false
          valMsg = MC.formatMessage("datetime", lang)
        }
        if (valid && minVal) {
          let minValLux = MC.dateTimeStringToLuxon(minVal).v
          if (minValLux.isValid) {
            if (val < minValLux) {
              valid = false
              valMsg = MC.formatMessage("datetime", lang)
            }
          } else {
            MC.error(`Unsupported date time minimum "${minVal}".`)
          }
        }
        if (valid && maxVal) {
          let maxValLux = MC.dateTimeStringToLuxon(maxVal).v
          if (maxValLux.isValid) {
            if (val > maxValLux) {
              valid = false
              valMsg = MC.formatMessage("datetime", lang)
            }
          } else {
            MC.error(`Unsupported date time maximum format "${maxVal}".`)
          }
        }  
      } else {
        if (!/^\d{4}-\d\d-\d\d(([+-]\d\d:\d\d)|Z)?$/i.test(value)) {
          valid = false
          valMsg = MC.formatMessage("date", lang)
        }
        let val = MC.dateTimeStringToLuxon(value).v
        if (!MC.isValidDay(val, minVal, maxVal)) {
          valid = false
          valMsg = MC.formatMessage("date", lang)
        }
      }
    }
    if (field.widget == "whisperbox") {
      if (MC.getFieldParamBooleanValue(field.param, '@forceValue') && Array.isArray(field.param['items']) && field.param['items'].length > 0) {
        let ok = true
        for (let item of field.param['items']) {
          if (!MC.isNull(item['@key'])) {
            ok = false
            if (item['@key'] == value) {
              ok = true
              break
            }
          }
        }
        if (!ok) {
          valid = false
          valMsg = MC.formatMessage("whisper", lang)
        }
      }
    }
    if (field.widget == "slider" && valid) {
      if (MC.isNumeric(value)) {
        let min = new Number(MC.getFieldParamValue(field.param, '@min')).valueOf()
        if (MC.isNumeric(min) && value < min) {
          valid = false
          valMsg = MC.formatMessage("min", lang, min)
        }
        let max = new Number(MC.getFieldParamValue(field.param, '@max')).valueOf()
        if (MC.isNumeric(max) && value > max) {
          valid = false
          valMsg = MC.formatMessage("max", lang, max)
        }
      } else {
        valid = false
        valMsg = MC.formatMessage("number", lang)
      }
    }
    let invalidState = MC.getFieldParamValue(field.param, '@invalidState')
    MC.putFieldParamValue(field.param, "@invalid", !valid)
    if (invalidState != 'validChecked') {
      if (valid) {
        MC.putFieldParamValue(field.param, "@invalidState", 'valid')
      } else {
        MC.putFieldParamValue(field.param, "@invalidState", 'error')
      }
    }
    MC.putFieldParamValue(field.param, "@invalidmessage", valMsg)
    let isBlurStyle = field.flow && field.flow.getCfgParameter('fl:validationStyle') == 'blur'
    if (!isBlurStyle && !valid && !field.flow.focusedOnFirst) {
      field.flow.focusedOnFirst = true
      MC.putFieldParamValue(field.param, "@focused", true)
    }
    if (valid && invalidState != 'validChecked') {
      field.flow.eventForm(field, 'validate', null, null)
    }
    return valid
  },
  isValidDay(luxonDate, min, max) {
    if (min || max) {
      if (min) {
        let minLuxon = MC.dateTimeStringToLuxon(min).v.startOf('day')
        if (minLuxon.isValid) {
          if (luxonDate < minLuxon) {
            return false
          }
        } else {
          MC.error(`Unsupported minimal date format "${min}".`)
          return false
        }
      }
      if (max) {
        let maxLuxon = MC.dateTimeStringToLuxon(max).v.startOf('day')
        if (maxLuxon.isValid) {
          if (luxonDate > maxLuxon) {
            return false
          }
        } else {
          MC.error(`Unsupported maximal date format "${max}".`)
          return false
        }
      }  
    }
    return true
  },
  findAncestor: function (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls)) ;
    return el;
  },
  findAncestorEl: function (el, tag) {
    while ((el = el.parentElement) && el.nodeName != tag.toUpperCase()) ;
    return el;
  },
  isVisible: function(elem) {
    return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
  },
  getElemCoords: function(elem) {
    let box = elem.getBoundingClientRect()
    let body = document.body
    let docEl = document.documentElement
    let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft
    let clientTop = docEl.clientTop || body.clientTop || 0
    let clientLeft = docEl.clientLeft || body.clientLeft || 0
    let top  = box.top +  scrollTop - clientTop
    let left = box.left + scrollLeft - clientLeft
    return { top: Math.round(top), left: Math.round(left) }
  },
  extend: function() {
    let options, name, src, copy, copyIsArray, clone
    let target = arguments[0]
    if (!target) {
      target = {}
    }
    let i = 1
    let length = arguments.length
    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== "object" && !MC.isFunction(target)) {
      target = {}
    }
    for (; i < length; i++) {
      // Only deal with non-null/undefined values
      if ((options = arguments[i]) != null) {
        // Extend the base object
        for (name in options) {
          src = target[name]
          copy = options[name]
          // Prevent never-ending loop
          if (target === copy) {
            continue
          }
          // Recurse if we're merging plain objects or arrays
          if (copy && (MC.isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))) {
            if (copyIsArray) {
              copyIsArray = false
              clone = src && Array.isArray(src) ? src : []
            } else {
              clone = src && MC.isPlainObject(src) ? src : {}
            }
            // Never move original objects, clone them
            target[name] = MC.extend(clone, copy)
          } else {
            target[name] = copy
          }
        }
      }
    }
    return target
  },
  extendFormField: function(target, options) {
    let name, src, copy, clone
    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== "object" && !MC.isFunction(target)) {
      target = {}
    }
    if (options != null) {
      // Extend the base object
      for (name in options) {
        src = target[name]
        copy = options[name]
        // Prevent never-ending loop
        if (target === copy) {
          continue
        }
        if (Array.isArray(copy)) {
          target[name] = copy.map(item => {
            if (MC.isPlainObject(item)) {
              return MC.extendFormField(Array.isArray(item) ? [] : {}, item)
            } else {
              return item
            }
          })
        } else if (copy && MC.isPlainObject(copy)) {
          clone = src && MC.isPlainObject(src) ? src : {}
          target[name] = MC.extendFormField(clone, copy)
        } else {
          if (copy === '' && Array.isArray(src)) {
            delete target[name]
          } else {
            target[name] = copy
          }
        }
      }
    }
    return target
  },
  copyFormField: function(options, iteration) {
    let name, copy
    let target = Array.isArray(options) ? [] : {}
    for (name in options) {
      if (!options.hasOwnProperty(name)) continue
      copy = options[name]
      if (copy && (MC.isPlainObject(copy) || Array.isArray(copy)) && ['flow', 'parent', 'reactWidget'].indexOf(name) < 0) {
        target[name] = MC.copyFormField(copy, iteration)
      } else {
        target[name] = copy
      }
    }
    if (iteration != undefined && target.param) { // is field
      target.param['@iteration'] = iteration
    }
    return target
  },
  closestHasAttr: function(el, atrName) {
    while (MC.isFunction(el.hasAttribute) && !el.hasAttribute(atrName)) {
        el = el.parentNode;
        if (!el) {
            return null;
        }
    }
    return el;
  },
  callServer: function(method, ri, accept, content, contentType, reqHeaders, timeout) {
    if (typeof mncServerFunction !== "undefined") { // HACK for external server calling
      return mncServerFunction(method, ri, accept, content, contentType, reqHeaders, timeout)
    }
    return new Promise(function (resolve, reject) {
      try {
        let xhr = new XMLHttpRequest()
        xhr.open(method, ri) 
        if (timeout) {
          xhr.timeout = timeout
        }
        if (accept) {
          xhr.setRequestHeader('Accept', accept)
        }
        xhr.setRequestHeader('x-metada-correlation-id', MC.correlationId)
        if (reqHeaders && MC.isPlainObject(reqHeaders)) {
          for (let header in reqHeaders) {
            xhr.setRequestHeader(header, reqHeaders[header])
          }
        }
        xhr.onload = function () {
          let res = {}
          res.status = xhr.status
          res.content = xhr.responseText
          res.contentType = xhr.getResponseHeader('Content-Type')
          if (res.contentType) {
            res.contentType = res.contentType.split(';')[0]
          }
          res.headers = MC.parseResponseHeaders(xhr)
          resolve(res)
        }
        xhr.onerror = function () {
          reject(new Error('Calling server side failed!'))
        }
        xhr.ontimeout = function() {
          reject(new Error('Network timeout! Server connection lost.'))
        }
        if (content) {
          if (!MC.isNull(contentType)) {
            xhr.setRequestHeader('Content-Type', contentType)
          }
          xhr.send(content)
        } else {
          xhr.send()
        }
      } catch (err) {
        reject(err)
      }
    })
  },
  URLUtils: function(url, baseURL) {
    let m = String(url).replace(/^\s+|\s+$/g, "").match(/^([^:\/?#]+:)?(?:\/\/(?:([^:@\/?#]*)(?::([^:@\/?#]*))?@)?(([^:\/?#]*)(?::(\d*))?))?([^?#]*)(\?[^#]*)?(#[\s\S]*)?/)
    if (!m) { throw new RangeError() }
    let protocol = m[1] || ""
    let host = m[4] || ""
    let pathname = m[7] || ""
    let search = m[8] || ""
    let hash = m[9] || ""
    if (baseURL !== undefined) {
      let base = new MC.URLUtils(baseURL)
      if (pathname === "" && search === "") {
        search = base.search
      }
      if (pathname.charAt(0) !== "/") {
        pathname = (pathname !== "" ? ((base.pathname === "" ? "/" : "") + base.pathname.slice(0, base.pathname.lastIndexOf("/") + 1) + pathname) : base.pathname);
      }
      let output = [];
      pathname.replace(/^(\.\.?(\/|$))+/, "").replace(/\/(\.(\/|$))+/g, "/").replace(/\/\.\.$/, "/../").replace(/\/?[^\/]*/g, (p) => {
          if (p === "/..") {
            output.pop()
          } else {
            output.push(p)
          }
      })
      pathname = output.join("").replace(/^\//, pathname.charAt(0) === "/" ? "/" : "")
      host = host || base.host
      protocol = protocol || base.protocol
    }
    this.href = pathname + search + hash
    this.pathname = pathname
    this.search = search
    this.host = host
    this.protocol = protocol
  },
  putValueIntoMultiArray: function(arr, indexes, totalSize, value) {
    if (MC.isNull(value) && totalSize == 1 && (!Array.isArray(indexes) || indexes.length <= 1)) {
      return null
    }
    if (!Array.isArray(indexes) || indexes.length < 1) {
      return value
    }
    if (MC.isNull(arr) || !Array.isArray(arr)) {
      arr = []
    }
    let levelArr = arr
    for (let i=0; i<indexes.length; i++) {
      let index = indexes[i]
      if (i < indexes.length-1) { // path
        if (MC.isNull(value) && totalSize == 1 && i == indexes.length-2) {
          levelArr[index] = null
          return arr
        }
        if (!Array.isArray(levelArr[index])) {
          levelArr[index] = []
        }
        levelArr = levelArr[index]
      } else { // list
        if (index == 0) {
          levelArr.length = 0
        }
        levelArr[index] = value
      }
    }
    return arr
  },
  setFieldsPropertyRecusively: function(tree, property, value) {
    tree[property] = value
    if (Array.isArray(tree.fields)) {
      for (let subTree of tree.fields) {
        MC.setFieldsPropertyRecusively(subTree, property, value)
      }
    }
    if (Array.isArray(tree.rows)) {
      for (let subTree of tree.rows) {
        MC.setFieldsPropertyRecusively(subTree, property, value)
      }
    }
  },
  setPropertyRecusively: function(tree, treeProperty, property, value) {
    tree[property] = value
    if (Array.isArray(tree[treeProperty])) {
      for (let subTree of tree[treeProperty]) {
        MC.setPropertyRecusively(subTree, treeProperty, property, value)
      }
    }
  },
  setPropertyRecusivelyIfNotDefined: function(tree, treeProperty, property, value) {
    if (typeof tree[property] === 'undefined') {
      tree[property] = value
    }
    if (Array.isArray(tree[treeProperty])) {
      for (let subTree of tree[treeProperty]) {
        MC.setPropertyRecusivelyIfNotDefined(subTree, treeProperty, property, value)
      }
    }
  },
  ensureIterations: function(def, iteration) {
    if (iteration.length > 0 && def.param) { // is field
      def.param['@iteration'] = iteration
    }
    if (Array.isArray(def.fields)) {
      for (let subTree of def.fields) {
        MC.ensureIterations(subTree, iteration)
      }
    }
    if (Array.isArray(def.rows) && def.rows.length > 0) {
      for (let i=0; i<def.rows.length; i++) {
        MC.ensureIterations(def.rows[i], [...iteration, i])
      }
    }
  },
  initParentFields: function(def) {
    if (Array.isArray(def.fields)) {
      for (let sub of def.fields) {
        sub.parent = def
        MC.initParentFields(sub)
      }
    }
    if (Array.isArray(def.rows) && def.rows.length > 0) {
      for (let sub of def.rows) {
        sub.parent = def
        MC.initParentFields(sub)
      }
    }
  },
  isInTable: function(field) {
    let parent = field.parent
    while (parent && parent.id == "rows*") {
      parent = parent.parent
    }
    return parent && parent.widget && "table" == parent.widget
  },
  findRootRepeatable: function(def, lastRpt) {
    if (def.rbsid == 'dummy-rows' && def.parent) {
      lastRpt = def.parent
    }
    if (def.parent) {
      return MC.findRootRepeatable(def.parent, lastRpt)
    } 
    return lastRpt
  },
  findRoot: function(def) {
    return def.parent ? MC.findRoot(def.parent) : def
  },
  getRadios: function(def) {
    let res = []
    if (Array.isArray(def.rows) && def.rows.length > 0) {
      for (let sub of def.rows) {
        res = res.concat(MC.getRadios(sub))
      }
    } else if (Array.isArray(def.fields)) {
      for (let sub of def.fields) {
        if (sub.widget == 'radiobutton') {
          res.push(sub)
        } else {
          res = res.concat(MC.getRadios(sub))
        }
      }
    }
    return res
  },
  classes: function() {
		let classes = []
		for (let i = 0; i < arguments.length; i++) {
      const arg = arguments[i]
			if (!arg) continue
			if (typeof arg === 'string' || typeof arg === 'number') {
				classes.push(arg)
			} else if (Array.isArray(arg) && arg.length) {
				const inner = MC.classes.apply(null, arg)
				if (inner) {
					classes.push(inner)
				}
			} else if (typeof arg === 'object') {
				for (const key in arg) {
					if (arg.hasOwnProperty(key) && arg[key]) {
						classes.push(key)
					}
				}
			}
		}
		return classes.join(' ')
  },
  getParameterByName: function(name, url) {
    if (!url && url !== '') {
      url = window.location.href
    }
    name = name.replace(/[\[\]]/g, "\\$&")
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
    let results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, " "))
  },
  findByObjectParamValue: function(coll, param, value) {
    if (Array.isArray(coll) || coll.length > 0) {
      for (let obj of coll) {
        if (obj[param] === value) {
          return obj
        }
      }
    }
    return null
  },
  prepareXMLForParsing: function(xmlString) {
    xmlString = xmlString.trim()
    let xmlnss = ''
    let match = Array.from(xmlString.matchAll(/[^<>]*<([^<>/\s]*):[^<>]*>/g), m => m[1]) 
    if (match && match.length > 0) {
      let index = xmlString.indexOf('>')
      let uniq = new Set()
      for (let i=0; i<match.length; i++) {
        if (!uniq.has(match[i])) {
          uniq.add(match[i])
          let definedIndex = xmlString.indexOf(' xmlns:' + match[i] + '=')
          if (definedIndex < 0 || definedIndex > index) {
            xmlnss += ' xmlns:' + match[i] + '="space' + i +'"'
          } 
        } 
      }
      xmlString = xmlString.substring(0, index) + xmlnss + xmlString.substring(index)
    }
    return xmlString
  },
  objectEqual: function(objA, objB, deep, ignoreKeys) {  
    if (objA === objB) {
      return true
    }
    if (typeof objA !== "object" || !objA || typeof objB !== "object" || !objB) {
      return false
    }
    let keysA = Object.keys(objA)
    let keysB = Object.keys(objB)
    if (Array.isArray(ignoreKeys)) {
      keysA = keysA.filter(key => ignoreKeys.indexOf(key) < 0)
      keysB = keysB.filter(key => ignoreKeys.indexOf(key) < 0)
    }
    if (keysA.length !== keysB.length) {
      return false
    }
    let bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB)
    for (let idx = 0; idx < keysA.length; idx++) {
      if (MC.isFunction(objA[keysA[idx]]) && MC.isFunction(objB[keysA[idx]])) {
        continue
      }
      if (!bHasOwnProperty(keysA[idx])) {
        return false
      }
      if (typeof objA[keysA[idx]] == 'object' && typeof objB[keysA[idx]] == 'object' && deep) {
        if (!MC.objectEqual(objA[keysA[idx]], objB[keysA[idx]], true, ignoreKeys)) {
          return false
        }
      } else {
        if (Array.isArray(objA[keysA[idx]]) && Array.isArray(objB[keysA[idx]])) {
          if (objA[keysA[idx]].length !== objB[keysA[idx]].length) {
            return false
          }
          for (let akey of Object.keys(objA[keysA[idx]])) {
            if (objA[keysA[idx]][akey] !== objA[keysA[idx]][akey]) {
              return false
            }
          }  
        } else if (objA[keysA[idx]] !== objB[keysA[idx]]) {
          return false
        }
      }
    }
    return true
  },
  ensureSystemParameters: (currentRi, ri) => {
    if ((MC.getParameterByName('debug', currentRi)) && !MC.getParameterByName('debug', ri)) {
      let hash = ""
      if (ri.indexOf("#") >= 0) {
        hash = "#" + ri.split("#")[1]
        ri = ri.split("#")[0]
      }
      ri += (ri.indexOf('?') > -1 ? '&' : '?') + 'debug=' + (MC.getParameterByName('debug', currentRi)) + hash
    }
    return ri
  },
  isCorrespondingRepeater: (def, triggeredByField) => {
    if (triggeredByField && def.rbsid === triggeredByField.rbsid) {
      return true
    } else if (def.fields && def.fields.length > 0) {
      for (let i=0; i<def.fields.length; i++) {
        if (MC.isCorrespondingRepeater(def.fields[i], triggeredByField)) {
          return true
        }
      }
    }
    return false
  },
  prepareDate: (field, defaultValue, basicType = 'date') => {
    let dateValue = null
    let withTimezone = MC.getFieldParamBooleanValue(field.param, '@outputTimezone')
    if (!MC.isNullOrEmpty(defaultValue) && MC.isValidDateStringByType(defaultValue, basicType)) {
      let defaultLuxon = MC.dateTimeStringToLuxon(defaultValue).v
      if (defaultLuxon.isValid) {
        if (withTimezone && !MC.hasTimezone(defaultValue)) {
          let expression = new Expression()
          expression.init(null, field.flow.context ? field.flow.context.data : {}, null)
          defaultValue = expression.operatorFillTimezone([Value.v(defaultValue)]).value
        }
        if (!withTimezone && MC.hasTimezone(defaultValue)) {
          let expression = new Expression()
          expression.init(null, field.flow.context ? field.flow.context.data : {}, null)
          defaultValue = expression.operatorRemoveTimezone([Value.v(defaultValue)]).value
        }
      }
      dateValue = MC.dateTimeStringToLuxon(defaultValue).v
    } else {
      dateValue = {isValid: false}
    }
    let dateFormat = 'dd. MM. yyyy'
    let timeFormat = 'HH:mm'
    let pattern = MC.getFieldParamValue(field.param, '@formatPattern')
    if (!MC.isNull(pattern)) {
      switch (field.basictype) {
        case 'dateTime':
          let tokens = pattern.split("'T'")
          if (tokens.length == 2) {
            dateFormat = JdateFormat.toLuxonFormatString(tokens[0])
            timeFormat = JdateFormat.toLuxonFormatString(tokens[1])
          } else {
            MCHistory.log(MCHistory.T_ERROR, 'Unsupported dateTime format' + pattern + ' at dateTimePicker (date\'T\'time), using default formats!', field.flow.debug())
          }
          break
        case 'time': timeFormat = JdateFormat.toLuxonFormatString(pattern); break
        default: dateFormat = JdateFormat.toLuxonFormatString(pattern); break
      }
    } else if (dateValue.isValid) {
      dateValue.set({second: 0, millisecond: 0})
    }
    let altFormats = []
    let apatterns = MC.asArray(MC.getFieldParamValue(field.param, '@secondaryPatterns'))
    if (!MC.isNull(apatterns)) {
      for (let apattern of apatterns) {
        if (!MC.isNull(apattern)) {
          let adateFormat = null
          let atimeFormat = null
          switch (field.basictype) {
            case 'dateTime':
              let tokens = apattern.split("'T'")
              if (tokens.length == 2) {
                adateFormat = JdateFormat.toLuxonFormatString(tokens[0])
                atimeFormat = JdateFormat.toLuxonFormatString(tokens[1])
              } else {
                MCHistory.log(MCHistory.T_ERROR, 'Unsupported dateTime format' + apattern + ' at dateTimePicker (date\'T\'time), using default formats!', field.flow.debug())
              }
              break
            case 'time': atimeFormat = JdateFormat.toLuxonFormatString(apattern); break
            default: adateFormat = JdateFormat.toLuxonFormatString(apattern); break
          }
          altFormats.push({dateFormat: adateFormat, timeFormat: atimeFormat})
        }
      }
    }
    return {dateValue, withTimezone, dateFormat, timeFormat, altFormats}
  },
  offset: (el) => {
    let rect = el.getBoundingClientRect()
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
  },
  outerWidthIncludeMargin: (el) => {
    let width = el.offsetWidth
    let style = getComputedStyle(el)
    width += parseInt(style.marginLeft) + parseInt(style.marginRight)
    return width
  },
  outerHeightIncludeMargin: (el) => {
    let height = el.offsetHeight
    let style = getComputedStyle(el)
    height += parseInt(style.marginTop) + parseInt(style.marginBottom)
    return height
  },
  iconize: (field, el, icoCss) => {
    let icon = MC.getFieldParamValue(field.param, '@icon')
    let leftIcon = null
    let rightIcon = null
    if (icon) {
      if (MC.getFieldParamValue(field.param, '@iconPlacement') === 'right') {
        rightIcon = <i key="icon" className={MC.buildIconClass(icon, icoCss)}/>
      } else {
        leftIcon = <i key="icon" className={MC.buildIconClass(icon, icoCss)}/>
      }
    }
    return <React.Fragment>{leftIcon}{el}{rightIcon}</React.Fragment>
  },
  buildIconClass: (icon, icoCss) => {
    if (icon.indexOf('flag') >= 0) {
      let tokens = icon.split(" ")
      if ((tokens.length == 2 || tokens.length == 3) && tokens[0].length == 2) {
        return MC.classes(icon, icoCss)
      }
    } 
    return MC.classes(icon, 'icon', icoCss)
  },
  generateId: () => {
    let d = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (d + Math.random()*16)%16 | 0; 
      d = Math.floor(d/16);
      return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    })
  },
  eventHasKey: (e) => {
    return e.metaKey || e.ctrlKey || e.altKey || e.shiftKey
  },
  noStrailingSlash: (s) => {
    if (s.endsWith('/')) {
      s = s.substring(0, s.length - 1)
    }
    return s
  },
  checkDateTimeValidDST: (lux, strDate) => {
    if (MC.getDateTimeType({_i: strDate}) != 'dateTime') {
      return true
    }
    let hour = Number(strDate.split('T')[1].split(":")[0]).valueOf() // beacuse in Luxon it is adjusted for valid existing hour
    let zone = lux.zone
    if (zone.isUniversal) {
      return true
    } else {
      // Step 1. Create a "local timestamp" from this date object
      let localTS = +Date.UTC(lux.year, lux.month - 1, lux.day, hour, lux.minute, lux.second, lux.millisecond)
      // Step 2. Get min/max timezone offsets from this timezone
      let zoneMaxOffset = undefined
      let zoneMinOffset = undefined
      for (let month = 1; month <= 12; month++) {
        let date = DateTime.fromObject({year: lux.year, month: month, day: lux.day, hour: hour, minute: lux.minute, second: lux.second, millisecond: lux.millisecond}, {zone})
        if (zoneMaxOffset === undefined || date.offset > zoneMaxOffset) {
          zoneMaxOffset = date.offset
        }
        if (zoneMinOffset === undefined || date.offset < zoneMinOffset) {
          zoneMinOffset = date.offset
        }
      }
      // Step 3. Test whether these offsets "tie out" (match the offset reported by the timezone)
      let zoneMinOffsetIsCorrect = zone.offset(localTS - (zoneMinOffset * 60 * 1000)) === zoneMinOffset
      let zoneMaxOffsetIsCorrect = zone.offset(localTS - (zoneMaxOffset * 60 * 1000)) === zoneMaxOffset
      if (!zoneMaxOffsetIsCorrect && !zoneMinOffsetIsCorrect) {
        // Neither offsets are correct - dateTime must be a skipped ("hole") time 
        return false
      } else if (zoneMaxOffsetIsCorrect && zoneMinOffsetIsCorrect) {
        // Both offsets are correct - dateTime must be an ambiguous time
        return false
      } else {
        return true
      }
    }
  },
  getFlowDefinition: (flowName, confPath, lang, baseUrl, flow) => {
    return new Promise(function(resolve, reject) {
      if (flow && !MC.isNull(flow.flow)) {
        resolve(flow.flow)
      } else {
        const url = baseUrl + ReactFlow.flowTemplate.replace('{configuration}', confPath).replace('{flowName}', flowName).replace('{lang}', lang)
        if (MCCache.has(url)) {
          resolve(MCCache.get(url))
        } else {
          MC.callServer('GET', url, MC.getJsonType()).then(function (result) {
            if (result.status == 200) {
              let list = JSON.parse(result.content)
              const flowTemplate = baseUrl + ReactFlow.flowTemplate
              for (let def of list) {
                MCCache.put(flowTemplate.replace('{configuration}', confPath).replace('{flowName}', def.id).replace('{lang}', lang), def)
              }
              resolve(list[0])
            } else {
              reject('Error in flow definition ' + url  + '\n' + result.content)
            }
          }).catch(function (err) {
            reject('Error in flow definition ' + url  + '\n' + err.message)
          })
        }
      }  
    })  
  },
  saveLastMouseClickPosition: (e) => {
    MC.lastMouseClickPosition = {x: e.clientX, y: e.clientY}
  },
  parseResponseHeaders: (xhr) => {
    const arr = xhr.getAllResponseHeaders().trim().split(/[\r\n]+/)
    const headers = {}
    for (let line of arr) {
      const parts = line.split(': ')
      const header = parts.shift()
      const value = parts.join(': ')
      headers[header] = value
    }
    return headers
  },
  htmlId: (field) => {
    let i = MC.getFieldParamValue(field.param, '@iteration')
    return field.rbsid + (Array.isArray(i) ? ':' + i.join(':') : '') 
  },
  base64toBlob(b64Data) {
    const byteCharacters = atob(b64Data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    return new Blob([new Uint8Array(byteNumbers)], {type: 'application/octet-stream'})
  },
  hasLogicForEvent(data, event) {
    if (!MC.isNull(data) && Array.isArray(data.logic)) {
      for (let actl of data.logic) {
        if (Array.isArray(actl.event)) {
          for (let acte of actl.event) {
            if (acte['e'] == event) {
             return true
            }
          }
        }
      }
    }
    return false
  },
  formatStringToCamelCase(str) {
    const splitted = str.split("-")
    if (splitted.length === 1) return splitted[0]
    return splitted[0] + splitted.slice(1).map(word => word[0].toUpperCase() + word.slice(1)).join("")
  },
  styleObjectFromString(str) {
    try {
      const style = {}
      if (!str) return style
      if (typeof str === 'string') {
        str = str.trim()
        if (str.startsWith('{')) {
          str = str.substring(1, str.length -1)
        }
        str = str.trim()
        str.split(";").forEach(el => {
          let [prop, value] = el.split(":")
          if (!prop) return
          style[MC.formatStringToCamelCase(prop.trim())] = value.trim()
        })
      }
      return style
    } catch {
      return {}
    } 
  }

}

if (!window.MC) {
  window.MC = MC
}