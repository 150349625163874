import React from "react"

import {MC} from '../client/MC.js'
import {ReactFlow} from "../client/ReactFlow.jsx"

class MiniclientConsole extends React.Component {

  constructor(props) {
    super(props)
    MC.correlationId = MC.generateId()
    let flowName = MC.getParameterByName("flowName") || props.mconf['flowName'] || null
    this.start = flowName ? true : false
    let debug = MC.getParameterByName("debug")
    if (!debug && props.mconf['debug']) {
      debug = props.mconf['debug']
    }
    if (debug == 'false') {
      debug = null
    }
    let input = null
    if (props.mconf.input && MC.isPlainObject(props.mconf.input)) {
      input = props.mconf.input
    } else {
      let urlInput = MC.getParameterByName("input")
      if (urlInput) {
        input = JSON.parse(urlInput)
      }
    }  
    this.state = {flowName: flowName, debug: debug, input: input}
  }
  
  resetStart = () => {
    this.forceUpdate()
  }

  obtainStart = () => {
    if (this.start) {
      this.start = false
      return true
    }
    return false
  }

  afterRenderFormFunction = (form, initial) => {
    if (parent) {
      setTimeout(() => { 
        let urlarr = window.location.href.split("/")
        parent.postMessage(initial ? {height: "initial", width: "initial"} : {height: document.body.scrollHeight, width: document.body.scrollWidth}, urlarr[0] + "//" + urlarr[2])
      }, 100)
    }
  }

  render() {
    let clearStateOnEnd = this.props.mconf.endFunction ? true : false // clear state on end when external funcion deal errors and ouputs
    return <ReactFlow configuration={this.props.mconf['configuration']} flowName={this.state.flowName} debug={this.state.debug} console={true} start={this.obtainStart()} mconf={this.props.mconf}
            input={this.state.input} afterRenderFormFunction={this.afterRenderFormFunction} resetStart={this.resetStart} onEndFunction={this.props.mconf.endFunction} clearStateOnEnd={clearStateOnEnd}/>
  }

}

export default MiniclientConsole