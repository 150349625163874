import React from "react"

import NumberFormat from './numberFormatInput.jsx'
import Dropdown from './semantic-widgets/Dropdown.jsx'
import Accordion from './semantic-widgets/Accordion.jsx'
import Checkbox from './semantic-widgets/Checkbox.jsx'
import Transition from './semantic-widgets/Transition.jsx'
import MaskedInput from './masked-input/MaskedInput.jsx'
import {Field} from "../modeler/Field.jsx";
import {EditableLabel} from "../modeler/EditableLabel.jsx";
import {Dummy} from "../modeler/Dummy.jsx";
import {MC} from './MC.js';
import {Button} from "./Button.jsx";
import {Camera} from "./Camera.jsx";
import {Repeater} from "./Repeater.jsx";
import {Table} from "./Table.jsx";
import {TabPanel} from "./TabPanel.jsx";
import {Slider} from "./Slider.jsx";
import {Label} from "./Label.jsx";
import {Download} from "./Download.jsx";
import {Media} from "./Media.jsx";
import {Link} from "./Link.jsx";
import {EmbeddedDialog} from "./EmbeddedDialog.jsx";
import {WhisperBox} from "./WhisperBox.jsx";
import {Upload} from "./Upload.jsx";
import {Paginator} from "./Paginator.jsx"
import Datebox from "./Datebox.jsx"
import Menu from "./Menu.jsx"
import Carousel from './carousel/Carousel.jsx'
import Breadcrumb from './Breadcrumb.jsx'
import ObjectInspector from './ObjectInspector.jsx'
import Steps from './Steps.jsx'
import Iframe from './Iframe.jsx'
import {Value} from "./Value.js"
import tt from "./tooltip/tooltip.js"

import './tooltip/tooltip.css'

class Widget extends React.Component {

  state = this.calculateState(this.props)
  widgetRef = React.createRef()
  widgetRootRef = React.createRef()
  typingTimer = null

  componentDidMount() {
    this.afterUpdate()
    this.scriptedWidget()
    this.updateTooltip()
    this.conditionalForceValidate()
    if (MC.getFieldParamBooleanValue(this.props.widget.param, '@sticky') && !MC.isModelerActive(this.props.widget)) {
      window.addEventListener('scroll', this.handleStickyScroll)
    }
  }

  calculateState(props) {
    let field = props.widget
    let visible = true // default is true
    let grid = MC.getFieldGrid(field, props.resolution)
    let wideClass = MC.getFieldWideClassFromInt(grid.columns)
    if (grid.visible === 'false' || grid.visible === false) {
      visible = false
    }
    if (MC.getFieldParamBooleanValue(field.param, '@visible') === false) {
      visible = false
    }
    if (MC.isModelerActive(field)) {
      if (field.isInteractive) {
        wideClass = ""
      }
      if (MC.isModelerInEyeMode(field)) {
        visible = true
      }
    }
    let focus = MC.getFieldParamBooleanValue(field.param, '@focused') || false
    if (focus) { // set focused false, for re-render caused by formlogic
      MC.putFieldParamValue(field.param, '@focused', false)
    }
    return {visible: visible, wideClass: wideClass, focus: focus}
  }

  componentDidUpdate() {
    let nState = this.calculateState(this.props)
    if (this.state.visible !== nState.visible || this.state.wideClass !== nState.wideClass || this.state.focus !== nState.focus) {
      this.setState(nState)
    }
    this.afterUpdate()
    this.scriptedWidget()
    this.updateTooltip()
    this.conditionalForceValidate()
  }

  componentWillUnmount() {
    if (MC.getFieldParamBooleanValue(this.props.widget.param, '@sticky') && !MC.isModelerActive(this.props.widget)) {
      window.removeEventListener('scroll', this.handleStickyScroll)
    }
    this.destroyTooltip()
    if (!MC.isModelerActive(this.props.widget)) {
      this.props.widget.reactWidget = null
    }
  }

  updateTooltip() {
    let tooltip = MC.getFieldParamValue(this.props.widget.param, '@tooltip')
    let tippyNode = this.widgetRootRef.current
    if (!MC.isNull(tooltip) && tippyNode) {
      if (tooltip !== this.tooltip) {
        this.tooltip = tooltip
        let toolTipHere = tippyNode.querySelector('.tooltip-here')
        if (toolTipHere) {
          tippyNode = toolTipHere
        }
        if (tippyNode._tippy) {
          tippyNode._tippy.destroy()
        }
        if (tooltip) {
          let escape = MC.getFieldParamBooleanValue(this.props.widget.param, '@escapeTooltipHtml')
          tt(tippyNode, {content: tooltip, allowHTML: !escape})
        }
      }
    } else {
      this.destroyTooltip()
    }
  }

  destroyTooltip() {
    if (this.tooltip) {
      this.tooltip = null
      let tippyNode = this.widgetRootRef.current
      if (tippyNode) {
        let toolTipHere = tippyNode.querySelector('.tooltip-here')
        if (toolTipHere) {
          tippyNode = toolTipHere
        }
        if (tippyNode._tippy) {
          tippyNode._tippy.destroy()
        }
      } 
    }
  }

  scriptedWidget() {
    var field = this.props.widget;
    if (MC.isModelerActive(field)) {
      return;
    }
    if (field.widget == 'panel' && field.scriptedWidget && field.scriptedWidget.script) {
      let widget;
      if (this.scriptedWidgetObject) {
        widget = this.scriptedWidgetObject
      } else {
        eval(field.scriptedWidget.script);
      }
      // run scripted widget
      if (widget) {
        if (MC.isFunction(widget.setValue)) {
          let input = MC.extend({}, this.props.widget.param)
          if (!this.scriptedWidgetValue || !MC.objectEqual(this.scriptedWidgetValue, input, true)) {
            this.scriptedWidgetValue = input
            widget.setValue(MC.extend({}, input))
          }
        }
        if (!this.scriptedWidgetObject && MC.isFunction(widget.onCreate)) {
          const options = {iteration: MC.getFieldParamValue(field.param, '@iteration')}
          widget.onCreate(this.widgetRef.current, field, options)
        }
        this.props.widget.scriptedWidgetObject = widget;
        this.scriptedWidgetObject = widget
      }
    }
  }

  handleStickyScroll = () => {
    var div = this.widgetRootRef.current
    var parentCol = MC.findAncestor(div, 'widget');
    if (parentCol) {
      if (!this.isSticked) {
        this.stickyTop = MC.getElemCoords(div).top;
        var fixedHeader = document.getElementsByClassName('fixed-header-size');
        if (fixedHeader && fixedHeader.length === 1) {
          this.fixedHeaderHeight = fixedHeader[0].getBoundingClientRect().height;
        } else {
          this.fixedHeaderHeight = 0;
        }
      }
      var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      y = y + this.fixedHeaderHeight;
      if (y >= this.stickyTop) {
        var yD = y + div.getBoundingClientRect().height + 5;
        var stickyBottom = MC.getElemCoords(parentCol).top + parentCol.getBoundingClientRect().height;
        if (yD < stickyBottom) {
          div.style.cssText = "position: relative; top: " + (y - this.stickyTop) + "px;";
        }
        this.isSticked = true;
      } else {
        div.style.cssText = "";
        this.isSticked = false;
      }
    }
  };

  panelOpenClose = (e, active) => {
    MC.putFieldParamValue(this.props.widget.param, '@collapsed', active)
    this.forceUpdate()
    if (MC.isModelerActive(this.props.widget)) {
      modelerChanged("resize")
    } else {
      MC.handleEvent(this.props.widget, 'change')
    }
  }

  afterUpdate() {
    if (this.state.focus) {
      var input = this.widgetRef.current
      if (input && MC.isFunction(input.focus)) {
        input.focus()
      } else if (this.widgetRootRef.current) {
        this.widgetRootRef.current.scrollIntoView()
      }
      var fixedHeader = document.getElementsByClassName('fixed-header-size')
      if (fixedHeader && fixedHeader.length === 1) {
        scrollBy(0, -fixedHeader[0].getBoundingClientRect().height)
      }
      this.setState({focus: false})
    }
    if (this.state.focused) {
      const formatter = MC.getFieldParamValue(this.props.widget.param, '@formatType')
      const disableFormatOnEdit = MC.getFieldParamBooleanValue(this.props.widget.param, '@disableFormatOnEdit')
      if (!MC.isNull(formatter) && disableFormatOnEdit) { // texfield was probably replaced in DOM and must be refocused
        const input = this.widgetRef.current
        if (input && MC.isFunction(input.focus)) {
          input.focus()
        }  
      }
    }
  }

  conditionalForceValidate() {
    if (MC.isModelerActive(this.props.widget)) {
      return
    }
    const forceValidation = MC.getFieldParamBooleanValue(this.props.widget.param, 'validation/@forceValidation')
    if (forceValidation) {
      MC.putFieldParamValue(this.props.widget.param, 'validation/@forceValidation', false)
      this.props.widget.flow.focusedOnFirst = true // prevent focus on first invalid
      this.revalidate(true)
    }
  }

  handleDateChange = (value) => {
    var field = this.props.widget;
    if (value && value.isLuxonDateTime == true) {
      let withTimezone = MC.getFieldParamBooleanValue(field.param, '@outputTimezone')
      switch (field.basictype) {
        case 'dateTime': MC.putFieldParamValue(field.param, 'value', value.toFormat(withTimezone ? "yyyy-MM-dd'T'HH:mm:ss.SSSZZ" : "yyyy-MM-dd'T'HH:mm:ss.SSS")); break
        case 'time': MC.putFieldParamValue(field.param, 'value', value.toFormat(withTimezone ? "HH:mm:ssZZ" : "HH:mm:ss")); break
        default: MC.putFieldParamValue(field.param, 'value', value.toFormat(withTimezone ? "yyyy-MM-ddZZ" : "yyyy-MM-dd")); break
      }
      if (this.props.widget.flow && this.props.widget.flow.getCfgParameter('fl:validationStyle') == 'blur') {
        field.flow.focusedOnFirst = true // prevent focus on first invalid
        this.revalidate(true)
      } else {
        this.revalidate()
      }
    } else {
      MC.putFieldParamValue(field.param, 'value', value)
      this.resetValidation()
      this.forceUpdate()
    }
    MC.handleEvent(field, 'change')
  }

  handleTextChange = (e, v, options) => {
    var field = this.props.widget;
    if (MC.isModelerActive(field)) {
      return
    }  
    switch (field.widget) {
      case "checkbox":
        MC.putFieldParamValue(field.param, 'value', v.checked)
        MC.handleEvent(field, 'click', null, e)
        field.flow.handleSubmit(field)
        break;
      case "combobox":
        MC.putFieldParamValue(field.param, 'value', v.value)
        let options = this.buildOptions(field, false, v.value, false)
        if (options.simpleText) {
          MC.putFieldParamValue(field.param, 'text', options.simpleText)
        }
        field.flow.handleSubmit(field)
        break;
      case "colorpalette":
        MC.putFieldParamValue(field.param, 'value', v.value)
        field.flow.handleSubmit(field)
        break;  
      case "whisperbox":
        MC.putFieldParamValue(field.param, 'value', e)
        MC.putFieldParamValue(field.param, 'text', v)
        field.flow.handleSubmit(field)
        break;
      case "radiobutton":
        let parent = this.getParentRadioGorup()
        if (parent) {
          let value = MC.getFieldParamValue(field.param, 'value') ?? true
          if (typeof value == 'boolean') {
            value = true
            let radios = MC.getRadios(parent)
            if (Array.isArray(radios)) {
              for (let radio of radios) {
                MC.putFieldParamValue(radio.param, 'value', field == radio)
              }  
            }
          } 
          MC.putFieldParamValue(parent.param, 'value', value)
        } 
        break;
      case "unitcombobox":
        if (!v || v.event) {
          let formatter = MC.getFieldParamValue(field.param, '@formatType')
          const disableFormatOnEdit = MC.getFieldParamBooleanValue(field.param, '@disableFormatOnEdit')
          if (formatter == 'number' && (!disableFormatOnEdit || !this.state.focused)) {
            MC.putFieldParamValue(field.param, 'value', e.floatValue)
          } else if (e.target.value) {
            let newVal = e.target.value
            let maxLength = MC.getFieldParamValue(field.param, 'validation/@maxLength')
            if (MC.isNumeric(maxLength) && newVal.length > maxLength) {
              newVal = MC.getFieldParamValue(field.param, 'value')
            }
            if (['integer', 'decimal'].indexOf(field.basictype) > -1) {
              newVal = parseFloat(newVal)
            }
            MC.putFieldParamValue(field.param, 'value',  newVal)
          } else {
            if (['integer', 'decimal'].indexOf(field.basictype) > -1) {
              this.widgetRef.current.value = '' // because when number type, react is not able delete value
            }
            MC.putFieldParamValue(field.param, 'value', null)
          }
        } else {
          MC.putFieldParamValue(field.param, '@unit', v.value)
        }
        break;
      default:
        let formatter = MC.getFieldParamValue(field.param, '@formatType')
        const disableFormatOnEdit = MC.getFieldParamBooleanValue(field.param, '@disableFormatOnEdit')
        if (formatter == 'number' && (!disableFormatOnEdit || !this.state.focused)) {
          MC.putFieldParamValue(field.param, 'value', e.floatValue)
        } else if (e.target.value) {
          let newVal = e.target.value
          let maxLength = MC.getFieldParamValue(field.param, 'validation/@maxLength')
          if (MC.isNumeric(maxLength) && newVal.length > maxLength) {
            newVal = MC.getFieldParamValue(field.param, 'value')
          }
          if (['integer', 'decimal'].indexOf(field.basictype) > -1) {
            newVal = parseFloat(newVal)
          }
          MC.putFieldParamValue(field.param, 'value', newVal)
        } else {
          if (['integer', 'decimal'].indexOf(field.basictype) > -1) {
            this.widgetRef.current.value = '' // because when number type, react is not able delete value
          }
          MC.putFieldParamValue(field.param, 'value', null)
        }
    }
    if (['checkbox', 'combobox', 'colorpalette', 'radiobutton'].indexOf(field.widget) > -1) {
      if (field.flow && field.flow.getCfgParameter('fl:validationStyle') == 'blur') {
        field.flow.focusedOnFirst = true // prevent focus on first invalid
        this.revalidate(true);
      } else {
        this.revalidate();
      }
    } else {
      this.resetValidation();
      this.forceUpdate();
    }
    Promise.resolve().then(()=>{ MC.handleEvent(field, 'change') }) // this must be called by promise for previous force update take effect before change event started 
    if (['textbox', 'unitbox', 'unitcombobox', 'passwordbox', 'textarea', 'whisperbox'].indexOf(field.widget) > -1 && (!options || !options.notypingbreak)) {
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => {MC.handleEvent(field, 'typingbreak')}, 1000)
    }
  }

  handleSearchChange = (sQuery, typingbreak) => {
    let field = this.props.widget
    MC.putFieldParamValue(field.param, '@searchQuery', sQuery)
    clearTimeout(this.typingTimer)
    if (typingbreak) {
      this.typingTimer = setTimeout(() => { MC.handleEvent(field, 'typingbreak') }, 1000)
    }
  }  

  onRadioClick = (e) => {
    if (MC.isModelerActive(this.props.widget)) {
      return
    }
    MC.handleEvent(this.props.widget, 'click', null, e)
  }

  revalidate = (blur) => {
    var field = this.props.widget
    if (MC.getFieldParamBooleanValue(field.param, 'validation/@disableValidation')) {
      return
    }
    this.resetValidation();
    var self = this;
    if (field.widget === "radiobutton") {
      field = this.getParentRadioGorup()
      self = field.reactWidget
    }
    if (MC.getFieldParamBooleanValue(field.param, '@invalid') || blur) { // run only if invalid or blur mode
      MC.validateField(field)
      MC.updateInvalidSummary(field, true)
      self.forceUpdate()
    } else {
      self.forceUpdate()
    }
  }

  buildSubFields(field, disabled, readOnly, label, textMode) {
    var resolution = this.props.resolution
    var hrows = []
    if (label) {
      hrows.push(<div key="label" className="mnc row mc-label">{label}</div>)
    }
    if (field.fields) {
      var rows = MC.splitFieldsIntoRows(field.fields, resolution);
      for (var i = 0; i < rows.length; i++) {
        var hrow = [];
        for (var ii = 0; ii < rows[i].length; ii++) {
          var subField = rows[i][ii];
          let offsetDiv;
          var grid = MC.getFieldGrid(subField, resolution);
          if (grid.offset > 0) {
            var cls = "mnc " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field mobile-no-100";
            offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>;
          }
          hrow.push(<Widget key={subField.id} widget={subField} parent={this} disabled={disabled} readOnly={readOnly} resolution={resolution} offsetDiv={offsetDiv} textMode={textMode}/>)
        }
        if (MC.isRowVisible(rows[i], resolution)) {
          hrows.push(<div key={i} className="mnc row">{hrow}</div>)
        }
      }
    }
    return hrows;
  }

  buildHbox(field, disabled, readOnly) {
    let className = MC.classes({'flex-grow': MC.getFieldParamBooleanValue(field.param, '@fitWidth'), 'gapped': !MC.getFieldParamBooleanValue(field.param, '@grouped')})
    let hcolumns = []
    if (field.fields) {
      for (let subField of field.fields) {
        hcolumns.push(<Widget key={subField.rbsid} widget={subField} parent={this} disabled={disabled} readOnly={readOnly} resolution={this.props.resolution} wrappingDiv={true} wrappingDivClass={className}/>)
      }
    }
    return hcolumns
  }

  buildVbox(field, disabled, readOnly) {
    let className = MC.classes({'flex-grow': MC.getFieldParamBooleanValue(field.param, '@fitWidth'), 'gapped': !MC.getFieldParamBooleanValue(field.param, '@grouped')})
    let vrows = []
    if (field.fields) {
      for (let subField of field.fields) {
        vrows.push(<Widget key={subField.rbsid} widget={subField} parent={this} disabled={disabled} readOnly={readOnly} resolution={this.props.resolution} wrappingDiv={true} wrappingDivClass={className}/>)
      }
    }
    return vrows
  }

  buildOptions(field, required, defaultValue, clearable) {
    let options = []
    if (!required && !MC.getFieldParamBooleanValue(field.param, '@multiple') && !clearable) {
      options.push({value: null, text: ''})
    }
    let items = MC.getFieldParamValue(field.param, 'items')
    if (!items && field.param['defaultItems'] && Array.isArray(field.param['defaultItems'])) {
      items = field.param['defaultItems']
    }
    let simpleText = null
    if (Array.isArray(items)) {
      let groupsSet = {}
      for (let item of items) {
        if (item['@group'] && !groupsSet[item['@group']]) {
          groupsSet[item['@group']] = true
        }
      }
      let data = []
      if (!MC.isEmptyObject(groupsSet)) {
        for (let groupName in groupsSet) {
          let group = {}
          group.title = groupName
          group.items = []
          for (let item of items) {
            if (item['@group'] == groupName) {
              group.items.push(item)
            }
          }
          data.push(group)
        }
      } else {        
        data.push({title: null, items: items})
      }
      for (let group of data) {
        for (let item of group.items) {
          if (MC.isNull(item['@key'])) {
            continue
          }
          let icon = null
          if (!MC.isNull(item['@imageUrl'])) {
            let imageUrl = MC.rebaseUrl(MC.findRoot(field).componentName, item['@imageUrl'], field.flow.baseUrl)
            icon = <img src={imageUrl} className={item['@icon']}/>
          } else if (!MC.isNull(item['@icon'])) {
            icon = <i className={MC.buildIconClass(item['@icon'])}></i>
          }
          let title = item['@title'] || item['@key']
          if (item['@key'].toString() == defaultValue) {
            simpleText = title
          }
          if (!MC.getFieldParamValue(field.param, '@escapeHtml')) {
            title = <div dangerouslySetInnerHTML={{__html: MC.customHtml(title)}}></div>
          }
          options.push({value: item['@key'].toString(), text: title, content: (<React.Fragment>{icon}{title}</React.Fragment>), url: item['@url'], cssClass: item['@cssClass'], css: MC.styleObjectFromString(item['@css']), group: group.title, disabled: item['@enabled'] == false})
        }
      }
    }
    if (field.widget !== 'unitcombobox') {
      let values = MC.getFieldParamValue(field.param, 'value')
      values = MC.isNull(values) ? [] : MC.asArray(values)
      let allowAdditions = MC.getFieldParamBooleanValue(field.param, '@allowAdditions')
      let invalidValues = MC.getFieldParamValue(field.param, '@invalidValues')
      let checkedValues = []
      for (let add of values) {
        add = Value.castToScalar(Value.fromJson(add), 'string').value
        if (!options.find(o => o.value === add)) {
          if (!MC.isNull(add) && ['add', 'mark'].indexOf(invalidValues) >= 0 || allowAdditions) {
            options.unshift({value: add, text: add, content: add, invalid: invalidValues == 'mark'})
            checkedValues.push(add)
          }
        } else {
          checkedValues.push(add)
        }
      }
      if (checkedValues.length != values.length) {
        MC.putFieldParamValue(field.param, 'value', checkedValues.length == 0 ? null : checkedValues)
      }
    }
    return {options: options, simpleText: simpleText}
  }

  buildColorOptions(field, required, clearable) {
    let options = []
    if (!required && !clearable) {
      options.push({value: '', text: '', content: ( <div className="mnc label delete-color" style={{backgroundColor: 'transparent'}}></div>)})
    }
    let items = MC.getFieldParamValue(field.param, 'items')
    if (Array.isArray(items)) {
      for (let item of items) {
        let color = item['@color']
        if (MC.isNull(color)) {
          continue
        }
        options.push({value: color, text: color, content: ( <div className="mnc label" style={{backgroundColor: color}}></div>)})
      }
    }
    return {options: options}
  }

  focus = () => {
    let field = this.props.widget
    if (MC.isModelerActive(field)) {
      return
    }
    this.setState({focused: true})
    if (field.flow && field.flow.getCfgParameter('fl:validationStyle') == 'blur') {
      this.resetValidation()
      this.forceUpdate()
    }
    MC.handleEvent(this.props.widget, 'focus')
  }

  blur = () => {
    if (MC.isModelerActive(this.props.widget) || this.props.widget.flow.context.action.kind !== 'form') { // blur can be done later than form submit, so flow context data not exists
      return
    }
    this.setState({focused: false})
    if (this.props.widget.flow && this.props.widget.flow.getCfgParameter('fl:validationStyle') == 'blur') {
      this.props.widget.flow.focusedOnFirst = true // prevent focus on first invalid
      this.revalidate(true)
    } else {
      this.revalidate()
    }
    MC.handleEvent(this.props.widget, 'blur')
  }
  
  checkWarning = () => {
    var field = this.props.widget;
    MC.putFieldParamValue(field.param, "@invalid", false)
    MC.putFieldParamValue(field.param, "@invalidState", 'validChecked')
    MC.putFieldParamValue(field.param, "@invalidmessage", null)
    MC.updateInvalidSummary(field, true)
    this.forceUpdate()
  };

  resetValidation = () => {
    var field = this.props.widget;
    MC.putFieldParamValue(field.param, "@invalid", false)
    MC.putFieldParamValue(field.param, "@invalidState", null)
    MC.putFieldParamValue(field.param, "@invalidmessage", null)
    MC.updateInvalidSummary(field, true)
    this.forceUpdate()
  };

  static hasTextMode(field) {
    if (field.widget == 'radiogroup') {
      return !MC.isModelerActive(field)
    } else {
      return ['textbox', 'textarea', 'checkbox', 'combobox', 'datebox', 'passwordbox', 'colorpalette', 'whisperbox', 'unitbox', 'unitcombobox', 'slider', 'upload'].indexOf(field.widget) > -1
    }
  }  

  static getValue(field) {
    let val = MC.getFieldParamValue(field.param, 'value')
    if (val == null) {
      val = ""
    }
    let formatter = MC.getFieldParamValue(field.param, '@formatType')
    if (!MC.isNull(formatter) && ['label'].indexOf(field.widget) > -1) {
      if (!MC.isModelerActive(field) || (!MC.isNull(val) && val !== '')) {
        val = MC.formatValue(val, formatter, field.basictype, MC.getFieldParamValue(field.param, '@formatPattern'), {param: field.param.value, msgparam: field.msgparam, lang: field.flow.lang})
      } else if (MC.isModelerActive(field)) {
        val = MC.getFieldParamValue(field.param, '@formatPattern')
      }
    } else if (typeof val !== 'string' && !MC.isNull(val)) {
      val = Value.castToScalar(Value.fromJson(val), 'string').value
    }
    return val
  }  

  static getTextMode(field, value) {
    if (['slider', 'textbox', 'unitbox', 'unitcombobox'].indexOf(field.widget) >= 0 && 'number' == MC.getFieldParamValue(field.param, '@formatType')) {
      let decimalScale = Number(MC.getFieldParamValue(field.param, '@decimalScale'))
      if (MC.isNull(decimalScale)) {
        decimalScale = 2
      }
      const prefix = MC.getFieldParamValue(field.param, '@prefix')
      const suffix = MC.getFieldParamValue(field.param, '@suffix')
      value = <NumberFormat key="nm" value={value} decimalSeparator="," thousandSeparator=" " decimalScale={decimalScale} prefix={prefix || ''} suffix={suffix || ''} displayType="text"/>
    }
    let textValue = null
    if (field.widget == 'combobox' || field.widget == 'unitcombobox') {
      let items = MC.getFieldParamValue(field.param, 'items')
      if (!items && field.param['defaultItems'] && Array.isArray(field.param['defaultItems'])) {
        items = field.param['defaultItems']
      }
      let values = MC.asArray(MC.getFieldParamValue(field.param, 'value'))
      let res = []
      let unit = Value.castToScalar(Value.fromJson(MC.getFieldParamValue(field.param, '@unit')), 'string')
      let allowAdditions = MC.getFieldParamBooleanValue(field.param, '@allowAdditions')
      let invalidValues = MC.getFieldParamValue(field.param, '@invalidValues')
      for (let value of values) {
        value = Value.castToScalar(Value.fromJson(value), 'string').value
        let item = !MC.isNull(items) ? MC.asArray(items).find(i => i['@key'] === value || field.widget == 'unitcombobox' && i['@key'] === unit.value) : null
        let title
        if (item) {
          title = item['@title'] || item['@key']
          title = item['@url'] ? <a className={item['@cssClass']} href={item['@url']}>{title}</a> : <span className={item['@cssClass']}>{title}</span>
        } else if (!MC.isNull(value) && ['add', 'mark'].indexOf(invalidValues) >= 0 || allowAdditions) {
          title = <span className={invalidValues == 'mark' ? 'mnc red' : null}>{value}</span>
        }
        if (title) {
          res.push(<React.Fragment key={value + '-frg'}>{res.length > 0 ?  ', ' : ''}{title}</React.Fragment>)
        }
      }
      if (field.widget == 'unitcombobox') {
        textValue = MC.getFieldParamValue(field.param, '@unitPlacement') === 'left' ? <span key="ub">{res}&nbsp;{value}</span> : <span key="ub">{value}&nbsp;{res}</span>
      } else {
        textValue = res
      }
    } else if (field.widget == 'datebox') {
      let {dateValue, dateFormat, timeFormat} = MC.prepareDate(field, value, field.basictype)
      if (!dateValue.isValid && !MC.isNull(value)) { // invalid value is not conveted to luxon
        textValue = value
      } else {
        let format = field.basictype == 'time' ? timeFormat : field.basictype == 'date' ? dateFormat : dateFormat + ' ' + timeFormat
        textValue = dateValue.toFormat(format)
      }
    } else if (field.widget == 'passwordbox') {
      textValue = '******'
    } else if (field.widget == 'colorpalette') {  
      textValue = value ? <span className="mnc label" style={{backgroundColor: value}} key="color"></span> : null
    } else if (field.widget == 'checkbox') {
      value = Value.v(value || false, 'boolean')
      if (Value.isTrue(value)) {
        textValue = <i className="mnc icon check square outline" key="ic"/>
      } else if (Value.isFalse(value)) {
        textValue = <i className="mnc icon square outline" key="ic"/>
      }
    } else if (field.widget == 'whisperbox') {
      textValue = <WhisperBox key="ws" data={field} textMode={true}/>
    } else if (field.widget == 'radiogroup') {
      let radios = MC.getRadios(field)
      if (Array.isArray(radios)) {
        for (let radio of radios) {
          if (MC.getFieldParamBooleanValue(radio.param, 'value') || value === MC.getFieldParamValue(radio.param, 'value')) {
            textValue = MC.getFieldParamValue(radio.param, '@title')
            break
          }
        }  
      }
    } else if (field.widget == 'unitbox') {  
      let unit = MC.getFieldParamValue(field.param, '@unit')
      textValue = MC.getFieldParamValue(field.param, '@unitPlacement') === 'left' ? <span key="ub">{unit}&nbsp;{value}</span> : <span key="ub">{value}&nbsp;{unit}</span>
    } else if (field.widget == 'upload') { 
      textValue = <Upload field={field} key="up" textMode={true}/>
    } else {
      textValue = value
    }
    return MC.isNull(textValue) || textValue === '' ? '\u00A0' : textValue
  }

  handlePanelClick = (e) => {
    const field = this.props.widget
    const url = MC.getFieldParamValue(field.param, '@url')
    if (!MC.isNull(url)) {
      let target = MC.getFieldParamValue(field.param, '@target')
      if (['blank', 'parent', 'top'].indexOf(target) > -1) {
        return
      }
      field.flow.reactFlow().routeTo(e, url)
      return
    }
  }

  getParentRadioGorup = () => {
    let parent = this.props.widget.parent
    if (parent) {
      while ((!parent || parent.widget !== 'radiogroup') && parent.parent) {
        parent = parent.parent
      }
      if (parent && parent.widget === 'radiogroup') {
        return parent
      }
    }
    return false
  }

  render() {
    var widget;
    var field = this.props.widget;
    if (!MC.isModelerActive(this.props.widget)) {
      field.reactWidget = this
    }
    const transition = MC.getFieldParamValue(this.props.widget.param, '@transition')
    if (!this.state.visible && MC.isNull(transition)) {
      return null
    }
    let defaultValue = Widget.getValue(field)
    var formatter = MC.getFieldParamValue(field.param, '@formatType')
    let icon = MC.getFieldParamValue(field.param, '@icon')
    let iconPlacement = MC.getFieldParamValue(field.param, '@iconPlacement')
    var required = MC.getFieldParamBooleanValue(field.param, 'validation/@required')
    var labelStr = MC.getFieldParamValue(field.param, '@title') || '\u00a0'
    var titlePlacement = MC.getFieldParamValue(field.param, '@titlePlacement') || 'INV'
    var label = null
    if (typeof labelStr === 'object') {
      MC.error(`Parameter @title of field "${field.id}" must be a simple value. Value: ${JSON.stringify(labelStr)}`)
    }
    let inputWidth
    if (titlePlacement !== 'INV') {
      if (titlePlacement == 'PI') {
        label = <h3 className="mnc header editable" key="h3">{MC.iconize(field, <div className="content">{labelStr}</div>)}</h3>
      } else {
        let inlineCss = {};
        if (['L', 'LL'].indexOf(titlePlacement) > -1) {
          if (titlePlacement == 'L') {
            inlineCss.textAlign = 'right';
          } else {
            inlineCss.textAlign = 'left';
          }
          var tWidth = MC.getFieldParamValue(field.param, '@titleWidth')
          if (!MC.isNull(tWidth) && MC.isNumeric(tWidth)) {
            inlineCss.width = tWidth + '%'
            inputWidth =  'calc(' + (100 - tWidth) + '% - 10px)'
          }
        }
        var requiredStar = '';
        var escapeHtml = MC.getFieldParamValue(field.param, '@escapeTitleHtml')
        if (escapeHtml) {
          if (required) {
            requiredStar = <span className="rstar">*</span>;
          }
          label = <label className="editable" style={inlineCss} htmlFor={MC.htmlId(field)} key="wlabel">{labelStr}{requiredStar}</label>;
        } else {
          if (required) {
            requiredStar = '<span class="rstar">*</span>';
          }
          label = <label className="editable" style={inlineCss} htmlFor={MC.htmlId(field)} key="wlabel" dangerouslySetInnerHTML={{__html: MC.customHtml(labelStr + requiredStar)}}/>;
        }
      }
      if (MC.isModelerActive(field)) {
        label = <EditableLabel field={field} widget={label} key="EditableLabel" path={["param", "@title"]} nospan={true}/>;
      }
    }
    var readOnly = MC.getFieldParamBooleanValue(field.param, '@readonly')
    if (!readOnly && this.props.readOnly) {
      readOnly = true;
    }
    let disabled = false
    if (MC.getFieldParamValue(field.param, '@enabled') == false || MC.getFieldParamValue(field.param, '@permitted') == false) {
      disabled = true
    }
    if (!disabled && this.props.disabled) {
      disabled = true
    }
    if (['slider'].indexOf(field.widget) > -1 && readOnly) { // fallback for widgets not having readonly mode
      disabled = true
    }
    if (MC.isModelerInEyeMode(field)) {
      disabled = false
    }
    let textMode = MC.getFieldParamBooleanValue(field.param, '@textmode')
    if (!textMode && this.props.textMode) {
      textMode = true
    }
    var halign = MC.getFieldParamValue(field.param, '@horizontalAlignment')
    var maxlength = MC.getFieldParamValue(field.param, 'validation/@maxLength')
    var placeholder = MC.getFieldParamValue(field.param, '@placeholder')
    var help = MC.getFieldParamValue(field.param, '@help')
    var helpDisplay = MC.getFieldParamValue(field.param, '@helpDisplay')
    var invalid = MC.getFieldParamBooleanValue(field.param, '@invalid')
    var invalidState = MC.getFieldParamValue(field.param, '@invalidState')
    var helpCls = 'help';
    var helpCheckbox = null;
    if (invalid) {
      var errMsg = MC.getFieldParamValue(field.param, 'validation/@title')
      if (!MC.isNull(errMsg) && errMsg !== '') {
        help = errMsg;
      } else {
        var invalidMsg = MC.getFieldParamValue(field.param, '@invalidmessage')
        if (!MC.isNull(invalidMsg) && invalidMsg !== '') {
          help = invalidMsg;
        }
      }
      if (invalidState == 'warning') {
        helpCls = 'warning';
        helpCheckbox = <Checkbox key="warning-checkbox" onChange={this.checkWarning}/>;
      } else {
        helpCls = 'error';
      }
    }
    helpDisplay = helpDisplay || 'conditional'
    if ((MC.isNull(help) || help == '') && !MC.isModelerInStructuralMode(field) && helpDisplay == 'yes') {
      help = '\u00a0'
    }
    let errLabel = null
    if (helpDisplay == 'yes' || helpDisplay == 'conditional') {
      if (helpDisplay == 'conditional' && MC.isNull(help) || help === '') {
        errLabel = <label className="help thin" key="error"/>
      } else {
        errLabel = <label className={helpCls} key="error" onClick={(e) => {e.preventDefault()}}>{helpCheckbox}<span className="text">{help}</span></label>
      }
    }
    let cssclass = Value.v(MC.getFieldParamValue(field.param, '@cssClassField'), 'string').value
    let cssin = Value.v(MC.getFieldParamValue(field.param, '@cssClass'), 'string').value
    let css = MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))
    let cssField = MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@cssField'))
    var collapsible = MC.getFieldParamBooleanValue(field.param, '@collapsible')
    if (textMode && Widget.hasTextMode(field)) {
      widget = [Widget.getTextMode(field, defaultValue), errLabel]
    } else {
      switch (field.widget) {
        case 'textbox':
        case 'unitbox':
        case 'unitcombobox':
          const disableFormatOnEdit = MC.getFieldParamBooleanValue(field.param, '@disableFormatOnEdit') // for numeric keyboard on mobile phone
          let decimalScale = Number(MC.getFieldParamValue(field.param, '@decimalScale'))
          if (MC.isNull(decimalScale)) {
            decimalScale = 2
          }
          let mask = MC.getFieldParamValue(field.param, '@formatPattern')  
          if (formatter == 'number' && (!disableFormatOnEdit || !this.state.focused)) {
            const prefix = MC.getFieldParamValue(field.param, '@prefix')
            const suffix = MC.getFieldParamValue(field.param, '@suffix')
            widget = <NumberFormat placeholder={placeholder} value={defaultValue} readOnly={readOnly} disabled={disabled} key="input" 
                      maxLength={maxlength} onFocus={this.focus} onBlur={this.blur} data-widget-i-name={field.id} className={cssin} style={css} prefix={prefix || ''} suffix={suffix || ''} 
                      decimalSeparator="," thousandSeparator=" " decimalScale={decimalScale} onValueChange={this.handleTextChange}/>
          } else if (formatter == 'mask' && mask && !MC.isModelerActive(field)) {
            widget = <MaskedInput mask={mask} id={MC.htmlId(field)} placeholder={placeholder} value={defaultValue} readOnly={readOnly} disabled={disabled} field={field}
                                  maxLength={maxlength} onChange={this.handleTextChange} key="input" onFocus={this.focus} onBlur={this.blur} data-widget-i-name={field.id} className={cssin} style={css}/>
          } else {
            const type = ['integer', 'decimal'].indexOf(field.basictype) > -1 ? 'number' : 'text';
            const maxValue = type === 'number' ? MC.getFieldParamValue(field.param, 'validation/@maxValue') : null
            const minValue = type === 'number' ? MC.getFieldParamValue(field.param, 'validation/@minValue') : null
            const step = 'decimal' == field.basictype ? (1/(Math.pow(10, decimalScale))).toString() : '1'
            let onKeyDown = null
            if ('integer' == field.basictype) {
              onKeyDown = function(e) {!['0','1','2','3','4','5','6','7','8','9','-','Backspace','Tab'].includes(e.key) && e.preventDefault()}
            }
            if ('decimal' == field.basictype) {
              onKeyDown = function(e) {!['0','1','2','3','4','5','6','7','8','9','-','.',',','Backspace','Tab'].includes(e.key) && e.preventDefault()}
            }
            widget = <input type={type} step={step} id={MC.htmlId(field)} ref={this.widgetRef} placeholder={placeholder} value={defaultValue} readOnly={readOnly} disabled={disabled}
                            maxLength={maxlength} onChange={this.handleTextChange} key="input" onFocus={this.focus} onBlur={this.blur} data-widget-i-name={field.id} className={cssin}
                            style={css} min={minValue} max={maxValue} onKeyDown={onKeyDown}/>
          }
          if (icon) {
            widget = <div className={MC.classes("mnc", {"left": iconPlacement !== 'right'}, "icon input")} key="inwrap">{widget}<i key="icon" className={MC.buildIconClass(icon)}></i></div>
          }
          if (field.widget === 'unitbox') {
            var unit = MC.getFieldParamValue(field.param, '@unit')
            var placement = MC.getFieldParamValue(field.param, '@unitPlacement')
            if (placement === 'left') {
              widget = [<div className="mnc labeled input" key="input"><div className="mnc label">{unit}</div>{widget}</div>, errLabel];
            } else {
              widget = [<div className="mnc right labeled input" key="input">{widget}<div className="mnc label">{unit}</div></div>, errLabel];
            }
          } else if (field.widget === 'unitcombobox') {
            var unit = MC.getFieldParamValue(field.param, '@unit')
            var placement = MC.getFieldParamValue(field.param, '@unitPlacement')
            const clearable = MC.getFieldParamBooleanValue(field.param, '@clearable')
            let options = this.buildOptions(field, required, unit, clearable)
            if (placement === 'left') {
              widget = ([
                <div className="mnc labeled input" key="field">
                  <Dropdown className="label" value={unit} options={options.options} onFocus={this.focus} onBlur={this.blur} field={field} onChange={this.handleTextChange} key="dropdown" disabled={disabled} clearable={clearable} />
                  {widget}
                </div>,
                errLabel,
                <label className="error" key="error2" style={{display: 'none'}}/>
              ]);
            } else {
              widget = ([
                <div className="mnc right labeled input" key="field">
                  {widget}
                  <Dropdown className="label" value={unit} options={options.options} onFocus={this.focus} onBlur={this.blur} field={field} onChange={this.handleTextChange} key="dropdown" disabled={disabled} clearable={clearable} />
                </div>,
                errLabel,
                <label className="error" key="error2" style={{display: 'none'}}/>
              ]);
            }
          } else {
            widget = [widget, errLabel];
          }
          break;
        case 'datebox':
          widget = [<Datebox key="dtbox" value={defaultValue} field={field} disabled={disabled} readonly={readOnly} placeholder={placeholder} widgetRef={this.widgetRef} 
                             onChange={this.handleDateChange} onFocus={this.focus} onBlur={this.blur} basicType={field.basictype}/>, errLabel]
          break;
        case 'passwordbox':
          let autocomplete = 'on'
          if (MC.isModelerActive(field)) {
            autocomplete = 'off'
          }
          widget = <input type="password" id={MC.htmlId(field)} ref={this.widgetRef} placeholder={placeholder} value={defaultValue} readOnly={readOnly}
                          disabled={disabled} onChange={this.handleTextChange} key="input" onFocus={this.focus} onBlur={this.blur} autoComplete={autocomplete} data-widget-i-name={field.id}/>
          if (icon) {
            widget = <div className={MC.classes("mnc", {"left": iconPlacement !== 'right'}, "icon input")} key="inwrap">{widget}<i key="icon" className={MC.buildIconClass(icon)}></i></div>
          }
          widget = [widget, errLabel]
          break;
        case 'textarea':
          var rows = MC.getFieldParamValue(field.param, '@rows')
          if (MC.isNull(rows) || !MC.isNumeric(rows)) {
            rows = null;
          }
          var resize = MC.getFieldParamBooleanValue(field.param, '@resize')
          var txtStyle = {};
          if (!resize) {
            txtStyle.resize = 'none';
          }
          if (MC.getFieldParamBooleanValue(field.param, '@nowrap')) {
            cssin = MC.classes(cssin, 'nowrap')
          }
          widget = <textarea type="text" id={MC.htmlId(field)} ref={this.widgetRef} placeholder={placeholder} value={defaultValue} readOnly={readOnly} onFocus={this.focus} onBlur={this.blur}
                             disabled={disabled} maxLength={maxlength} onChange={this.handleTextChange} key="input" rows={rows} style={{...css, ...txtStyle}} data-widget-i-name={field.id} className={cssin}/>
          widget = [widget, errLabel];
          break;
        case 'upload':
          widget = <Upload field={field} widget={this} key="input" placeholder={placeholder} readOnly={readOnly} disabled={disabled}/>
          widget = [widget, errLabel];
          break;
        case 'checkbox':
          const checked = defaultValue == true || defaultValue == 'true'
          if (!checked && defaultValue !== false && defaultValue !== 'false') {
            MC.putFieldParamValue(field.param, 'value', false)
          }
          if (typeof cssclass == 'string' && cssclass.indexOf("toggle") > -1) {
            cssin += " toggle"
          }
          let inLabel = <label key="chlabel">&nbsp;</label>
          if (titlePlacement == 'R') {
            inLabel = label
            label = ''
          }
          widget = [<Checkbox key="input" label={inLabel} checked={checked} field={field} onChange={this.handleTextChange} className={cssin} style={css} disabled={disabled} readOnly={readOnly}/>, errLabel]
          break;
        case 'radiobutton':
          let parent = this.getParentRadioGorup()
          let asButtons = parent && MC.getFieldParamBooleanValue(parent.param, '@asButtons')
          let inLabelR = <label key="chlabel">&nbsp;</label>
          if (titlePlacement == 'R' || asButtons) {
            inLabelR = label
            label = ''
          }
          let selected = defaultValue == true || defaultValue == 'true' || defaultValue === MC.getFieldParamValue(parent.param, 'value')
          widget = [<Checkbox radio key="input" name={parent && parent.rbsid ? parent.rbsid : null} checked={selected} field={field} 
                      onChange={this.handleTextChange} onClick={this.onRadioClick} className={cssin} style={css} disabled={disabled} readOnly={readOnly} label={inLabelR} id={MC.htmlId(field)}/>, errLabel]
          break;
        case 'label':
          widget = <Label field={field} value={defaultValue} key="label"/>
          if (MC.isModelerActive(field)) {
            widget = <EditableLabel key="editableLabel" field={field} widget={widget} path={["param", titlePlacement == "IN" ? "@title" : "value"]}/>;
          }
          widget = [widget, errLabel];
          break;
        case 'button':
          widget = [<Button button={field} widgetRef={this.widgetRef} value={defaultValue} disabled={disabled} key="button"/>, errLabel]
          if (MC.isModelerActive(field)) {
            widget = <EditableLabel key="editableLabel" field={field} widget={widget} path={["param", titlePlacement == "IN" ? "@title" : "value"]}/>;
          }
          break;
        case 'link':
          widget = <Link data={field} widgetRef={this.widgetRef} value={defaultValue} disabled={disabled} key="link"/>
          widget = [widget, errLabel];
          if (MC.isModelerActive(field)) {
            widget = <EditableLabel key="editableLabel" field={field} widget={widget} path={["param", titlePlacement == "IN" ? "@title" : "value"]}/>;
          }
          break;
        case 'combobox':
        case 'colorpalette':
          const	allowAdditions = MC.getFieldParamBooleanValue(field.param, '@allowAdditions')	
          const whisper = MC.getFieldParamBooleanValue(field.param, '@whisper') || allowAdditions
          const clearable = MC.getFieldParamBooleanValue(field.param, '@clearable')
          let options = field.widget === 'colorpalette' ? this.buildColorOptions(field, required, clearable) : this.buildOptions(field, required, defaultValue, clearable)
          let disabledCmb = false
          if (!MC.isModelerInEyeMode(field) && (disabled || readOnly)) {
            disabledCmb = true
          }
          let multiple = false
          let values = defaultValue
          if (MC.getFieldParamBooleanValue(field.param, '@multiple')) {
            values = MC.getFieldParamValue(field.param, 'value')
            if (MC.isNull(values)) {
              values = []
            }
            multiple = true
          } else {
            if (!MC.isNull(options.simpleText)) {
              MC.putFieldParamValue(field.param, 'text', options.simpleText)
            }
          }
          let cls = MC.classes('fluid selection', {'colorpalette': field.widget === 'colorpalette'})
          let onSearch = MC.getFieldParamBooleanValue(field.param, '@customSearch') ? this.handleSearchChange : null
          widget = [<Dropdown className={cls} multiple={multiple} key="dropdown" onChange={this.handleTextChange} search={whisper} value={values} options={options.options} field={field}
                      disabled={disabledCmb} onFocus={this.focus} onBlur={this.blur} placeholder={placeholder} allowAdditions={allowAdditions} clearable={clearable} onSearch={onSearch} widgetRef={this.widgetRef} />, errLabel]
          break
        case 'panel':
        case 'radiogroup':
          if (field.widget == 'panel' && field.scriptedWidget) {
            widget =  <div className="mnc twelve wide column" dangerouslySetInnerHTML={{__html: field.scriptedWidget.html}} ref={this.widgetRef}/>
          } else {
            if (field.widget == 'radiogroup' && defaultValue === '') {
              let radios = MC.getRadios(field)
              if (Array.isArray(radios)) {
                for (let radio of radios) {
                  if (MC.getFieldParamBooleanValue(radio.param, 'value')) {
                    MC.putFieldParamValue(field.param, 'value', true)
                    break;
                  }
                }  
              }
            }
            let labelToSend = field.widget == 'panel' && titlePlacement == 'PI' && !collapsible ? label : null
            widget = this.buildSubFields(field, disabled, readOnly, labelToSend, textMode)
          }
          if (field.widget == 'radiogroup') {
            widget = [widget, errLabel]
          }
          break;
        case 'hbox':
          widget = this.buildHbox(field, disabled, readOnly)
          break;
        case 'vbox':
          widget = this.buildVbox(field, disabled, readOnly)
          break;
        case 'tabpanel':
          widget = <TabPanel key="tabpanel" data={field} disabled={disabled} readOnly={readOnly} resolution={this.props.resolution}/>;
          break;
        case 'repeater':
          widget = <Repeater key="repeater" data={field} disabled={disabled} readOnly={readOnly} textMode={textMode} resolution={this.props.resolution}/>
          break;
        case 'table':  
          widget = <Table key="table" data={field} disabled={disabled} readOnly={readOnly} textMode={textMode} resolution={this.props.resolution}/>
          break;
        case 'download':
          if (MC.isModelerActive(field)) {
            widget = <Dummy key="widget" field={field}/>
          } else {
            widget = <Download key="download" data={field}/>
          }
          label = null
          break;
        case 'imageviewer':
        case 'defaultviewer':
        case 'pdfviewer':
        case 'videoviewer':  
          widget = [<Media key="media" data={field} halign={halign}/>, errLabel]
          break;
        case 'icon':
            if (icon) {
              widget = <i key="icon" className={icon + ' icon'} id={MC.htmlId(field)} ref={this.widgetRef} data-widget-i-name={field.id}/>
            } else if (MC.isModelerActive) {
              widget = <Dummy key="widget" field={field}/>
            }
          break;
        case 'embeddeddialog':
          if (MC.isModelerActive(field)) {
            widget = <Dummy key="widget" field={field}/>;
          } else {
            widget = <EmbeddedDialog key="dialog" data={field} formExecutionId={field.flow.formExecutionId}/>
          }
          break;
        case 'camera':
          widget = <Camera key="camera" data={field} help={help} helpCls={helpCls}/>
          break;
        case 'slider':
          widget = [<Slider key="slider" data={field} onFocus={this.focus} onBlur={this.blur} focused={this.state.focused} widget={this}/>, errLabel]
          break;
        case 'whisperbox':
          widget = [<WhisperBox key="slider" data={field} onFocus={this.focus} onBlur={this.blur} placeholder={placeholder} widget={this} readOnly={readOnly} disabled={disabled} widgetRef={this.widgetRef}/>, errLabel]
          break;
        case 'dynamicPanel':
          widget = this.buildSubFields(field, disabled, readOnly, null, textMode)
          break;
        case 'menu':
          widget = [<Menu key="menu" data={field}/>, errLabel]
          break;
        case 'breadcrumb':
          widget = [<Breadcrumb key="breadcrumb" data={field}/>, errLabel]
          break;  
        case 'carousel':
          const autoplay = MC.getFieldParamBooleanValue(field.param, '@autoplay')
          let autoplayInterval = MC.getFieldParamValue(field.param, '@autoplayInterval')
          if (MC.isNumeric(autoplayInterval)) {
            autoplayInterval = autoplayInterval * 1000
          } else {
            autoplayInterval = 3000
          }
          const wrapAround = MC.getFieldParamBooleanValue(field.param, '@wrapAround')
          widget = <Carousel key="carousel" data={field} autoplay={autoplay} autoplayInterval={autoplayInterval} wrapAround={wrapAround}/>
          break;
        case 'objectinspector':
          const name = MC.getFieldParamValue(field.param, '@name')
          const data = MC.getFieldParamValue(field.param, '@value')
          widget = <ObjectInspector key="obi" data={data} name={name}/> 
          break
        case 'iframe':
          widget = <Iframe field={field} className={cssin} style={css} key="ifr"/> 
          break  
        case 'divider':
          let DivEl = 'div'
          let headerType =  MC.getFieldParamValue(field.param, '@headerType') 
          if (headerType) {
            DivEl = headerType.toLowerCase()
          }
          let vertical = MC.getFieldParamBooleanValue(field.param, '@vertical')
          let hidden = MC.getFieldParamBooleanValue(field.param, '@hiddenLine')
          label = titlePlacement == 'PI' ? null : label
          widget = <DivEl key="dvd" className={MC.classes('mnc', {'horizontal': titlePlacement == 'PI' && !vertical, 'vertical': vertical, 'hidden': hidden}, 'divider', {'header': headerType}, cssin)} style={css} data-widget-i-name={field.id}>{titlePlacement == 'PI' ? MC.iconize(field, labelStr) : null}</DivEl>
          break
        case 'pagination':
          widget = <Paginator field={field} key="pag"/>
          break
        case 'steps':
            widget = <Steps field={field} key="stp"/>
            break  
        default:
          let CustomComponent = MC.getReactRomponent(field.widget)
          let extendedProps = {}
          if ('piechart' == field.widget) {
            extendedProps.type = 'pie'
            CustomComponent = MC.getReactRomponent('chart')
          }
          if ('chart' == field.widget) {
            extendedProps.type = 'basic'
          }
          if (CustomComponent) {
            widget = <CustomComponent key={field.rbsid} data={field} placeholder={placeholder} readOnly={readOnly} disabled={disabled} 
                        value={defaultValue} handleTextChange={this.handleTextChange} {...extendedProps}/>
          } else {
            if (MC.isModelerActive(field)) {
              widget = this.buildSubFields(field, disabled, readOnly, null, textMode)
            } else {
              widget = <span key="unrecognized">Unsupported, or not loaded widget: {field.widget}</span>
            }
          }
          break;
      }
    }
    let valign = MC.getFieldParamValue(field.param, '@verticalAlignment')
    valign = ['top', 'middle', 'bottom'].indexOf(valign) >= 0 ? valign + " aligned" : ""
    let wide = MC.isInTable(field) || field.parent && ['hbox', 'vbox'].indexOf(field.parent.widget) > -1 ? '' : this.state.wideClass
    if (field.widget == "repeater" && MC.getFieldParamBooleanValue(field.param, '@inline')) {
      wide = 'twelve'
    }
    cssclass = MC.classes('mnc', wide, "wide column field", cssclass,  {'textmode': textMode, 'inline': ['L', 'LL'].indexOf(titlePlacement) > -1, 
                 'disabled': !MC.isModelerInEyeMode(field) && disabled, 'focused' : this.state.focused}, valign, "widget widget-" + field.widget)
    if (invalid) {
      if (invalidState == 'warning') {
        cssclass = MC.classes(cssclass, 'warning')
      } else {
        cssclass = MC.classes(cssclass, 'error')
      }
    } else {
      if (invalidState == 'valid' || invalidState == 'validChecked') {
        cssclass = MC.classes(cssclass, 'valid')
      }
    }
    if (!MC.isNull(defaultValue) && defaultValue !== '' || field.widget === 'upload') {
      if (["checkbox", "radiogroup", "radiobutton"].indexOf(field.widget) < 0) {
        cssclass = MC.classes(cssclass, 'valued')
      }
    }
    let inlineCss = cssField
    if (this.state.visible === false) {
      inlineCss.display = 'none'
    }
    if (['center', 'left', 'right'].indexOf(halign) > -1) {
      inlineCss.textAlign = halign
    }
    let collapsed = MC.getFieldParamBooleanValue(field.param, '@collapsed')
    let resField;
    let inputWrapperInlineCss = {}
    if (inputWidth) {
      inputWrapperInlineCss.width = inputWidth
    }
  	if (["radiogroup"].indexOf(field.widget) > -1 && (!textMode || MC.isModelerActive(field))) {
      widget = [label, <div key="kd" className={MC.classes("mnc twelve column grid", {"mnc buttons" : MC.getFieldParamBooleanValue(field.param, '@asButtons')})} style={inputWrapperInlineCss} data-widget-i-name={field.id}>{widget}</div>]
      resField = <Field key="field" widgetRootRef={this.widgetRootRef} field={field} className={cssclass} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>
    } else if (['panel', 'dynamicPanel'].indexOf(field.widget) > -1) {
      label = field.widget == 'panel' && titlePlacement == 'PI' ? null : label
      let href = MC.getFieldParamValue(field.param, '@url')
      let PanelEl =  'div'
      let target = null
      let panelClick = null
      if (href) {
        PanelEl =  'a'
        panelClick = this.handlePanelClick
        target = MC.getFieldParamValue(field.param, '@target')
        if (['blank', 'parent', 'top'].indexOf(target) > -1) {
          target = '_' + target
        }
      }
      if (collapsible) {
        let activeIndex = collapsed ? -1 : 0
        let panel = [{
          key: 'panel',
          title: <span className="mnc header">{labelStr}</span>,
          content: <PanelEl key="kd" className={MC.classes("mnc twelve column grid widgetContainer", cssin)} style={css} data-widget-i-name={field.id} onClick={panelClick} target={target} href={href}>{widget}</PanelEl>
        }]
        widget = <Accordion fluid panels={panel} onTitleClick={this.panelOpenClose} activeIndex={activeIndex}/>
      } else {
        widget = [label, <PanelEl key="kd" className={MC.classes("mnc twelve column grid widgetContainer", cssin)} style={{...inputWrapperInlineCss, ...css}} data-widget-i-name={field.id} 
                   onClick={panelClick} target={target} href={href}>{widget}</PanelEl>]
      }
      resField = <Field key="field" widgetRootRef={this.widgetRootRef} field={field} className={cssclass} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>
    } else if (["hbox"].indexOf(field.widget) > -1) {
      let className = MC.classes('hbox flex-row widget-hbox', {'flex-center': halign == 'center', 'flex-right': halign == 'right'}, cssin)
      widget = [label, <div  key="kd" className={className} style={{...inlineCss, ...inputWrapperInlineCss, ...css}} data-widget-i-name={field.id}>{widget}</div>];
      resField = <Field key="field" widgetRootRef={this.widgetRootRef} field={field} className={cssclass} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
    } else if (["vbox"].indexOf(field.widget) > -1) {
      if (MC.getFieldParamBooleanValue(field.param, '@fitWidth')) {
        inlineCss['height'] = '100%';
      }
      widget = [label, <div  key="kd" className="vbox flex-column widget-vbox" style={{...inlineCss, ...inputWrapperInlineCss}} data-widget-i-name={field.id}>{widget}</div>];
      resField = <Field key="field" widgetRootRef={this.widgetRootRef} field={field} className={cssclass} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
    } else if (field.widget == "repeater") {
      if (collapsible) {
        let activeIndex = collapsed ? -1 : 0
        let panel = [{key: 'panel', title: <span className="mnc header">{labelStr}</span>, content:  widget}]
        widget =  <Accordion panels={panel} onTitleClick={this.panelOpenClose} activeIndex={activeIndex}/>
        resField = <Field key="field" widgetRootRef={this.widgetRootRef} field={field} className={cssclass} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      } else {
        if (inputWidth) {
          widget = <div style={inputWrapperInlineCss}>{widget}</div>
        }
        widget = [label, widget];
        resField = <Field key="field" widgetRootRef={this.widgetRootRef} field={field} className={cssclass} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      }
    } else {
      let cls = cssclass
      if (field.widget == 'checkbox') {
        if (MC.isModelerActive(field)) {
          cls += " read-only";
        }
      } else if (field.widget == 'radiobutton') {
        let parent = this.getParentRadioGorup()
        let radios = MC.asArray(MC.getRadios(parent))
        let asButtons = parent && MC.getFieldParamBooleanValue(parent.param, '@asButtons')
        cls = MC.classes(cls, {"mnc button": asButtons, "active": asButtons && (defaultValue == true || defaultValue == 'true'), "read-only": MC.isModelerActive(field), 
        "checked": defaultValue == true || defaultValue == 'true', 'first-left': asButtons && field === radios[0], 'last-right': asButtons && field === radios[radios.length - 1]})
      } else if (field.widget == 'upload') {
        cls += " upload";
      }
      if (inputWidth) {
        widget = <div style={inputWrapperInlineCss} key="inwrap">{widget}</div>
      }
      if (MC.isModelerActive(field) && Array.isArray(field.fields) && field.fields.length > 0 && field.widget !== 'tabpanel') { // add grid to unknown widgets with children
        widget = <div key="kd" className="mnc twelve column grid widgetContainer" data-widget-i-name={field.id}>{widget}</div>
      }
      widget = [label, widget]
      resField = <Field key="field" widgetRootRef={this.widgetRootRef} field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
    }
    if (this.props.wrappingDiv) {
      resField = <div className={this.props.wrappingDivClass}>{resField}</div>
    }
    let toReturn = null
    if (this.state.visible && this.props.offsetDiv) {
      toReturn = [this.props.offsetDiv, resField]
    } else {
      toReturn = resField
    }
    if (MC.isNull(transition)) {
      return toReturn
    } else {
      return <Transition visible={this.state.visible} animation={transition} duration={1000}>{toReturn}</Transition>
    }
  }

}

export {Widget}