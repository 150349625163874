import React from 'react'
import NodeGroup from './NodeGroup.jsx'

function numeric(beg, end) {
  const a = +beg
  const b = +end - a
  return function(t) {
    return a + b * t
  } 
}

class Animate extends React.Component {
  render() {
    const {start, update, children} = this.props
    return (
      <NodeGroup data={[start]} start={() => start} keyAccessor={() => '$$key$$'} interpolation={numeric} update={update}>
        {nodes => {
          if (!nodes[0]) {
            return null
          }
          const renderedChildren = children(nodes[0].state)
          return renderedChildren && React.Children.only(renderedChildren)
        }}
      </NodeGroup>
    )
  }
}

export default Animate