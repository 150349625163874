export const GetText = {
  localMessages: {
    cs: {
      "Search...": "Hledat...",
      "Save": "Uložit",
      "with grid": "s gridem",
      "Create grid": "Vytvořit grid",
      "Delete grid": "Odstranit grid",
      "Controls": "Ovládání",
      "Cut": "Vyjmout",
      "Copy": "Kopírovat",
      "Paste": "Vložit",
      "Undo": "Odvolat akci",
      "Redo": "Opakovat akci",
      "Toggle end of line": "Přepínání konce řádku",
      "Select All": "Vybrat vše",
      "Delete": "Odstranit",
      "Delete field": "Odstranit pole",
      "Select field": "Výběr pole",
      "Show palette": "Zobrazit paletu",
      "Change offset": "Změnit offset",
      "Double click": "Dvoj klik",
      "Change the name or title": "Změnit název nebo jméno",
      "Context menu": "Kontextové menu",
      "Toggle structural view": "Přepnout strukturální pohled",
      "Toggle display of invisible fields": "Přepnout zobrazení neviditelných polí",
      "Edit in repository": "Editovat v repository",
      "Basic": "Základní",
      "Column": "Sloupec",
      "Others": "Ostatní",
      "Field identification name unique within one form. The name should be without spaces or special characters.": "Identifikační název pole jedinečný v rámci jednoho formuláře. Název by měl být bez mezer nebo speciálních znaků.",
      "Select the widget used for this field here. The behavior and rendering of the field change based on the selected widget.": "Zde vyberte widget použitý pro toto pole. Chování a vykreslování pole se mění na základě vybraného widgetu.",
      "For some widgets that support multiple data types, the behavior may vary based on the selected data type.": "U některých widgetů, které podporují více datových typů, se chování může lišit podle vybraného datového typu.",
      "The number of columns that the field occupies.": "Počet sloupců, které pole zabírá.",
      "The number of columns that take up free space to the left of the field.": "Počet sloupců, které zabírá volné místo nalevo od pole.",
      "The order of the field within the parent field, such as the panel.": "Pořadí pole v nadřazeném poli, například v panelu.",
      "Specifies whether the field is always placed at the beginning of a line. Value <code>never</code> is not interpreted.": "Určuje, zda je pole vždy umístěno na začátku řádku. Hodnota <code>never</code> není interpretována.",
      "Specifies whether the field always ends a line. Value <code>never</code> is not interpreted.": "Určuje, zda pole vždy končí řádek. Hodnota <code>never</code> není interpretována.",
      "Specifies whether the field is rendered at that resolution.": "Určuje, zda je pole vykresleno v tomto rozlišení.",
      "Duplicate name at the same level!": "Duplikátní jméno na stejné úrovni!",
      "Empty name!": "Prázdné jméno!",
      "indeterminate": "nerozhodnuto",
      "The name must not contain illegal character!": "Jméno nesmí obsahovat nepovolené znaky!",
      "The name must not begin with a number!": "Jméno nesmí začínat číslem!"
    }
  },
  lang: null,
  setLang: function(l) {
    this.lang = l
  },
  doParams: function(text, params) {
    if (params == undefined) {
      return text
    }
    for (let i=0; i<params.length; i++) {
      text = text.replace(new RegExp("\\{" + i + "\\}", "g"), params[i])
    }
    return text
  },
  t: function(text, ...params) {
    let messages = this.localMessages[this.lang]
    if (!this.lang || !messages || !messages[text]) {
      return this.doParams(text, params)
    } else {
      return this.doParams(messages[text], params)
    }
  }
}