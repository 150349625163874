import React from "react"

import {MC} from "../client/MC.js"
import {Widget} from '../client/Widget.jsx'
import {FieldDef} from "./FieldDef.js"
import {WidgetModel} from "./WidgetModel.js"
import {GetText} from "./GetText.js"

class Palette extends React.Component {

  state = {search: ""}
  searchInputRef = React.createRef()

  handleSearchChange = (event) => {
    this.setState({search: event.target.value})
  }

  handleClick = (event) => {
    event.preventDefault()
  }

  componentDidMount() {
    if (!this.props.widgetModelLoaded) {
      return
    }
    this.searchInputRef.current.focus()
  }

  render() {
    if (!this.props.widgetModelLoaded) {
      return <div><div className="mnc loader"></div></div>
    } else {
      let labelField = {"widget":"label", "grid": [{"columns":"12","index":"1","offset":"0","resolution":"x-small","visible":"true","newLineAfter":"never"}], "fields": [], "param": {"@titlePlacement": "UL", "@helpDisplay": "none"}}
      let fields = []
      let fieldsPalette = WidgetModel.getFieldsPalette()
      let fieldsDef = fieldsPalette.filter(field => field.param["@title"].toUpperCase().includes(this.state.search.toUpperCase()))
      for (let field of fieldsDef) {
        let displayedField = MC.extend({}, labelField)
        displayedField.id = field.id
        displayedField.param["@title"] = field.param["@title"]
        if (-1 != ["repeater", "table"].indexOf(field.widget)) {
          field.fields = [{id: "rows*", fields: [], flow: this.props.flow, rbsid: "dummy-rows", widget: "dummy", param: {}}]
        }
        field.flow = this.props.flow
        displayedField.flow = this.props.flow
        displayedField.isInPalette = true
        FieldDef.setProto(displayedField)
        fields.push(<div key={field.widget} style={{margin: '0px', paddingTop: '0px', width: '200px', paddingBottom: '2px', boxShadow: 'none', border: 'none', borderRadius: 0}}><Widget widget={displayedField} resolution="x-small"/></div>)
      }
      return <div style={{top: this.props.position.y, left: this.props.position.x, position: 'absolute', width: '808px', zIndex: 100000, background: '#FFF', border: '1px solid rgba(34,36,38,.15)'}} onClick={this.handleClick}>
              <div style={{borderBottom: '1px solid rgb(230, 230, 230)', display: 'flex'}}>
                <div className="mnc icon input" style={{padding: '5px', flexGrow: 1}}>
                  <input ref={this.searchInputRef} name="widgetsearch" type="text" placeholder={GetText.t("Search...")} onChange={this.handleSearchChange}/>
                    <i className="search icon"/>
                  </div>
                </div>
                <div style={{overflowY: 'hidden', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', height: '540px', padding: '5px', flexGrow: 1}}>
                  {fields}
                </div>
            </div>
      }
  }
}

export {Palette}