import React from "react"

import {MC} from "../client/MC.js"

class Dummy extends React.Component {

  render() {
    return <span className={MC.getFieldParamValue(this.props.field.param, '@cssClass')} id={this.props.field.rbsid} style={MC.styleObjectFromString(MC.getFieldParamValue(this.props.field.param, '@css'))}/>
  }

}

export {Dummy}